import React, {useMemo} from 'react';
import {NavLink, useRouteMatch} from 'react-router-dom';
import {useHistory} from 'react-router';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import {useLocation} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import actions from 'store/actions';
import Menu from 'components/elements/common/Menu';
import Search from 'components/elements/common/Search';
import AccentButton from 'components/elements/common/AccentButton';
import {Avatar, Count} from 'components/elements/common';
import useAppState from 'lib/hooks/useAppState';
import {ReactComponent as Logo} from 'images/addy-logo.svg';
import {ReactComponent as BillingIcon} from 'images/dollar-sign.svg';
import {ReactComponent as OrdersIcon} from 'images/package.svg';
import {ReactComponent as LogoutIcon} from 'images/log-out.svg';
import {ReactComponent as AccountIcon} from 'images/account.svg';
import {ReactComponent as CartIcon} from 'images/shopping-cart.svg';
import {ReactComponent as MenuIcon} from 'images/menu.svg';
import {ReactComponent as ClientsIcon} from 'images/users.svg';
import {ReactComponent as UserCheckIcon} from 'images/user-check.svg';
import {ReactComponent as HomeIcon} from 'images/home.svg';
import {ReactComponent as FilesIcon} from 'images/fileLine.svg';

import useData from 'lib/hooks/useData.js';
import {useStyles as useMenuStyles} from './MainSidebar';
import {isMenuItemActive} from 'lib/functions';
import theme from 'theme/theme';
import useLinkHandler from '../../lib/hooks/useLinkHandler';
import {cartCookieName, wwwUrl} from 'lib/config.js';
import DataController from '../../lib/controllers/DataController';

export const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    background: theme.palette.common.white,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: 60,
    padding: '0 64px',
    position: 'fixed',
    top: 0,
    width: '100vw',
    zIndex: 1600,

    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },

    [theme.breakpoints.up('md')]: {
      height: 72,
    },
  },
  left: {
    position: 'absolute',
    left: 16,

    '& .desktop': {
      display: 'none',

      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },

    '& .mobile': {
      display: 'block',
      padding: 3,

      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    padding: '12px 16px',
    position: 'absolute',
    right: 0,

    '& .desktop': {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
  },
  panel: {
    alignItems: 'center',
    background: theme.palette.neutral.audienceAsphalt,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '16px 10px 8px 10px',

    '& .avatar': {
      height: 100,
      width: 100,
    },

    '& .panel-content': {
      margin: '6px 0 8px',
      textAlign: 'center',
    },

    '& a': {
      width: '100%',
    },

    '& .MuiBox-root': {
      marginRight: 0,
    },

    [theme.breakpoints.up('md')]: {
      background: theme.palette.neutral[200],

      '& .avatar': {
        height: 100,
        width: 100,
      },

      '& .panel-content': {
        margin: 8,
      },
    },
  },
  panelName: {
    color: theme.palette.primary.main,
    fontSize: 20,
  },
  panelCompany: {
    color: theme.palette.neutral[500],
    fontSize: 15,
  },
  iconBox: {
    alignItems: 'center',
    borderColor: theme.palette.neutral[400],
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'flex',
    justifyContent: 'center',
    padding: '8px 10px',

    '& svg': {
      height: 14,
      width: 14,
    },

    '& p': {
      fontSize: 13,
    },

    [theme.breakpoints.up('md')]: {
      padding: '11px 13px',

      '& svg': {
        height: 24,
        width: 24,
      },

      '& p': {
        fontSize: 17,
      },
    },
  },
  profile: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: 24,

    '& p': {
      color: theme.palette.neutral[900],
      fontSize: 17,
      marginLeft: 8,
      marginRight: 6,
    },
  },
  signup: {
    alignItems: 'center',
    marginRight: 24,
    display: 'flex',
    justifyContent: 'center',
  },
  drawer: {
    '& .MuiPaper-root': {
      background: theme.palette.neutral.audienceAsphalt,
      width: 300,

      '& > a': {
        borderTop: `1px solid ${theme.palette.accent.marketMint[900]}66`,

        '& > svg + span': {
          color: theme.palette.common.offWhite,
        },
      },
    },
  },
  drawerPaper: {
    paddingTop: 80,
  },
  bottomFiller: {
    borderTop: `1px solid ${theme.palette.accent.marketMint[900]}66`,
  },
  linkActive: {
    background: '#2e2e31',
    opacity: 0.6,
  },
  hideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  itemClass: {
    color: theme.palette.accent.marketMint[900],
    padding: '12px 4px',
  },
  dropdownMenu: {
    '& .MuiListItemIcon-root path': {
      stroke: `${theme.palette.accent.marketMint[700]} !important`,
    },
  },
  accentButton: {
    margin: '10px 0',
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.accent.marketMint[900]}`,
    paddingBottom: 15,
    marginBottom: 5,
  },
  signUpBtn: {
    backgroundColor: theme.palette.common.offWhite,
    color: theme.palette.common.black,
  },
  signInBtn: {
    color: theme.palette.common.offWhite,
  },
  title: {
    textTransform: 'capitalize',
    fontWeight: '400',
    letterSpacing: '-0.44999998807907104px',
    textAlign: 'left',

    [theme.breakpoints.up('md')]: {
      fontSize: '34px',
      lineHeight: '34px',
      marginLeft: '24px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '21px',
      lineHeight: '25.2px',
      marginLeft: '20px',
    },
  },
  logo: {
    height: 60,

    [theme.breakpoints.up('md')]: {
      height: 72,
    },
  },
}));

const MainNavBar = (props) => {
  const {dispatch, state} = useAppState();
  const history = useHistory();
  const {getLinkButtonProps, getLinkAnchorProps} = useLinkHandler();
  const isInvoicePage = useRouteMatch('/invoice');
  const isCheckoutPage = useRouteMatch('/checkout');
  const cartId = DataController.getCookie(cartCookieName);

  const {pathname} = useLocation();

  const [open, setOpen] = React.useState(false);

  const title = React.useMemo(() => {
    try {
      return history.location?.pathname?.split('/')[0];
    } catch (_e) {
    } finally {
      return '';
    }
  }, [history?.location]);

  const classes = useStyles();
  const menuClasses = useMenuStyles();

  const authenticated = !!state.token;

  const handleLogout = (e) => {
    dispatch({
      type: actions.LOGOUT,
    });
  };

  const toggleDrawer = () => {
    setOpen((open) => !open);
  };

  useData({
    queries:
      authenticated && pathname !== '/account/profile' && (!state.userData || !state.userData.last_login)
        ? ['getUser']
        : [],
  });

  const handleLinkClick = () => {
    setOpen(false);
  };

  const desktopMenuItems = [
    {
      icon: HomeIcon,
      name: 'Home',
      onClick: () => {
        window.location.replace(wwwUrl);
      },
    },
    {
      icon: OrdersIcon,
      name: 'Orders',
      onClick: () => history.push('/orders/upcoming'),
    },
    // insert Clients here - IF user.is_agency
    {
      icon: BillingIcon,
      name: 'Billing',
      onClick: () => history.push('/billing'),
    },
    {
      icon: UserCheckIcon,
      name: 'Team Members',
      onClick: () => history.push('/team'),
    },
    {
      icon: FilesIcon,
      name: 'Ad Files',
      onClick: () => history.push('/files'),
    },
    {
      icon: AccountIcon,
      name: 'Account',
      onClick: () => history.push('/account/profile'),
    },
    {
      icon: LogoutIcon,
      name: 'Sign out',
      onClick: handleLogout,
    },
  ];

  if (state.userData && state.userData.is_agency) {
    desktopMenuItems.splice(2, 0, {
      icon: ClientsIcon,
      name: 'Clients',
      onClick: () => history.push('/clients'),
    });
  }

  const upcomingCount = state.ordersStats.data?.open;
  const ordersCount = useMemo(() => {
    const {open = 0, inflight = 0, completed = 0} = state.ordersStats.data || {};
    return open + inflight + completed;
  }, [state.ordersStats.data]);
  const inProgressCount = state.ordersStats.data?.inflight;
  const inHistoryCount = state.ordersStats.data?.completed;
  const cartItems = state.userData?.cart_items || 0;

  if (isInvoicePage) {
    return null;
  }

  return (
    <Box id="header" className={classes.root} {...props}>
      <Box className={classes.left}>
        {!authenticated && (
          <AccentButton className={classes.hideOnMobile} href={wwwUrl + '/shop'}>
            Shop
          </AccentButton>
        )}

        {authenticated && (
          <>
            <AccentButton className="desktop" href={wwwUrl + '/shop'}>
              Shop
            </AccentButton>
            <Button className="mobile" onClick={toggleDrawer}>
              <MenuIcon />
            </Button>
            <Drawer
              anchor="left"
              className={classes.drawer}
              open={open}
              onClose={toggleDrawer}
              PaperProps={{className: classes.drawerPaper}}
            >
              <Box className={classes.panel}>
                <Avatar
                  src={state.userData?.avatar}
                  border={theme.palette.neutral[900]}
                  badge={state.userData.is_agency ? 'Agency' : 'Client'}
                />
                <Box className="panel-content">
                  <Typography className={classes.panelName}>{state.userData?.fullname}</Typography>
                  <Typography className={classes.panelCompany}>{state.userData?.company || '\u00A0'}</Typography>
                </Box>
                <AccentButton href={wwwUrl + '/shop'} className={classes.accentButton}>
                  Shop
                </AccentButton>
              </Box>
              <a className={menuClasses.link} href={wwwUrl + '/shop'}>
                <HomeIcon />
                <span>Home</span>
                <Box className={menuClasses.bottomFiller} />
              </a>
              <NavLink className={menuClasses.link} onClick={handleLinkClick} to="/orders/all">
                <OrdersIcon />
                <span>Orders</span>
                <Box className={menuClasses.bottomFiller} />
                <Count value={ordersCount} />
              </NavLink>
              <NavLink className={menuClasses.link} onClick={handleLinkClick} to="/orders/upcoming">
                <span>Upcoming</span>
                <Box className={menuClasses.bottomFiller} />
                <Count value={upcomingCount} />
              </NavLink>
              <NavLink className={menuClasses.link} onClick={handleLinkClick} to="/orders/inprocess">
                <span>In Process</span>
                <Box className={menuClasses.bottomFiller} />
                <Count value={inProgressCount} />
              </NavLink>
              <NavLink className={menuClasses.link} onClick={handleLinkClick} to="/orders/history">
                <span>History</span>
                <Box className={menuClasses.bottomFiller} />
                <Count value={inHistoryCount} />
              </NavLink>
              <NavLink
                className={menuClasses.link}
                onClick={handleLinkClick}
                to="/billing"
                activeClassName={classes.linkActive}
              >
                <BillingIcon />
                <span>Billing</span>
              </NavLink>
              {state.userData.is_agency && (
                <>
                  <NavLink
                    className={menuClasses.link}
                    onClick={handleLinkClick}
                    to="/clients"
                    activeClassName={classes.linkActive}
                  >
                    <ClientsIcon />
                    <span>Clients</span>
                  </NavLink>
                </>
              )}

              <NavLink
                className={menuClasses.link}
                onClick={handleLinkClick}
                to="/team"
                activeClassName={classes.linkActive}
              >
                <UserCheckIcon />
                <span>Team Members</span>
              </NavLink>

              <NavLink
                className={menuClasses.link}
                onClick={handleLinkClick}
                to="/files"
                activeClassName={classes.linkActive}
              >
                <FilesIcon />
                <span>Ad Files</span>
              </NavLink>

              <Box className={`${classes.bottomFiller} ${menuClasses.bottomFiller}`} />

              <NavLink
                className={`${menuClasses.link}`}
                onClick={handleLinkClick}
                to="/account/profile"
                activeClassName={classes.linkActive}
                isActive={(match, location) => isMenuItemActive('/account/profile', match, location)}
              >
                <AccountIcon />
                <span>Account</span>
              </NavLink>
              <NavLink
                className={`${menuClasses.link}`}
                onClick={() => {
                  handleLogout();
                  handleLinkClick();
                }}
                to="/"
              >
                <LogoutIcon />
                <span>Sign out</span>
              </NavLink>
            </Drawer>
          </>
        )}
        {!authenticated && (
          <>
            <Button className="mobile" onClick={toggleDrawer}>
              <MenuIcon />
            </Button>
            <Drawer
              anchor="left"
              className={classes.drawer}
              open={open}
              onClose={toggleDrawer}
              PaperProps={{className: classes.drawerPaper}}
            >
              <Box className={classes.panel}>
                <div className={classes.buttonsWrapper}>
                  <Button className={classes.signInBtn} {...getLinkButtonProps({url: '/login'})}>
                    Sign in
                  </Button>
                  <Button
                    className={classes.signUpBtn}
                    variant="contained"
                    {...getLinkButtonProps({url: wwwUrl + '/signup', target: '_self'})}
                  >
                    Sign up
                  </Button>
                </div>
                <AccentButton href={wwwUrl + '/shop'} className={classes.accentButton}>
                  Shop
                </AccentButton>
              </Box>
            </Drawer>
          </>
        )}
      </Box>
      <a {...getLinkAnchorProps({url: wwwUrl, target: '_self'})}>
        <Logo className={classes.logo} />
      </a>

      <Box className={classes.title}>{title}</Box>

      <Box className={classes.right}>
        <Search mr={2} />
        {authenticated ? (
          <Menu
            border
            className="desktop"
            dropdownClassName={classes.dropdownMenu}
            itemClassName={classes.itemClass}
            items={desktopMenuItems}
            mr={2}
            panel={
              <Box className={classes.panel}>
                <Avatar
                  src={state.userData?.avatar}
                  marginRight={0}
                  badge={state.userData.is_agency ? 'Agency' : 'Client'}
                />
                <Box className="panel-content">
                  <Typography className={classes.panelName}>{state.userData?.fullname}</Typography>
                  <Typography className={classes.panelCompany}>{state.userData?.company || '\u00A0'}</Typography>
                </Box>
              </Box>
            }
            transformOrigin={{
              vertical: -141,
              horizontal: 86,
            }}
          >
            <Box className={classes.profile}>
              <Avatar height={24} src={state.userData?.avatar} width={24} />
              <Typography>{state.userData?.fullname}</Typography>
            </Box>
          </Menu>
        ) : (
          <Box className={classNames(classes.signup, classes.hideOnMobile)}>
            <Button {...getLinkButtonProps({url: wwwUrl + '/signup', target: '_self'})}>Sign up</Button>
          </Box>
        )}
        <Button href={!isCheckoutPage && !!cartItems ? `/checkout/${cartId}` : ''}>
          <Box className={classes.iconBox}>
            <CartIcon />
            <Typography color="textPrimary">{cartItems}</Typography>
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

export default MainNavBar;
