import {ReactComponent as MagnifierIcon} from '../../../images/magnifier.svg';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import useAppState from '../../../lib/hooks/useAppState.js';
import actions from '../../../store/actions.js';
import {useLocation} from 'react-router-dom';
import React, {useEffect} from 'react';

const useStyles = makeStyles((theme) => ({
  filter: {
    maxWidth: 192,
    marginRight: 16,

    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
}));

function FilterTextField() {
  const classes = useStyles();
  const {state, dispatch} = useAppState();
  const location = useLocation();

  const setFilterState = (val) =>
    dispatch({
      type: actions.SET_TEMPORARY,
      payload: {
        key: 'filter',
        val: val,
      },
    });

  const handleChange = (e) => {
    setFilterState(e.target.value);
  };

  useEffect(() => {
    setFilterState('');
  }, [location]);

  return (
    <TextField
      value={state.temporary.filter}
      onChange={handleChange}
      className={classes.filter}
      placeholder="Filter"
      InputProps={{endAdornment: <MagnifierIcon />}}
    />
  );
}

export default FilterTextField;
