import {authTimeout} from './config.js';

/**
 * Set component error
 *
 * @param result
 * @param setErrorMessageState
 * @param setDisplayErrorState
 */
export const setError = (result, setErrorMessageState, setDisplayErrorState) => {
  console.error(result.message);
  let msg = [];
  if (typeof result.message === 'string') {
    msg.push(result.message);
  } else {
    for (let key in result.message) {
      if (Object.prototype.hasOwnProperty.call(result.message, key)) msg.push(result.message[key]);
    }
  }
  setErrorMessageState(msg.join('<br/>'));
  setDisplayErrorState(true);
};

export const formatErrorObj = (errors) =>
  Object.keys(errors).reduce(
    (acc, key) => ({
      ...acc,
      [key]: Array.isArray(errors[key]) ? errors[key].join('<br />') : errors[key],
    }),
    {}
  );

/**
 * Check if given token is valid
 *
 * @param token
 * @param tokenCreatedAt
 * @returns {*|boolean}
 */
export const isTokenValid = (token, tokenCreatedAt) =>
  token && tokenCreatedAt && new Date().getTime() - tokenCreatedAt < authTimeout;

/**
 * Get cookies
 *
 * @param cookieName
 * @returns {if param provided, value for the key | else parsed key value pairs of cookies}
 */
export const getCookies = (cookieName) => {
  const cookie = document.cookie;
  if (!cookie || cookie.length === 0) return null;

  const parsed = cookie
    .split(';')
    .map((v) => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {});

  // cookieName provided, but not found - return null
  if (!!cookieName && !parsed[cookieName]) return null;

  if (!!cookieName) return parsed[cookieName];

  return parsed;
};

/**
 * Compare mixed objects
 *
 * @param obj1
 * @param obj2
 * @returns {boolean}
 */
export const compareMixed = (obj1, obj2) => {
  const getType = (obj) => Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();

  const areArraysEqual = () => {
    if (obj1.length !== obj2.length) return false;
    for (let i = 0; i < obj1.length; i++) {
      if (!compareMixed(obj1[i], obj2[i])) return false;
    }
    return true;
  };

  const areObjectsEqual = () => {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;
    for (let key in obj1) {
      if (Object.prototype.hasOwnProperty.call(obj1, key)) {
        if (!compareMixed(obj1[key], obj2[key])) return false;
      }
    }
    return true;
  };

  const areFunctionsEqual = () => obj1.toString() === obj2.toString();

  const arePrimitivesEqual = () => obj1 === obj2;

  const type = getType(obj1);
  if (type !== getType(obj2)) return false;
  if (type === 'array') return areArraysEqual();
  if (type === 'object') return areObjectsEqual();
  if (type === 'function') return areFunctionsEqual();
  return arePrimitivesEqual();
};

/**
 * Check if obj contains str
 *
 * @param obj
 * @param fields
 * @param str
 * @returns {boolean}
 */
export const objContainsStr = (obj, fields, str) => {
  if (!str || typeof str !== 'string') return true;
  if (!obj) return false;
  for (let i = 0; i < fields.length; i++) {
    if (
      obj.hasOwnProperty(fields[i]) &&
      typeof obj[fields[i]] === 'string' &&
      obj[fields[i]].toLowerCase().includes(str.toLowerCase())
    )
      return true;
  }
  return false;
};

/**
 * Copy text to clipboard
 *
 * @param text
 * @returns {boolean}
 */
export const copyTextToClipboard = (text) => {
  let res;
  const textArea = document.createElement('textarea');
  textArea.style.position = 'fixed';
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = '0';
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    res = !!successful;
  } catch (err) {
    console.error('[copyTextToClipboard] unable to copy', err);
    res = false;
  }

  document.body.removeChild(textArea);
  return res;
};

/**
 * Get cropped image to then upload it
 * @param src
 * @param pixelCrop
 * @param rotation
 * @returns {Promise<unknown>}
 */
export async function getCroppedImg(src, pixelCrop, rotation = 0) {
  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  const getRadianAngle = (degreeValue) => {
    return (degreeValue * Math.PI) / 180;
  };

  const image = await createImage(src);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // As Base64 string
  return new Promise((resolve) => {
    resolve(canvas.toDataURL('image/png'));
  });

  // // As a blob
  // return new Promise(resolve => {
  //     canvas.toBlob(file => {
  //         resolve(file);
  //     }, 'image/jpeg');
  // });
}

export const isMenuItemActive = (to, match, location) => {
  switch (to) {
    case '/account/profile':
      const profileSubUrls = [
        '/account/profile',
        '/account/company',
        '/account/promocodes',
        '/account/paymentmethods',
        '/account/addresses',
      ];
      let found = false;
      profileSubUrls.forEach((url) => {
        if (location.pathname.includes(url)) {
          found = true;
        }
      });
      return found;
      break;

    default:
      break;
  }
};

/**
 * Create debounced function from func
 *
 * @param func
 * @param wait
 * @param immediate
 * @returns {(function(): void)|*}
 */
export const debounce = (func, wait, immediate) => {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

/**
 * Animate scroll to
 *
 * @param to
 * @param duration
 * @param container
 * @param offset
 */
export const scrollTo = (to, duration, container, offset) => {
  const element = container || document.scrollingElement || document.body;
  if (to && to.nodeName) {
    to = to.getBoundingClientRect().top;
  }
  if (offset && !isNaN(offset)) to = to - offset;
  const start = element.scrollTop;
  const change = to - start;
  const startTs = performance.now();
  const easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };
  const animateScroll = function (ts) {
    const currentTime = ts - startTs;
    element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      element.scrollTop = to;
    }
  };
  requestAnimationFrame(animateScroll);
};

/**
 * Get image dimensions
 *
 * @param url
 * @returns {Promise<unknown>}
 */
export const getImageDimensions = async (url) => {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };

    img.onerror = () => {
      resolve(false);
    };

    img.src = url;
  });
};

/**
 * Get human readable size
 *
 * @param bytes
 * @param si
 * @param dp
 * @returns {string}
 */
export const humanFileSize = (bytes, si = false, dp = 0) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};

/**
 * Download file
 *
 * @param name
 * @param url
 */
export const downloadFile = (name, url) => {
  const link = document.createElement('a');
  link.setAttribute('download', name);
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

/**
 * Check gif file for max_seconds length
 *
 * @param file
 * @param max_seconds
 * @returns {Promise<unknown>}
 */
export const gifAnimationCheck = (file, max_seconds) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const bytes = new Uint8Array(reader.result);
      const buf = [];
      for (let i = 0; i < bytes.length; i++) {
        let b = bytes[i].toString(16);
        if (b.length === 1) {
          // Zero pad
          b = '0' + b;
        }
        buf.push(b);
      }
      const data = buf.join('');

      const iterations = (function () {
        // See http://www.w3.org/Graphics/GIF/spec-gif89a.txt and http://www.let.rug.nl/~kleiweg/gif/netscape.html for more info
        const re = new RegExp('21ff0b4e45545343415045322e300301([0-9a-f]{4})00');
        const matches = re.exec(data);

        let iter = 1;
        if (matches) {
          // Convert little-endian hex unsigned int to decimal
          iter = parseInt(matches[1].substring(2, 4) + matches[1].substring(0, 2), 16);

          // The presence of the header with a nonzero number of iterations
          // should be interpreted as "additional" iterations,
          // hence a specifed iteration of 1 means loop twice.
          // Zero iterations means loop continuously
          if (iter > 0) iter++;
        }

        // A return value of zero means gif will loop continuously
        return iter;
      })();

      // If set to loop continuously, return false
      if (iterations === 0) {
        console.log('continuous loop, return false');
        resolve(false);
        return;
      }

      let delay = (function () {
        let total_delay = 0;
        // See http://www.w3.org/Graphics/GIF/spec-gif89a.txt for more info
        const re = new RegExp('21f904[0-9a-f]{2}([0-9a-f]{4})[0-9a-f]{2}00', 'g');
        let matches = null;
        do {
          matches = re.exec(data);
          if (matches) {
            // Convert little-endian hex unsigned ints to decimals
            const d = parseInt(matches[1].substring(2, 4) + matches[1].substring(0, 2), 16);
            total_delay += d;
          }
        } while (matches);

        // Delays are stored as hundredths of a second, lets convert to seconds
        return total_delay / 100;
      })();

      delay = delay * iterations;

      // console.log('delay: ' + delay);
      if (delay > max_seconds) {
        // console.log('animation > ' + max_seconds + ', return false');
        resolve(false);
      } else {
        // console.log('animation <= ' + max_seconds + ', return true');
        resolve(true);
      }
    };

    reader.readAsArrayBuffer(file);
  });
};

/**
 * Format price with $ sign and two decimals
 *
 * @param value
 * @returns {String}
 */
export const formatPrice = (value) => {
  if (typeof value != 'number') return console.error('Type of parameter value is invalid (NaN)');

  let prefix = value < 0 ? '-$' : '$';

  return `${prefix}${Math.abs(value).toFixed(2)}`;
};

/**
 * @param from
 */
export const getBackButtonProps = (from) => {
  let label;
  let path;
  if (from === '/orders/all') {
    label = 'Back to Orders';
  } else if (from === '/orders/upcoming') {
    label = 'Back to Upcoming Orders';
  } else if (from === '/orders/inprocess') {
    label = 'Back to In Process Orders';
  } else if (from === '/orders/history') {
    label = 'Back to History';
  } else {
    label = 'Back to Orders';
    path = '/orders/all';
  }
  return {label, path};
};

export const sendGtagEvent = (...args) => {
  if (window && window.gtag) {
    window.gtag(...args);
  }
};
