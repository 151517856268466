import React from 'react';
import CommonHeader from 'components/elements/common/CommonHeader';
import BackArrow from 'components/elements/common/BackArrow';
import TeamInviteForm from 'components/elements/TeamPage/TeamInviteForm';

function TeamInvitePage() {
  return (
    <>
      <CommonHeader heading="Add Team Members" />
      <BackArrow path="/team" />
      <TeamInviteForm />
    </>
  );
}

export default TeamInvitePage;
