import React from 'react';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    background: theme.palette.accent.subscriberSunshine[500],
    borderRadius: 14,
    display: 'flex',
    justifyContent: 'center',
    height: 26,
    width: 28,
  },
  inner: {
    color: theme.palette.common.offWhite,
    fontSize: 13,
    lineHeight: '18px',
    textAlign: 'center',
  },
}));

const Count = ({value, ...rest}) => {
  const classes = useStyles();

  return !value ? null : (
    <Box {...rest} className={classNames(classes.root, rest.className ? rest.className : '')}>
      <span className={classes.inner}>{value}</span>
    </Box>
  );
};

export default Count;
