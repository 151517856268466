import React from 'react';
import useData from '../../../lib/hooks/useData.js';
import Box from '@material-ui/core/Box';
import DataTable from '../../elements/common/DataTable.js';
import CopyRowButton from '../../elements/PromoCodesPage/CopyRowButton.js';
import NoDataFound from 'components/elements/common/NoDataFound.js';
import {makeStyles} from '@material-ui/core/styles';
import {format, parseISO} from 'date-fns';

const useStyles = makeStyles((theme) => ({
  copyCell: {
    width: 215,
  },
}));

const headings = [
  {label: 'Code', id: 'code'},
  {label: 'Discount', id: 'discount'},
  {label: 'Expiration date', id: 'expiring_date'},
  {id: 'copy'},
];

export default function PromoPage() {
  const classes = useStyles();

  const state = useData({
    queries: ['getMyPromocodes'],
    setLoadingState: true,
  });

  const cellPropsGenerator = (heading, row) => {
    switch (heading.id) {
      case 'copy':
        return {className: classes.copyCell};
      default:
        return {};
    }
  };

  const cellRenderer = (heading, row) => {
    switch (heading.id) {
      case 'code':
        return row.code;
      case 'discount':
        return row.amount_formatted;
      case 'expiring_date':
        return !row.expires ? '--' : format(parseISO(row.expires), 'MM/dd/yyyy');
      case 'copy':
        return <CopyRowButton text={row.code} />;
    }
  };

  return (
    <Box>
      {!state.promocodes.codes || state.promocodes.codes.length === 0 ? (
        state.promocodes.isLoaded ? (
          <NoDataFound>No promo codes found.</NoDataFound>
        ) : (
          ''
        )
      ) : (
        <DataTable
          breakpointPaddings={{
            xsUp: 16,
            smUp: 24,
            mdUp: 32,
          }}
          headings={headings}
          rows={state.promocodes.codes}
          cellPropsGenerator={cellPropsGenerator}
          cellRenderer={cellRenderer}
        />
      )}
    </Box>
  );
}
