import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import {ReactComponent as Logo} from '../../../images/addy-logo.svg';
import {ReactComponent as GTLogo} from '../../../images/gt-logo.svg';
import HeaderActions from './HeaderActions';

const useStyles = makeStyles((theme) => ({
  header: {
    height: 72,
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',

    '@media print': {
      display: 'none',
    },
  },
  logo: {
    width: 80,
    height: 44,
    marginRight: 5,
  },
  gtLogo: {
    width: 42,
    height: 44,
    marginRight: 5,
  },
  gtLogoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logoText: {
    marginLeft: 11.29,
    fontWeight: 800,
    fontSize: 16,
  },
}));

function Header({isGT, setIsOpen, handlePayThisInvoice, cartId, orderId, handlePrintClick, order, isAgency}) {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      {isGT ? (
        <div className={classes.gtLogoContainer}>
          <GTLogo className={classes.gtLogo} />
          <span className={classes.logoText}>GroundTruth</span>
        </div>
      ) : (
        <Logo className={classes.logo} />
      )}
      <HeaderActions
        setIsOpen={setIsOpen}
        handlePayThisInvoice={handlePayThisInvoice}
        cartId={cartId}
        orderId={orderId}
        handlePrintClick={handlePrintClick}
        isPaid={order.status === 'paid'}
        isGT={isGT}
        isAgency={isAgency}
      />
    </Box>
  );
}

export default Header;
