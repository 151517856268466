import {Box, makeStyles} from '@material-ui/core';
import OrderSidebarRow from './OrderSidebarRow';
import OrderReceiptAddons from './OrderReceiptAddons';

const useStyles = makeStyles((theme) => ({
  wrap: {
    width: '100%',
  },
  group: {
    marginBottom: 32,
  },
}));

export default function OrderReceipt({order}) {
  const classes = useStyles();

  return (
    <Box className={classes.wrap}>
      {/* receipt main */}
      <Box className={classes.group}>
        {order.receipt.map((row, index) => (
          <OrderSidebarRow row={row} key={index} />
        ))}
      </Box>

      {/* receipt addons */}
      {order.receipt_addons && order.receipt_addons.length > 0 && (
        <Box className={classes.group}>
          <OrderSidebarRow row={{description: 'Add ons', is_subtotal: true}} />
          <OrderReceiptAddons addons={order.receipt_addons} order={order} />
        </Box>
      )}
    </Box>
  );
}
