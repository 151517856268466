import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {ReactComponent as ConfettiLeft} from 'images/confettiLeft.svg';
import {ReactComponent as ConfettiRight} from 'images/confettiRight.svg';

const useStyles = makeStyles((theme) => ({
  confetti: {
    position: 'fixed',
    width: '100vw',
    left: 0,
    bottom: 0,
    zIndex: 2001,
    display: 'flex',
  },

  left: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },

  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },

  leftInner: {
    transition: 'transform 0.5s cubic-bezier(.26,1.14,.33,.97)',
    transformOrigin: 'right bottom',
  },

  rightInner: {
    transition: 'transform 0.5s cubic-bezier(.26,1.14,.33,.97)',
    transformOrigin: 'left bottom',
  },
}));

const animationSteps = (state, side) => {
  switch (state) {
    case 0:
      return {
        transform: 'scale(0) translateY(50px)',
      };
      break;

    case 1:
      return {
        transform: `scale(1.2) translateY(0px) rotate(${side === 'left' ? '-' : ''}15deg)`,
      };
      break;

    case 2:
      return {
        transform: `scale(1.2) translateY(100vh) rotate(${side === 'left' ? '-' : ''}15deg)`,
        transition: 'transform 0.5s linear',
      };
      break;

    case 3: {
      return {
        transform: 'scale(0)',
      };
    }

    default:
      break;
  }
};

const wrapperZIndex = (state) => {
  switch (state) {
    case 0:
      return {
        zIndex: -1000,
      };
      break;

    case 1:
    case 2: {
      return {
        zIndex: 2100,
      };
    }

    case 3: {
      return {
        zIndex: -1000,
      };
    }

    default:
      break;
  }
};

const Confetti = forwardRef((props, ref) => {
  const classes = useStyles();
  const [state, setState] = useState(0);

  useImperativeHandle(ref, () => ({
    playConfetti() {
      setState(1);

      setTimeout(() => {
        setState(2);
      }, 800);

      setTimeout(() => {
        setState(3);
      }, 1500);

      setTimeout(() => {
        setState(0);
      }, 2000);
    },
  }));

  return (
    <Box className={classes.confetti} style={wrapperZIndex(state)}>
      <Box className={classes.left}>
        <Box className={classes.leftInner} style={animationSteps(state, 'left')}>
          <ConfettiLeft />
        </Box>
      </Box>
      <Box className={classes.right}>
        <Box className={classes.rightInner} style={animationSteps(state, 'right')}>
          <ConfettiRight />
        </Box>
      </Box>
    </Box>
  );
});

export default Confetti;
