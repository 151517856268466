import React from 'react';
import CustomDialog from '../common/CustomDialog.js';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core';
import TransitionBounce from '../common/TransitionBounce.js';

const useStyles = makeStyles((theme) => ({
  dialogContentClass: {
    height: 351,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  graphicWrapper: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: 26,
    lineHeight: '1.4',
    color: theme.palette.neutral[900],
    fontWeight: 400,
    textAlign: 'center',
    maxWidth: 411,

    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <TransitionBounce ref={ref} {...props} />;
});

function OrderSubmitSuccess({isOpen, message, onClose}) {
  const classes = useStyles();

  return (
    <CustomDialog
      TransitionComponent={Transition}
      onClose={onClose}
      isOpen={isOpen}
      title=" "
      actions={[]}
      dialogContentClass={classes.dialogContentClass}
      maxWidth="sm"
      hideContentDivider
      hasXClose
      disableAutoFocus
      positionedAbove
    >
      <Box className={classes.graphicWrapper}>
        <Box mt={1} className={classes.title}>
          <span dangerouslySetInnerHTML={{__html: message}} />
        </Box>
      </Box>
    </CustomDialog>
  );
}

export default OrderSubmitSuccess;
