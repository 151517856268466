import React, {useMemo} from 'react';
import {NavLink} from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import useData from '../../lib/hooks/useData.js';
import {ReactComponent as BillingIcon} from 'images/dollar-sign.svg';
import {ReactComponent as OrdersIcon} from 'images/package.svg';
import {ReactComponent as ClientsIcon} from 'images/users.svg';
import {ReactComponent as AccountIcon} from 'images/account.svg';
import {ReactComponent as UserCheckIcon} from 'images/user-check.svg';
import {ReactComponent as FilesIcon} from 'images/fileLine.svg';
import {Count} from 'components/elements/common';
import {isMenuItemActive} from 'lib/functions';
import classNames from 'classnames';

export const useStyles = makeStyles((theme) => ({
  root: {
    background: 'transparent',
    display: 'none',
    flexDirection: 'column',
    //height: '100vh',
    height: '85%',
    padding: '72px 0 48px',
    position: 'fixed',
    left: 0,
    top: 0,

    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      width: 234,
    },

    [theme.breakpoints.up('xl')]: {
      width: 308,
    },
  },
  topFiller: {
    height: 109,
    width: '100%',
  },
  bottomFiller: {
    flex: '1 1 auto',
  },
  link: {
    alignItems: 'center',
    background: 'none',
    border: 'none',
    display: 'flex',
    height: 48,
    padding: '0 32px',

    '&:hover': {
      cursor: 'pointer',
    },

    '& > span': {
      color: theme.palette.accent.marketMint[700],
      fontSize: 17,
      lineHeight: '24px',
      marginLeft: 32,
    },

    '& > svg + span': {
      color: theme.palette.accent.marketMint[900],
      marginLeft: 10,
    },

    '& > svg': {
      height: 22,
      width: 22,

      '& path': {
        stroke: theme.palette.accent.marketMint[700],
      },
    },
  },
  linkActive: {
    background: 'rgba(253, 253, 253, 0.3)',
    borderTop: `1px solid ${theme.palette.common.base}1a`,
    borderBottom: `1px solid ${theme.palette.accent.marketMint[600]}66`,

    '& .menuItemLabel': {
      color: `${theme.palette.primary[500]} !important`,
    },

    '& svg path': {
      stroke: theme.palette.primary[500],
    },
  },
  accountMenu: {
    //marginBottom: 110,
  },
}));

const MainMenu = ({className, ...rest}) => {
  const classes = useStyles();

  const state = useData({
    queries: ['getOrdersStats'],
    pollingTimeout: 600000,
  });

  const upcomingCount = state.ordersStats.data?.open;
  const ordersCount = useMemo(() => {
    const {open = 0, inflight = 0, completed = 0} = state.ordersStats.data || {};
    return open + inflight + completed;
  }, [state.ordersStats.data]);
  const inProgressCount = state.ordersStats.data?.inflight;
  const inHistoryCount = state.ordersStats.data?.completed;

  return (
    <Box className={`${classes.root} ${className}`} {...rest}>
      <Box className={classes.topFiller} />
      <NavLink className={classes.link} activeClassName={classes.linkActive} to="/orders/all">
        <OrdersIcon />
        <span className="menuItemLabel">Orders</span>
        <Box className={classes.bottomFiller} />
        <Count value={ordersCount} />
      </NavLink>
      <NavLink className={classes.link} to="/orders/upcoming" activeClassName={classes.linkActive}>
        <span className="menuItemLabel">Upcoming</span>
        <Box className={classes.bottomFiller} />
        <Count value={upcomingCount} />
      </NavLink>
      <NavLink className={classes.link} to="/orders/inprocess" activeClassName={classes.linkActive}>
        <span className="menuItemLabel">In Process</span>
        <Box className={classes.bottomFiller} />
        <Count value={inProgressCount} />
      </NavLink>
      <NavLink className={classes.link} to="/orders/history" activeClassName={classes.linkActive}>
        <span className="menuItemLabel">History</span>
        <Box className={classes.bottomFiller} />
        <Count value={inHistoryCount} />
      </NavLink>
      <NavLink className={classes.link} to="/billing" activeClassName={classes.linkActive}>
        <BillingIcon />
        <span className="menuItemLabel">Billing</span>
      </NavLink>
      {state.userData.is_agency && (
        <>
          <NavLink className={classes.link} activeClassName={classes.linkActive} to="/clients">
            <ClientsIcon />
            <span className="menuItemLabel">Clients</span>
          </NavLink>
        </>
      )}
      <NavLink className={classes.link} activeClassName={classes.linkActive} to="/team">
        <UserCheckIcon />
        <span className="menuItemLabel">Team Members</span>
      </NavLink>
      <NavLink className={classes.link} activeClassName={classes.linkActive} to="/files">
        <FilesIcon />
        <span className="menuItemLabel">Ad Files</span>
      </NavLink>

      <Box className={classes.bottomFiller} />

      <NavLink
        className={classNames(classes.link, classes.accountMenu)}
        activeClassName={classes.linkActive}
        to="/account/profile"
        isActive={(match, location) => isMenuItemActive('/account/profile', match, location)}
      >
        <AccountIcon />
        <span>Account</span>
      </NavLink>
    </Box>
  );
};

export default MainMenu;
