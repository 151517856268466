import React, {useState} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {actions} from 'store/store.js';
import {formatErrorObj} from 'lib/functions';
import DataController from 'lib/controllers/DataController.js';
import FieldGroup from 'components/elements/form/FieldGroup';
import TextField from '@material-ui/core/TextField';
import useAppState from 'lib/hooks/useAppState.js';
import useFormControl from '../../../lib/hooks/useFormControl.js';
import {useSnackbar} from 'notistack';
import CheckboxField from '../form/CheckboxField';

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 428,

    [theme.breakpoints.up('tb')]: {
      maxWidth: 384,
    },
  },
}));

const defaultForm = {
  billing_contact_email: '',
  billing_contact_name: '',
  billing_contact_phone: '',
  material_reminder: '',
  billing_street: '',
  billing_street2: '',
  billing_city: '',
  billing_state: '',
  billing_zip: '',
  billing_country: '',
  enable_submit: '',
};

export default function BillingForm({data, isClientPage}) {
  const [copyAddress, setCopyAddress] = useState(!data.use_billing_address);

  const {dispatch} = useAppState();

  const {enqueueSnackbar} = useSnackbar();

  const classes = useStyles();

  const {getFieldProps, setErrors, formData, renderSubmitButton, setIsWorking} = useFormControl({
    loadValues: Object.keys(data).length && data,
    defaultForm,
    SubmitButtonProps: {
      label: 'Update Billing Info',
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsWorking(true);
    setErrors({});
    DataController.postCompanyBilling(data.id, {...formData, billing_same: copyAddress ? 1 : 0}).then(
      ({data, errors, message, success}) => {
        if (success) {
          if (!isClientPage) {
            dispatch({
              type: actions.SET_MY_COMPANY,
              payload: data,
            });
          } else {
            dispatch({
              type: actions.CLEAR_DATA,
              payload: {
                action: actions.SET_MY_CLIENTS,
              },
            });
            dispatch({
              type: actions.CLEAR_DATA,
              payload: {
                action: actions.SET_CURRENT_CLIENT,
              },
            });
          }
          enqueueSnackbar('Billing info updated successfully!');
        } else {
          if (Object.keys(errors).length > 0) {
            const errorObject = formatErrorObj(errors);
            setErrors(errorObject);
          } else {
            enqueueSnackbar(message, {
              variant: 'error',
            });
          }
        }
        setIsWorking(false);
      }
    );
  };

  function handleCopyAddress() {
    formData.enable_submit = !copyAddress ? '1' : '';
    setCopyAddress(!copyAddress);
  }

  return (
    <form autoComplete="off" className={classes.form} noValidate onSubmit={handleSubmit}>
      <FieldGroup>
        <TextField label="Billing name" {...getFieldProps('billing_contact_name')} />
      </FieldGroup>

      <FieldGroup>
        <TextField label="Billing email" {...getFieldProps('billing_contact_email')} />
      </FieldGroup>

      <FieldGroup>
        <TextField label="Billing phone number" {...getFieldProps('billing_contact_phone')} />
      </FieldGroup>

      <FieldGroup>
        <CheckboxField label="address same as company's" checked={copyAddress} onChange={handleCopyAddress} />
      </FieldGroup>

      {!copyAddress && (
        <Box>
          <FieldGroup>
            <TextField label="Address" {...getFieldProps('billing_street')} />
          </FieldGroup>
          <FieldGroup>
            <TextField label="Address, line 2" {...getFieldProps('billing_street2')} />
          </FieldGroup>
          <FieldGroup>
            <TextField label="City" {...getFieldProps('billing_city')} />
            <TextField label="State/Province" {...getFieldProps('billing_state')} />
          </FieldGroup>
          <FieldGroup>
            <TextField label="Zip code" {...getFieldProps('billing_zip')} />
          </FieldGroup>
          <FieldGroup>
            <TextField label="Country" {...getFieldProps('billing_country')} />
          </FieldGroup>
        </Box>
      )}

      <Box mt={3}>{renderSubmitButton}</Box>
    </form>
  );
}
