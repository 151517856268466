import React from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {actions} from 'store/store.js';
import {formatErrorObj} from 'lib/functions';
import DataController from 'lib/controllers/DataController.js';
import FieldGroup from 'components/elements/form/FieldGroup';
import TextField from '@material-ui/core/TextField';
import useAppState from 'lib/hooks/useAppState.js';
import useFormControl from '../../../lib/hooks/useFormControl.js';
import {useSnackbar} from 'notistack';

const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 428,

    [theme.breakpoints.up('tb')]: {
      maxWidth: 384,
    },
  },
}));

const defaultForm = {
  name: '',
  street: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
};

export default function AddressForm({data, isClientPage}) {
  const {dispatch} = useAppState();

  const {enqueueSnackbar} = useSnackbar();

  const {getFieldProps, setErrors, formData, renderSubmitButton, setIsWorking} = useFormControl({
    defaultForm,
    loadValues: Object.keys(data).length && data,
    SubmitButtonProps: {
      label: 'Update Company Info',
    },
  });

  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsWorking(true);
    setErrors({});

    DataController.postCompanyInfo(data.id, formData).then(({data, errors, message, success}) => {
      if (success) {
        if (!isClientPage) {
          dispatch({
            type: actions.SET_MY_COMPANY,
            payload: data,
          });
        } else {
          dispatch({
            type: actions.CLEAR_DATA,
            payload: {
              action: actions.SET_MY_CLIENTS,
            },
          });
          dispatch({
            type: actions.CLEAR_DATA,
            payload: {
              action: actions.SET_CURRENT_CLIENT,
            },
          });
        }
        enqueueSnackbar('Company info updated successfully!');
      } else {
        if (Object.keys(errors).length > 0) {
          const errorObject = formatErrorObj(errors);
          setErrors(errorObject);
        } else {
          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      }
      setIsWorking(false);
    });
  };

  return (
    <form autoComplete="off" className={classes.form} noValidate onSubmit={handleSubmit}>
      <FieldGroup>
        <TextField label="Company name" required {...getFieldProps('name')} />
      </FieldGroup>

      <FieldGroup>
        <TextField label="Address" {...getFieldProps('street')} />
      </FieldGroup>

      <FieldGroup>
        <TextField label="Address, line 2" {...getFieldProps('street2')} />
      </FieldGroup>

      <FieldGroup>
        <TextField label="City" {...getFieldProps('city')} />
        <TextField label="State/Province" {...getFieldProps('state')} />
      </FieldGroup>

      <FieldGroup>
        <TextField label="Zip code" {...getFieldProps('zip')} />
      </FieldGroup>

      <FieldGroup>
        <TextField label="Country" {...getFieldProps('country')} />
      </FieldGroup>

      <Box mt={3}>{renderSubmitButton}</Box>
    </form>
  );
}
