import React, {useState} from 'react';
import IconButton from '@material-ui/core/IconButton';
import {ReactComponent as MoreVerticalIcon} from '../../../images/moreVertical.svg';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

function MoreMenu({row, id, items, loading}) {
  const [activeMenuEl, setActiveMenuEl] = useState(null);
  const [activeMenuId, setActiveMenuId] = useState('');

  const handleOpenMenu = (e, id) => {
    e.preventDefault();
    setActiveMenuEl(e.currentTarget);
    setActiveMenuId(id);
  };

  const handleCloseMenu = (e) => {
    e && e.preventDefault();
    setActiveMenuEl(null);
    setActiveMenuId('');
  };

  const handleMenuItemClick = (e, item, row) => {
    item.onClick && item.onClick(e, row);
    handleCloseMenu();
  };

  return (
    <Box>
      <IconButton
        aria-controls={id}
        aria-haspopup="true"
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          handleOpenMenu(e, row && row.id);
        }}
      >
        {!loading ? (
          <MoreVerticalIcon />
        ) : (
          <CircularProgress variant="indeterminate" color="secondary" size={20} thickness={4} value={50} />
        )}
      </IconButton>
      <Menu
        id={id}
        anchorEl={activeMenuEl}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        open={Boolean(activeMenuEl) && (!row || activeMenuId === row.id)}
        onClose={handleCloseMenu}
      >
        {items &&
          items.map((item, itemIndex) => (
            <MenuItem key={itemIndex} disabled={item.disabled} onClick={(e) => handleMenuItemClick(e, item, row)}>
              {item.label}
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
}

MoreMenu.propTypes = {
  id: PropTypes.string,
  row: PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    })
  ),
  loading: PropTypes.bool,
};

MoreMenu.defaultProps = {
  loading: false,
};

export default MoreMenu;
