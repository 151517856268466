import React from 'react';
import {Typography} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import {Link} from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  footerWrapper: {
    // [theme.breakpoints.up('912')]: {
    //   position: 'absolute',
    //   bottom: 0,
    //   width: '100%',
    //   zIndex: 1000,
    // }
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 71,
    padding: '16px 16px 20px',

    '& p': {
      color: theme.palette.neutral[700],
      fontSize: 15,
      lineHeight: '20px',
      maxWidth: 386,
      textAlign: 'center',

      '& + p': {
        marginTop: 16,
      },
    },

    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'row',

      '& p': {
        textAlign: 'left',

        '& + p': {
          marginTop: 0,
        },
      },
    },

    [theme.breakpoints.up('md')]: {
      alignItems: 'center',

      '& p': {
        maxWidth: 'unset',
      },
    },
  },
  choiceLink: {
    color: ({isGT}) => isGT && '#559BAB',
  },
  contact: {
    fontSize: 13,
    lineHeight: '18px',
    color: theme.palette.neutral[600],
    '& a': {
      color: ({isGT}) => (isGT ? '#559BAB' : theme.palette.primary[500]),
    },
  },
}));

const NonAuthFooter = ({isGT}) => {
  const classes = useStyles({isGT});

  return (
    <Box className={classes.footerWrapper}>
      <Divider />
      <Box className={classes.root}>
        <Typography>
          {`${isGT ? 'GroundTruth' : 'Addy'} is a registered trademark of xAd, Inc. ©2021. All rights reserved. `}
          <Link to="/" className={classes.choiceLink}>
            Adchoices
          </Link>
        </Typography>
        <Typography className={classes.contact}>
          Get in touch: <Link to="/">(646) 595-1155</Link> or{' '}
          <Link to="/">{isGT ? 'info@GroundTruth.co' : 'info@addy.co'}</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default NonAuthFooter;
