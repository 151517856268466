import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import DataTable from '../common/DataTable.js';
import {ReactComponent as CloseDialogIcon} from '../../../images/closeDialog.svg';
import IconButton from '@material-ui/core/IconButton';
import {Divider, Typography} from '@material-ui/core';
import OrderExtraAddonsProcessing from '../OrderPage/OrderSidebar/OrderExtraAddonsProcessing';
import OrderExtraAddonsSuccess from '../OrderPage/OrderSidebar/OrderExtraAddonsSuccess';
import Chip from '@material-ui/core/Chip';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
  },
  footer: {
    backgroundColor: theme.palette.neutral[200],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: 10,
  },
  total: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
  },
  invoices: {
    '& tbody': {
      backgroundColor: theme.palette.accent.marketMint[100],
    },
  },
  xCloseButton: {
    marginLeft: 16,
    marginRight: 0,
    marginTop: 2,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 20px 0 20px',
  },
  dialogTitle: {
    fontSize: 34,
    lineHeight: '34px',
    letterSpacing: '-0.45px',
    color: theme.palette.neutral[900],

    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
  },
  formWrap: {
    padding: 16,
  },
  formTitle: {
    color: theme.palette.neutral[900],
    fontSize: 21,
  },
  textCell: {
    maxWidth: 430,
    minWidth: 400,
  },
  orderCell: {
    maxWidth: 170,
  },
  orderDateCell: {
    maxWidth: 260,
  },
  amountDueCell: {
    justifyContent: 'flex-end',
  },
  invoiceNumberWrapper: {
    padding: '0 15px',
  },
  chipOutlined: {
    background: theme.palette.common.white,
    borderColor: theme.palette.accent.subscriberSunshine[500],

    '& span': {
      color: theme.palette.accent.subscriberSunshine[500],
    },
  },
  chipPrimary: {
    borderColor: theme.palette.accent.buyerBlue[600],

    '& span': {
      color: theme.palette.accent.buyerBlue[600],
    },
  },
  chipSecondary: {
    borderColor: theme.palette.accent.customerCoral[500],

    '& span': {
      color: theme.palette.accent.customerCoral[500],
    },
  },
  chipDisabled: {
    borderColor: theme.palette.neutral[600],

    '& span': {
      color: theme.palette.neutral[600],
    },
  },
  chiplabel: {
    borderColor: theme.palette.text.primary,

    '& span': {
      color: theme.palette.text.primary,
    },
  },
  adDescription: {
    color: theme.palette.neutral[900],
    fontSize: 20,
    lineHeight: '25px',
    marginBottom: 4,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  adQty: {
    color: theme.palette.neutral[800],
    fontSize: 13,
    overflow: 'hidden',
    lineHeight: '18px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  adRun: {
    color: theme.palette.neutral[800],
    fontSize: 13,
    overflow: 'hidden',
    lineHeight: '18px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const headings = [
  {label: 'Order', id: 'order', key: 'order'},
  {label: 'Order date', id: 'orderDate', key: 'orderDate'},
  {label: 'Item', id: 'item', key: 'item'},
  {label: 'Due', id: 'due', key: 'due'},
  {label: 'Amount due', id: 'amountDue', key: 'amountDue'},
];

const statusMap = {
  open: 'default',
  overdue: 'default',
  paid: 'primary',
};

export default function PaymentStatus({status, onClose, handleSuccessClose, invoices, isGT}) {
  const classes = useStyles();

  const cellRenderer = ({key}, row) => {
    switch (key) {
      case 'order':
        return (
          <Box className={classes.invoiceNumberWrapper}>
            <div>{`#${row.id}`}</div>
            <Chip
              classes={{
                disabled: classes.chipDisabled,
                label: classes.chiplabel,
                outlined: classes.chipOutlined,
                outlinedPrimary: classes.chipPrimary,
                outlinedSecondary: classes.chipSecondary,
              }}
              color={statusMap[row.status]}
              disabled={row.status === 'canceled'}
              label={row.status}
              variant="outlined"
            />
          </Box>
        );
      case 'orderDate':
        return (
          <Box>
            <Typography>{row.order_date}</Typography>
          </Box>
        );
      case 'item':
        return (
          <Box>
            <Typography className={classes.adDescription}>{row.summary.summary_title}</Typography>
            <Typography className={classes.adRun}>Client: {row.company}</Typography>
            <Typography className={classes.adRun}>{row.summary.summary_line_1}</Typography>
            <Typography className={classes.adQty}>{row.summary.summary_line_2}</Typography>
            <Typography className={classes.adQty}>{row.addons.join(', ')}</Typography>
          </Box>
        );
      case 'due':
        return <Typography>{row.due_date}</Typography>;
      case 'amountDue':
        return <Typography>{row.totals.amount_due}</Typography>;
      default:
        return row[key] || '-';
    }
  };

  const cellPropsGenerator = (heading, row) => {
    switch (heading.id) {
      case 'order':
        return {className: classes.orderCell};
      case 'orderDate':
        return {className: classes.orderDateCell};
      case 'item':
        return {className: classes.textCell};
      case 'amountDue':
        return {className: classes.amountDueCell};
    }
  };

  return (
    <>
      <DialogTitle disableTypography>
        <Box className={classes.headerContainer}>
          <div className={classes.dialogTitle}>
            {status === 'processing' ? 'Processing Payment' : 'Payment Complete'}
          </div>
          <IconButton
            className={classes.xCloseButton}
            onClick={onClose}
            size="small"
            aria-label="close"
            color="secondary"
          >
            <CloseDialogIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box pl={3} pr={3}>
        <Divider />
      </Box>
      <DialogContent>
        <Box>
          {status === 'processing' ? (
            <OrderExtraAddonsProcessing isGT={isGT} />
          ) : (
            <OrderExtraAddonsSuccess isGT={isGT} handleClose={handleSuccessClose} text="Your payment is complete." />
          )}
        </Box>
        <Box className={classes.invoices}>
          <DataTable
            cellPropsGenerator={cellPropsGenerator}
            cellRenderer={cellRenderer}
            headings={headings}
            rows={invoices}
            hideTableHead
          />
        </Box>
      </DialogContent>
    </>
  );
}
