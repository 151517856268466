import CheckoutItem from './CheckoutItem.js';

function CheckoutList({
  items,
  updateItem,
  disableUpdate,
  hideAddon,
  handleAddonChange,
  insertAddonLoader,
  showAddonPricing,
  showCTA,
  isInvoicePayment,
  deleteCartItem,
  disableDelete,
  cartErrors,
  dateErrors,
  cart
}) {
  if (!items || !items?.length) {
    return <></>;
  }

  return (
    <>
      {items.map((item, i) => (
        <CheckoutItem
          key={i}
          item={item}
          updateItem={updateItem}
          hideAddon={hideAddon}
          disableUpdate={item.media_type == 'custom' ? true : disableUpdate}
          handleAddonChange={handleAddonChange}
          showAddonPricing={showAddonPricing}
          showCTA={showCTA}
          isInvoicePayment={isInvoicePayment}
          deleteCartItem={deleteCartItem}
          disableDelete={disableDelete}
          cartErrors={cartErrors}
          dateErrors={dateErrors}
          insertAddonLoader={insertAddonLoader}
          cart={cart}
        />
      ))}
    </>
  );
}

export default CheckoutList;
