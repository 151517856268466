import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {IntercomProvider} from 'react-use-intercom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from './theme/theme.js';
import LoginPage from 'components/pages/LoginPage';
import {StateProvider} from './store/store.js';
import {Footer, MainNavBar} from 'components/layout';
import NotFoundPage from 'components/pages/NotFoundPage.js';
import TestPage from './components/pages/TestPage.js';
import InvoicePage from './components/pages/InvoicePage/InvoicePage.js';
import AccountIndex from 'components/pages/dashboard/AccountIndex.js';
import useGlobalStyles from './lib/hooks/useGlobalStyles.js';
import useAppState from './lib/hooks/useAppState.js';
import Dashboard from './components/layout/Dashboard.js';
import InvoiceLayout from './components/layout/InvoiceLayout.js';
import Container from '@material-ui/core/Container';
import SnackbarWrapper from 'components/layout/SnackbarWrapper.js';
import ClientsPage from 'components/pages/dashboard/ClientsPage.js';
import ClientEditPage from 'components/pages/dashboard/clients/ClientEditPage.js';
import BillingPage from 'components/pages/dashboard/BillingPage.js';
import CheckoutPage from 'components/pages/dashboard/CheckoutPage.js';
import OrderLayout from 'components/layout/OrderLayout.js';
import OrderPage from 'components/pages/dashboard/OrderPage.js';
import OrdersAllPage from './components/pages/dashboard/orders/OrdersAllPage.js';
import OrdersUpcomingPage from './components/pages/dashboard/orders/OrdersUpcomingPage.js';
import OrdersInProcessPage from './components/pages/dashboard/orders/OrdersInProcessPage.js';
import OrdersHistoryPage from './components/pages/dashboard/orders/OrdersHistoryPage.js';
import OrdersCanceledPage from './components/pages/dashboard/orders/OrdersCanceledPage.js';
import CustomEventHandler from './components/elements/functional/CustomEventHandler.js';
import ThrottledModal from './components/elements/common/ThrottledModal.js';
import HashListener from './components/elements/functional/HashListener.js';
import IntercomBoot from './components/elements/functional/IntercomBoot.js';
import {INTERCOM_APP_ID} from './lib/config';
import {useHistory} from 'react-router-dom';
import TeamPage from 'components/pages/dashboard/TeamPage.js';
import TeamInvitePage from 'components/pages/dashboard/team/TeamInvitePage.js';
import TeamEditPage from 'components/pages/dashboard/team/TeamEditPage.js';
import actions from 'store/actions';
import LoadHotjar from './components/elements/functional/LoadHotjar';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import {LocalizationProvider} from '@material-ui/pickers';
import FilesPage from 'components/pages/dashboard/FilesPage.js';
import FilePage from 'components/pages/dashboard/FilePage.js';
import {Helmet} from 'react-helmet';

const Logout = () => {
  const {dispatch} = useAppState();
  dispatch({
    type: actions.LOGOUT,
  });
  return null;
};

const ProtectedRoute = (props) => {
  const history = useHistory();
  const {state} = useAppState();
  const authenticated = !!state.token;

  if (!authenticated) {
    window.setTimeout(() => {
      history.push(`/login?goto=${props.location.pathname}`);
    }, 1);
  }

  return <Route {...props} />;
};

const MetaTags = ({title}) => {
  return <Helmet>{title && <title>{title} | Addy Dashboard</title>}</Helmet>;
};

export default function App() {
  useGlobalStyles();

  return (
    <Router>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <IntercomProvider appId={INTERCOM_APP_ID}>
          <StateProvider>
            <LoadHotjar />
            <IntercomBoot />
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <CustomEventHandler />
              <HashListener />
              <ThrottledModal />
              <MainNavBar />
              <SnackbarWrapper>
                <Container component="main" id="app-root">
                  <MetaTags title="Addy Dashboard" />
                  <Switch>
                    <Route exact path="/">
                      <Redirect to="/login" />
                    </Route>

                    <Route path="/login" exact>
                      <MetaTags title="Login" />
                      <LoginPage />
                    </Route>

                    <Route path="/logout" exact>
                      <MetaTags title="Logout" />
                      <Logout />
                    </Route>

                    <Route path="/password-reset" exact>
                      <MetaTags title="Reset Password" />
                      <LoginPage />
                    </Route>

                    <Route path="/test" exact>
                      <TestPage />
                    </Route>

                    <Route path="/invoice/view/:cartId/:orderId" exact>
                      <MetaTags title="Invoice" />
                      <InvoiceLayout>
                        <InvoicePage />
                      </InvoiceLayout>
                    </Route>

                    <Route exact path="/checkout/:cartId">
                      <MetaTags title="Checkout" />
                      <CheckoutPage />
                    </Route>

                    <ProtectedRoute path="/account">
                      <MetaTags title="Account" />
                      <Dashboard>
                        <AccountIndex />
                      </Dashboard>
                    </ProtectedRoute>

                    <ProtectedRoute exact path="/clients/:id/edit">
                      <MetaTags title="Edit Client" />
                      <Dashboard>
                        <ClientEditPage />
                      </Dashboard>
                    </ProtectedRoute>

                    <ProtectedRoute exact path="/clients">
                      <MetaTags title="Clients" />
                      <Dashboard>
                        <ClientsPage />
                      </Dashboard>
                    </ProtectedRoute>

                    <ProtectedRoute exact path="/billing">
                      <MetaTags title="Billing" />
                      <Dashboard>
                        <BillingPage />
                      </Dashboard>
                    </ProtectedRoute>

                    <ProtectedRoute exact path="/order/:id/:action?">
                      <MetaTags title="Order" />
                      <OrderLayout>
                        <OrderPage />
                      </OrderLayout>
                    </ProtectedRoute>

                    <ProtectedRoute exact path="/orders/all">
                      <MetaTags title="All Orders" />
                      <Dashboard>
                        <OrdersAllPage />
                      </Dashboard>
                    </ProtectedRoute>

                    <ProtectedRoute exact path="/orders">
                      <MetaTags title="Orders" />
                      <Dashboard>
                        <OrdersAllPage />
                      </Dashboard>
                    </ProtectedRoute>

                    <ProtectedRoute exact path="/orders/upcoming">
                      <MetaTags title="Upcoming Orders" />
                      <Dashboard>
                        <OrdersUpcomingPage />
                      </Dashboard>
                    </ProtectedRoute>

                    <ProtectedRoute exact path="/orders/inprocess">
                      <MetaTags title="In Process Orders" />
                      <Dashboard>
                        <OrdersInProcessPage />
                      </Dashboard>
                    </ProtectedRoute>

                    <ProtectedRoute exact path="/orders/history">
                      <MetaTags title="Order History" />
                      <Dashboard>
                        <OrdersHistoryPage />
                      </Dashboard>
                    </ProtectedRoute>

                    <ProtectedRoute exact path="/orders/canceled">
                      <MetaTags title="Canceled Orders" />
                      <Dashboard>
                        <OrdersCanceledPage />
                      </Dashboard>
                    </ProtectedRoute>

                    <ProtectedRoute exact path="/team">
                      <MetaTags title="Team" />
                      <Dashboard>
                        <TeamPage />
                      </Dashboard>
                    </ProtectedRoute>

                    <ProtectedRoute exact path="/team/invite">
                      <MetaTags title="Add Team Members" />
                      <Dashboard>
                        <TeamInvitePage />
                      </Dashboard>
                    </ProtectedRoute>

                    <ProtectedRoute exact path="/team/:id/edit">
                      <MetaTags title="Edit Team Member" />
                      <Dashboard>
                        <TeamEditPage />
                      </Dashboard>
                    </ProtectedRoute>

                    <ProtectedRoute exact path="/files">
                      <MetaTags title="Ad Files" />
                      <Dashboard>
                        <FilesPage />
                      </Dashboard>
                    </ProtectedRoute>

                    <ProtectedRoute exact path="/file/:id">
                      <MetaTags title="View File" />
                      <Dashboard>
                        <FilePage />
                      </Dashboard>
                    </ProtectedRoute>

                    <Route>
                      <MetaTags title="Page Not Found" />
                      <NotFoundPage />
                    </Route>
                  </Switch>
                </Container>
              </SnackbarWrapper>

              <Footer />
            </ThemeProvider>
          </StateProvider>
        </IntercomProvider>
      </LocalizationProvider>
    </Router>
  );
}
