const initialState = {
  token: '',
  tokenCreatedAt: 0,
  userData: {},
  temporary: {
    filter: '',
    isThrottled: false,
  },
  myTeam: {
    contacts: [],
    meta: {},
    isLoaded: false,
  },
  myCompany: {
    data: {},
    isLoaded: false,
  },
  myClients: {
    clients: [],
    meta: {},
    isLoaded: false,
    currentMeta: {},
    current: null,
  },
  promocodes: {
    codes: [],
    isLoaded: false,
  },
  addresses: {
    addresses: [],
    isLoaded: false,
  },
  paymentMethods: {
    cards: [],
    banks: [],
    isLoaded: false,
  },
  billing: {
    data: [],
    meta: {},
    isLoaded: false,
  },
  files: {
    data: [],
    meta: {},
    isLoaded: false,
    current: null,
  },
  orders: {
    data: [],
    meta: {},
    isLoaded: false,
    current: null,
  },
  timeline: {
    data: [],
    isLoaded: false,
  },
  ordersStats: {
    data: {},
    isLoaded: false,
  },
  orderMetrics: {
    data: {},
    isLoaded: false,
  },
  invoice: {
    data: {
      order: {},
      all: [],
      user: {},
      all_total: '',
    },
    isLoaded: false,
  },
  queriesId: 0,
  station: {},
  isLoading: false,
  socketData: {},
  isSocketConnected: false,
};

export default initialState;
