import React from 'react';
import Button from '@material-ui/core/Button';
import {useIntercom} from 'react-use-intercom';
import {makeStyles} from '@material-ui/core';
import {ReactComponent as ExternalLink} from 'images/externalLink.svg';
import useLinkHandler from '../../../lib/hooks/useLinkHandler';

const useStyles = makeStyles((theme) => ({
  intercomWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 60,

    [theme.breakpoints.down('sm')]: {
      marginTop: 15,
      flexDirection: 'column',
      alignItems: 'space-between',

      '& button': {
        width: '100%',
      },
    },
  },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      marginRight: 25,
    },
  },
  link: {
    marginLeft: 10,
    cursor: 'pointer',
    fontSize: 17,
    lineHeight: '24px',
  },
  helpButton: {
    borderColor: theme.palette.neutral[900],

    '& .MuiButton-label': {
      fontWeight: 800,
      fontSize: 17,
      lineHeight: '24px',
    },
  },
}));

export default function OrderDesignHelp({order}) {
  const classes = useStyles();
  const {show} = useIntercom();
  const {getLinkButtonProps} = useLinkHandler();

  return (
    <div className={classes.intercomWrapper}>
      <Button className={classes.helpButton} variant="outlined" onClick={show}>
        Get Design Help️
      </Button>
      <div className={classes.linkWrapper}>
        <ExternalLink />
        <a className={classes.link} {...getLinkButtonProps({url: order?.media_specs.cta_url})}>
          {order?.media_specs.cta_label}
        </a>
      </div>
    </div>
  );
}
