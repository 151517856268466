import React from 'react';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ProfileForm from 'components/elements/ProfilePage/ProfileForm.js';
import PasswordForm from 'components/elements/ProfilePage/PasswordForm.js';
import AvatarContainer from 'components/elements/common/AvatarContainer.js';
import useAppState from 'lib/hooks/useAppState.js';
import theme from 'theme';
import useData from '../../../lib/hooks/useData.js';

const useStyles = makeStyles(() => ({
  container: {
    //minHeight: '100vh',

    '& h3': {
      fontSize: 21,
      lineHeight: '100%',
      marginBottom: 15,
    },

    '& .divider-top': {
      marginBottom: 24,
    },

    '& .divider-bottom': {
      marginTop: 24,
    },

    [theme.default.breakpoints.up('tb')]: {
      '& .divider-top': {
        display: 'none',
      },

      '& h3': {
        fontSize: 34,
      },
    },
  },
}));

export default function ProfilePage() {
  const {state} = useAppState();
  const classes = useStyles();
  // useUserData();

  useData({
    queries: ['getUser'],
    setLoadingState: true,
  });

  return (
    <Box className={classes.container}>
      <Divider className="divider-top" />

      <AvatarContainer
        src={state.userData.avatar}
        badge={state.userData.is_agency ? 'Agency' : 'Client'}
        uploadUrl="/my/avatar"
      />

      <Divider />

      <ProfileForm />

      <PasswordForm />
    </Box>
  );
}
