import {useEffect, useState} from 'react';
import {Box, Dialog, Typography, DialogTitle, IconButton, CircularProgress, makeStyles} from '@material-ui/core';
import {ReactComponent as CloseDialogIcon} from 'images/closeDialog.svg';
import DataTable from 'components/elements/common/DataTable';
import DataController from 'lib/controllers/DataController';
import NoDataFound from 'components/elements/common/NoDataFound';
import {useSnackbar} from 'notistack';

const useStyles = makeStyles((theme) => ({
  xCloseButton: {
    marginLeft: 'auto',
    marginRight: 0,
    marginTop: 2,
  },
  dialogTitleCloseButton: {
    display: 'flex',
    width: '100%',
    padding: 24,
  },
  title: {
    marginRight: 24,
  },
  dialog: {
    '& .MuiDialog-paper': {
      padding: 0,
    },

    [theme.breakpoints.up('tb')]: {
      '& .MuiDialog-paper': {
        minWidth: 636,
      },
    },
  },
}));

const headings = [
  {
    id: 'zipcode',
    label: 'Zip Code',
    key: 'zipcode',
  },
  {
    id: 'addresses',
    label: 'Addresses',
    key: 'addresses',
  },
  {
    id: 'city',
    label: 'City',
    key: 'city',
  },
  {
    id: 'state',
    label: 'State',
    key: 'state',
  },
];

export default function RoutesDialog({handleClose, open, order}) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (open && !isLoaded) {
      setLoading(true);

      DataController.getABDMZips(order.id).then(({data, meta, success, error, message}) => {
        if (success) {
          setData(data);
          setMeta(meta);
          setIsLoaded(true);
        } else {
          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
        setLoading(false);
      });
    }
  }, [open, isLoaded]);

  const renderCell = ({key}, data) => {
    switch (key) {
      case 'zipcode':
        return data['Zip code'];
      case 'addresses':
        return data['Address(es)'];
      case 'city':
        return data['City'];
      case 'state':
        return data['State'];
      default:
        return data[key] || '-';
    }
  };

  return (
    <Dialog
      className={classes.dialog}
      onClose={() => {
        handleClose();
      }}
      open={open}
      disableEnforceFocus={true}
    >
      <Box className={classes.dialogTitleCloseButton}>
        <DialogTitle className={classes.title} disableTypography>
          {loading ? (
            <Typography variant="h5">Loading Zips...</Typography>
          ) : (
            <Typography variant="h5">
              {meta.total_zips} zip{meta.count === 1 ? '' : 's'} • {meta.total_addresses} addresses
            </Typography>
          )}
        </DialogTitle>
        <IconButton
          className={classes.xCloseButton}
          onClick={handleClose}
          size="small"
          aria-label="close"
          color="secondary"
        >
          <CloseDialogIcon />
        </IconButton>
      </Box>
      <Box>
        {loading ? (
          <Box m={4} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={28} className={classes.loader} />
          </Box>
        ) : isLoaded && data.length === 0 ? (
          <NoDataFound>No routes found.</NoDataFound>
        ) : (
          <DataTable cellRenderer={renderCell} headings={headings} rows={data} />
        )}
      </Box>
    </Dialog>
  );
}
