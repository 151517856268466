import React, {useState, useEffect} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import OrderSidebarHeader from './OrderSidebarHeader';
import OrderReceipt from './OrderReceipt';
import OrderReceiptAddons from './OrderReceiptAddons';
import OrderExtraAddons from './OrderExtraAddons';
import AccordionWrap from '../../common/AccordionWrap.js';
import OrderSidebarTotals from './OrderSidebarTotals';

const useStyles = makeStyles((theme) => ({
  wrap: {
    background: theme.palette.neutral[200],
    minHeight: '100%',
  },
  accordionWrap: {
    padding: '24px 16px',

    [theme.breakpoints.up('xl')]: {
      padding: '24px 32px',
    },
  },
  addOnsWrap: {
    flex: 1,
    background: theme.palette.neutral[300],
    padding: '24px 16px',

    [theme.breakpoints.up('xl')]: {
      padding: '24px 32px',
    },
  },
}));

export default function OrderSidebar({order}) {
  const classes = useStyles();
  const [addons, setAddons] = useState([]);

  useEffect(() => {
    const addons = order.available_addons && order.available_addons.filter((addon) => addon.is_available);
    setAddons(addons);
  }, [order]);

  return (
    <Box className={classes.wrap} display="flex" flexDirection="column">
      <OrderSidebarHeader order={order} />
      <OrderSidebarTotals order={order} />
      <Box className={classes.accordionWrap}>
        <AccordionWrap id="accord-receipt" title="Order details">
          <OrderReceipt order={order} />
        </AccordionWrap>

        {order.receipt_extra_addons && order.receipt_extra_addons.length > 0 && (
          <AccordionWrap id="accord-receipt-addons" title="Extra add ons">
            <OrderReceiptAddons addons={order.receipt_extra_addons} />
          </AccordionWrap>
        )}
      </Box>
      {addons && addons.length > 0 && (
        <Box className={classes.addOnsWrap}>
          <OrderExtraAddons addons={addons} order={order} />
        </Box>
      )}
    </Box>
  );
}
