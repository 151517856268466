import {apiUrl, cartCookieName, repcodeCookieName} from '../config.js';
import PersistentStorage from './PersistentStorage.js';
import {fireCustomEvent, customEvents} from '../../components/elements/functional/CustomEventHandler.js';

class DataController {
  constructor() {
    this.baseURL = apiUrl;
    this.cartCookie = cartCookieName;
    this.apiCallInc = 0;
  }

  getCookie(name) {
    let matches = document.cookie.match(
      new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  async APICall(url, opts) {
    // console.log(`[DataController] APICall ${this.apiCallInc}`, url, opts);
    // this.apiCallInc++;
    // return fetch(`${this.baseURL}${url}`, opts);

    // logging
    console.log(`[DataController] APICall ${this.apiCallInc}`, url, opts);
    this.apiCallInc++;

    const fullUrl = url.startsWith('http') ? url : this.baseURL + url;

    // legacy API call if opts.raw
    // does not transform to JSON
    if (opts.raw) {
      delete opts.raw;
      return await fetch(fullUrl, opts);
    }

    // fetch w/advanced error handling
    try {
      const res = await fetch(fullUrl, opts);
      const resJSON = await res.json();
      if (res.status === 401) {
        fireCustomEvent(customEvents.APIError, {status: res.status, body: resJSON});
      } else if (res.status === 429) {
        fireCustomEvent(customEvents.APIError, {status: res.status});
      }
      if (resJSON.success && resJSON.meta && resJSON.meta.drop_cookies) {
        for (let i = 0; i < resJSON.meta.drop_cookies.length; i++) {
          document.cookie = resJSON.meta.drop_cookies[i];
        }
      }
      return resJSON;
    } catch (error) {
      fireCustomEvent(customEvents.APIError, error);
      throw new Error(error);
    }
  }

  APICallAuth(url, opts) {
    const token = PersistentStorage.get('token');

    if (!token) {
      console.log('[DataController] token not found');
      return new Promise((resolve, reject) =>
        resolve({
          success: false,
        })
      );
    }

    const authHeaders = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    };
    const optHeaders = opts.headers ? opts.headers : {};
    const newHeaders = {...authHeaders, ...optHeaders};

    const newOpts = Object.assign(opts, {
      headers: newHeaders,
    });

    return this.APICall(url, newOpts);
  }

  uploadProgressAuth(url, opts, onProgress, auth = true) {
    return new Promise((resolve, reject) => {
      try {
        const request = new XMLHttpRequest();

        request.open(opts.method || 'POST', url.startsWith('http') ? url : `${this.baseURL}${url}`, true);

        const token = PersistentStorage.get('token');
        //request.setRequestHeader('Content-Type', 'multipart/form-data');
        request.setRequestHeader('Accept', 'application/json');
        if (auth) request.setRequestHeader('Authorization', `Bearer ${token}`);

        request.upload.addEventListener('progress', (e) => {
          if (typeof onProgress === 'function') onProgress((e.loaded / e.total) * 100);
        });

        request.addEventListener('load', (e) => {
          resolve(opts.raw ? request.response : JSON.parse(request.response));
        });

        request.addEventListener('error', (e) => resolve({error: e}));
        request.addEventListener('abort', (e) => resolve({error: e}));

        request.send(opts.body);
      } catch (e) {
        resolve({error: e});
      }
    });
  }

  login(email, password) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    const opt = {
      method: 'POST',
      body: formData,
    };

    return this.APICall(`/login`, opt);
  }

  register(email, password, firstname, lastname, company) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('company', company);

    const rep_name = this.getCookie(repcodeCookieName);
    if (rep_name) {
      formData.append('rep_name', rep_name);
    }

    const opt = {
      method: 'POST',
      body: formData,
    };

    return this.APICall(`/register`, opt);
  }

  loginViaSession(cookieVal) {
    const formData = new FormData();
    formData.append('session', cookieVal);

    const opt = {
      method: 'POST',
      body: formData,
    };

    const addy_cart_id = this.getCookie(this.cartCookie);
    const url = addy_cart_id ? `/login/session?cart=${addy_cart_id}` : `/login/session`;

    return this.APICall(url, opt);
  }

  loginViaRefresh(cookieVal) {
    const opt = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${cookieVal}`,
      },
    };

    const url = '/login/refresh';

    console.log('APICall: '.url);
    return this.APICall(url, opt);
  }

  getUser() {
    const opts = {
      method: 'GET',
    };

    const addy_cart_id = this.getCookie(this.cartCookie);
    const url = addy_cart_id ? `/me?cart=${addy_cart_id}` : `/me`;

    return this.APICallAuth(url, opts);
  }

  inviteTeamMembers(emails, companyId) {
    const formData = new FormData();
    formData.append('emails', emails.join(','));
    if (companyId) {
      formData.append('companyid', companyId);
    }

    const opt = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/team/invite`, opt);
  }

  getTeamMember(id) {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth(`/team/${id}`, opts);
  }

  postTeamMember({firstname, lastname, phone, id}) {
    const formData = new FormData();
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('phone', phone);

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/team/${id}/edit`, opts);
  }

  postTeamMemberRole({role, companyId, contactId}) {
    const formData = new FormData();
    formData.append('role', role);

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/team/${contactId}/role/${companyId}`, opts);
  }

  deleteTeamMember(contactId) {
    const opts = {
      method: 'POST',
    };

    return this.APICallAuth(`/team/${contactId}/delete`, opts);
  }

  getMyTeam() {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth(`/my/team`, opts);
  }

  getMyAddresses() {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth(`/my/addresses`, opts);
  }

  createAddress(data) {
    const formData = new FormData();
    formData.append('addressee', data.addressee);
    formData.append('attention', data.attention);
    formData.append('street', data.street);
    formData.append('street2', data.street2);
    formData.append('city', data.city);
    formData.append('state', data.state);
    formData.append('zip', data.zip);
    formData.append('country', data.country);

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/address/create`, opts);
  }

  updateAddress(data) {
    const formData = new FormData();
    formData.append('addressee', data.addressee);
    formData.append('attention', data.attention);
    formData.append('street', data.street);
    formData.append('street2', data.street2);
    formData.append('city', data.city);
    formData.append('state', data.state);
    formData.append('zip', data.zip);
    formData.append('country', data.country);

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/address/${data.id}`, opts);
  }

  postDefaultAddress(addressId) {
    const opts = {
      method: 'POST',
    };

    return this.APICallAuth(`/address/${addressId}/default`, opts);
  }

  deleteAddress(addressId) {
    const opts = {
      method: 'POST',
    };

    return this.APICallAuth(`/address/${addressId}/delete`, opts);
  }

  getMyPromocodes() {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth(`/my/promocodes`, opts);
  }

  getMyPaymentMethods() {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth(`/my/paymentmethods`, opts);
  }

  getPlaidLinkToken(transaction) {
    const opts = {
      method: 'GET',
    };

    // Invoice page: For unauthorized users
    if (transaction) {
      return this.APICall(`/invoice/${transaction}/ach`, opts);
    }

    return this.APICallAuth(`/paymentmethod/plaid/linktoken`, opts);
  }

  getPlaidPaymentMethod({public_token, account_id}) {
    const formData = new FormData();
    formData.append('public_token', public_token);
    formData.append('account_id', account_id);

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth('/paymentmethod/plaid/banktoken', opts);
  }

  paymentMethodDelete(id) {
    const opts = {
      method: 'POST',
    };

    return this.APICallAuth(`/paymentmethod/${id}/delete`, opts);
  }

  paymentMethodEdit(id, data) {
    const formData = new FormData();
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/paymentmethod/${id}`, opts);
  }

  createPaymentMethod({type, token, ...data}) {
    if (!['card', 'ach'].includes(type)) return;

    const formData = new FormData();
    formData.append('token', token);
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/paymentmethod/create/${type}`, opts);
  }

  createPaymentMethodAch({...data}) {
    const formData = new FormData();
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/paymentmethod/plaid/banktoken`, opts);
  }

  postProfile({email, firstname, lastname, phone}) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('phone', phone);

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth('/my/profile', opts);
  }

  getMyCompany() {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth('/my/company', opts);
  }

  getMyClients() {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth('/my/clients', opts);
  }

  getCurrentClient(id) {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth(`/client/${id}`, opts);
  }

  // create member-client relationships:

  addClientsToMember({memberId, companyIds}) {
    const formData = new FormData();
    formData.append('companyids', companyIds);

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/team/${memberId}/client/add`, opts);
  }

  removeClientsFromMember({memberId, companyIds}) {
    const formData = new FormData();
    formData.append('companyids', companyIds);

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/team/${memberId}/client/remove`, opts);
  }

  // create client-member relationships:

  addMembersToClient({memberIds, companyId}) {
    const formData = new FormData();
    formData.append('contactids', memberIds);

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/client/${companyId}/contact/add`, opts);
  }

  removeMembersFromClient({memberIds, companyId}) {
    const formData = new FormData();
    formData.append('contactids', memberIds);

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/client/${companyId}/contact/remove`, opts);
  }

  createCompany({name, mrid, billing_contact_name, billing_contact_phone, billing_contact_email}) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('mrid', mrid ? mrid : '');
    formData.append('billing_contact_name', billing_contact_name ? billing_contact_name : '');
    formData.append('billing_contact_phone', billing_contact_phone ? billing_contact_phone : '');
    formData.append('billing_contact_email', billing_contact_email ? billing_contact_email : '');

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/company/create`, opts);
  }

  deleteCompany(id) {
    const opts = {
      method: 'POST',
    };

    return this.APICallAuth(`/company/${id}/delete`, opts);
  }

  postCompanyInfo(id, payload) {
    const formData = new FormData();
    formData.append('name', payload.name);
    formData.append('street', payload.street);
    formData.append('street2', payload.street2);
    formData.append('city', payload.city);
    formData.append('state', payload.state);
    formData.append('zip', payload.zip);
    formData.append('country', payload.country);

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/company/${id}/info`, opts);
  }

  postCompanyBilling(id, payload) {
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/company/${id}/billing`, opts);
  }

  postCompanyNotifications(id, payload) {
    const formData = new FormData();
    formData.append('material_reminder', payload.material_reminder);

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/company/${id}/billing`, opts);
  }

  forgotPassword(email) {
    const formData = new FormData();
    formData.append('email', email);

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICall('/forgotpassword', opts);
  }

  postAssociateFiles(orderId, data) {
    const opts = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {'Content-Type': 'application/json'},
    };

    return this.APICallAuth(`/files/associate/${orderId}`, opts);
  }

  changePassword({password, confirmPassword}) {
    const formData = new FormData();
    formData.append('password', password);
    formData.append('password_confirm', confirmPassword);

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/my/password`, opts);
  }

  // uploadAvatar(avatar, url) {
  //     const formData = new FormData();
  //     formData.append('avatar', avatar);
  //
  //     const opts = {
  //         method: 'POST',
  //         body: formData
  //     };
  //
  //     return this.APICallAuth(url, opts).then(res => res.json());
  // }

  uploadAvatar(avatar, url, onProgress) {
    const formData = new FormData();
    formData.append('avatar', avatar);

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.uploadProgressAuth(url, opts, onProgress);
  }

  getOrdersStats() {
    return this.APICallAuth('/orders/stats', {method: 'GET'});
  }

  getBilling(company, status, sort, page) {
    const url = `/billing?${new URLSearchParams({
      ...(company ? {company} : {}),
      ...(status ? {status} : {}),
      ...(sort ? {sort} : {}),
      ...(page ? {page} : {}),
    })}`;

    return this.APICallAuth(url, {method: 'GET'});
  }

  getOrders(company, media_type, sort, page, status) {
    const url = `/my/orders?${new URLSearchParams({
      ...(status ? {status} : {}),
      ...(company ? {company} : {}),
      ...(media_type ? {media_type} : {}),
      ...(sort ? {sort} : {}),
      ...(page ? {page} : {}),
    })}`;

    return this.APICallAuth(url, {method: 'GET'});
  }

  getOrder(id) {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth(`/order/${id}`, opts);
  }

  updateOrder(id, {company}) {
    const formData = new FormData();

    if (company) {
      formData.append('company', company);
    }

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/order/${id}/edit`, opts);
  }

  cancelOrder(id) {
    const opts = {
      method: 'POST',
    };

    return this.APICallAuth(`/order/${id}/cancel`, opts);
  }

  getOrderMetrics(id) {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth(`/order/${id}/metrics`, opts);
  }

  getOrderEddm(id) {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth(`/order/${id}/eddm`, opts);
  }

  getABDMZips(id) {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth(`/order/${id}/abdm`, opts);
  }

  getFilesPickup(id) {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth(`/files/pickup/${id}`, opts);
  }

  postFilesPickup(id, ids) {
    const formData = new FormData();
    formData.append('tids', ids.join(','));

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/files/pickup/${id}`, opts);
  }

  getFileCandidateOrders(fileId) {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth(`/file/${fileId}/orders`, opts);
  }

  postReuseFileOnOrders(fileId, ids) {
    const formData = new FormData();
    formData.append('tids', ids.join(','));

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/file/${fileId}/orders`, opts);
  }

  getFiles(media_type, favorite, sort, page, company) {
    const url = `/my/files?${new URLSearchParams({
      ...(media_type ? {media_type} : {}),
      ...(favorite ? {favorite} : {}),
      ...(sort ? {sort} : {}),
      ...(page ? {page} : {}),
      ...(company ? {company} : {}),
    })}`;

    return this.APICallAuth(url, {method: 'GET'});
  }

  getFile(id) {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth(`/file/${id}`, opts);
  }

  updateFileFavorite(id, isFavorite) {
    const formData = new FormData();

    formData.append('is_favorite', isFavorite ? 1 : 0);

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/file/${id}/favorite`, opts);
  }

  shareFile(id, emails) {
    const formData = new FormData();
    formData.append('emails', emails.join(','));

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/file/${id}/share`, opts);
  }

  getFileSets(id) {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth(`/file/${id}/sets`, opts);
  }

  getTimeline(id) {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth(`/order/${id}/timeline`, opts);
  }

  getOrderSpecFiles(order, spec) {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth(`/files/order/${order}/${spec}`, opts);
  }

  getOrderFiles(id) {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth(`/order/${id}/files`, opts);
  }

  searchOrders(query) {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth(`/orders/search?q=${query}`, opts);
  }

  getFileUploadConfig(filename) {
    const opts = {
      method: 'GET',
    };

    return this.APICallAuth(`/file/upload/config?filename=${filename}`, opts);
  }

  downloadBilling(url) {
    return this.APICallAuth(url, {method: 'GET', raw: true});
  }

  downloadPdf(url, invoiceNumber) {
    const token = PersistentStorage.get('token');

    const opts = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    return fetch(`${url}?number=${invoiceNumber}`, opts);
  }

  downloadInvoicePdf({type, cartId, orderId}) {
    const opts = {
      headers: {
        Accept: 'application/json',
      },
    };

    return fetch(`${apiUrl}/invoice/pdf/${type}/${cartId}/${orderId}`, opts);
  }

  purchaseAddons(orderId, data) {
    const opts = {
      method: 'POST',
      body: JSON.stringify(data),
    };

    return this.APICallAuth(`/order/${orderId}/addons`, opts);
  }

  updatePurchasedAddon(addonId, {address}) {
    const formData = new FormData();

    if (address) {
      formData.append('address', address);
    }

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/addon/${addonId}/edit`, opts);
  }

  updateCampaignUrl({orderId, link}) {
    const formData = new FormData();
    if (link) {
      formData.append('link', link);
    }

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/files/url/${orderId}`, opts);
  }

  getInvoice(tid, orderId) {
    const opts = {
      method: 'GET',
    };

    return this.APICall(`/invoice/${orderId}/${tid}`, opts);
  }

  payInvoice(invoices, stripToken) {
    const formData = new FormData();
    for (let i = 0; i < invoices.length; i++) {
      formData.append('tid[]', invoices[i]);
    }
    formData.append('token', stripToken);

    const opts = {
      method: 'POST',
      body: formData,
    };

    return this.APICall(`/invoice/pay`, opts);
  }

  sendInvoice({emails, cartId, orderId}) {
    const formData = new FormData();
    formData.append('emails', emails.join(','));
    const opt = {
      method: 'POST',
      body: formData,
    };

    return this.APICall(`/invoice/send/${cartId}/${orderId}`, opt);
  }

  payInvoiceAnon({invoices, paymentData}) {
    const token = PersistentStorage.get('token');
    const formData = new FormData();
    invoices.forEach((invoice) => {
      formData.append('tid[]', invoice);
    });
    Object.keys(paymentData).forEach((key) => {
      formData.append(key, paymentData[key]);
    });

    const opts = {
      method: 'POST',
      body: formData,
    };

    if (token) {
      opts.headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    return this.APICall(`/invoice/pay`, opts);
  }

  getCartItems(cartId, isAuthenticated) {
    const opts = {
      method: 'GET',
    };
    if (isAuthenticated) {
      return this.APICallAuth(`/checkout/${cartId}`, opts);
    }
    return this.APICall(`/checkout/${cartId}`, opts);
  }

  getCartItemDates(cartId, itemId) {
    const token = PersistentStorage.get('token');
    const url = `/checkout/${cartId}/item/${itemId}/dates`;
    const opts = {
      method: 'GET',
    };

    if (token) {
      return this.APICallAuth(url, opts);
    }

    return this.APICall(url, opts);
  }

  updateCartItem(cartId, itemId, dateStart) {
    const token = PersistentStorage.get('token');
    const formData = new FormData();
    formData.append('date_start', dateStart);
    const url = `/checkout/${cartId}/item/${itemId}/edit`;

    const opt = {
      method: 'POST',
      body: formData,
    };

    if (token) {
      return this.APICallAuth(url, opt);
    }

    return this.APICall(url, opt);
  }

  deleteCartItem(cartId, itemId) {
    const token = PersistentStorage.get('token');
    const url = `/checkout/${cartId}/item/${itemId}/delete`;
    const opt = {
      method: 'POST',
    };

    if (token) {
      return this.APICallAuth(url, opt);
    }

    return this.APICall(url, opt);
  }

  updateCartPaymentType(cartId, type) {
    const token = PersistentStorage.get('token');
    const formData = new FormData();
    formData.append('type', type);

    const url = `/checkout/${cartId}/payment/type`;
    let opts = {
      method: 'POST',
      body: formData,
    };

    if (token) {
      return this.APICallAuth(url, opts);
    }

    return this.APICall(url, opts);
  }

  addPromoCode(cartId, promoCode) {
    const token = PersistentStorage.get('token');
    const formData = new FormData();
    formData.append('promocode', promoCode);

    const url = `/checkout/${cartId}/promocode`;
    let opts = {
      method: 'POST',
      body: formData,
    };

    if (token) {
      return this.APICallAuth(url, opts);
    }

    return this.APICall(url, opts);
  }

  removePromoCode(cartId) {
    const token = PersistentStorage.get('token');
    const url = `/checkout/${cartId}/promocode/delete`;
    let opts = {
      method: 'POST',
    };

    if (token) {
      return this.APICallAuth(url, opts);
    }

    return this.APICall(url, opts);
  }

  validateCart(cartId) {
    const token = PersistentStorage.get('token');
    const url = `/checkout/${cartId}/validate/addons`;
    let opts = {
      method: 'GET',
    };

    if (token) {
      return this.APICallAuth(url, opts);
    }

    return this.APICall(url, opts);
  }

  validateDates(cartId) {
    const token = PersistentStorage.get('token');
    const url = `/checkout/${cartId}/validate/dates`;
    let opts = {
      method: 'GET',
    };

    if (token) {
      return this.APICallAuth(url, opts);
    }

    return this.APICall(url, opts);
  }

  insertAddon(cartId, itemId, payload) {
    const formData = new FormData();
    formData.append('type', payload.type);
    formData.append('qty', payload.qty);
    formData.append('address_id', payload.address_id);

    const opt = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/checkout/${cartId}/item/${itemId}/addon`, opt);
  }

  updateAddon(cartId, itemId, addonId, payload) {
    const formData = new FormData();
    formData.append('qty', payload.qty);
    formData.append('address_id', payload.address_id);

    const opt = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/checkout/${cartId}/item/${itemId}/addon/${addonId}/edit`, opt);
  }

  deleteAddon(cartId, itemId, addonId) {
    const opt = {
      method: 'POST',
    };

    return this.APICallAuth(`/checkout/${cartId}/item/${itemId}/addon/${addonId}/delete`, opt);
  }

  checkoutClaimCart(cartId) {
    const opt = {
      method: 'POST',
    };

    return this.APICallAuth(`/checkout/${cartId}/claim`, opt);
  }

  checkoutPayementMethod(cartId, paymentmethod) {
    const formData = new FormData();
    formData.append('paymentmethod', paymentmethod);

    const opt = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/checkout/${cartId}/payment/method`, opt);
  }

  updateCheckoutCompany(cartId, companyId) {
    const formData = new FormData();
    formData.append('company', companyId);

    const opt = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/checkout/${cartId}/company`, opt);
  }

  checkoutPaymentAddress(cartId, payload = {}) {
    console.log('service------formData', payload, payload?.billing_same);
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });
    const opt = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/checkout/${cartId}/payment/address`, opt);
  }

  checkoutTransact(cartId, paymentData = {}) {
    const formData = new FormData();
    Object.keys(paymentData).forEach((key) => {
      formData.append(key, paymentData[key]);
    });
    const opt = {
      method: 'POST',
      body: formData,
    };

    return this.APICallAuth(`/checkout/${cartId}/transact`, opt);
  }
}

export default new DataController();
