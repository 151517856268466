import {hotjar} from 'react-hotjar';
import useAppState from '../../../lib/hooks/useAppState.js';
import {HOTJAR_ID, HOTJAR_SNIPPET_VERSION} from '../../../lib/config';

const LoadHotjar = () => {
  hotjar.initialize(HOTJAR_ID, HOTJAR_SNIPPET_VERSION);
  const {state} = useAppState();
  if (state?.userData?.uuid) {
    const uuid = state.userData.uuid;
    hotjar.identify(uuid, {uuid: uuid, timestamp: Date.now(), subdomain: 'dashboard'});
  }
  return null;
};

export default LoadHotjar;
