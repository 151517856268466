import {Box, makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  thumb: {
    position: 'relative',
    width: 100,
    height: 100,
    overflow: 'hidden',
    borderRadius: 5,
    marginRight: 12,

    '& img': {
      display: 'block',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  cancel: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    borderRadius: 14,
    border: `1px solid ${theme.palette.common.base}`,
    color: theme.palette.common.base,
    fontSize: 13,
    lineHeight: '18px',
    padding: '4px 8px',
    backgroundColor: theme.palette.common.white,
  },
}));

export default function OrderThumbnail({src, isCanceled}) {
  const classes = useStyles();

  return (
    <Box className={classes.thumb}>
      {isCanceled ? <div className={classes.cancel}>Canceled</div> : ''}
      <img src={src} alt="thumb" />
    </Box>
  );
}
