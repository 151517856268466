import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 34,
    letterSpacing: -0.45,
    color: theme.palette.secondary.main,
    marginBottom: 24,
  },
}));

function PaymentTableTitle({children}) {
  const classes = useStyles();

  return (
    <Typography className={classes.title} variant="h3">
      {children}
    </Typography>
  );
}

export default PaymentTableTitle;
