import React, {useState} from 'react';
import classNames from 'classnames';
import {IconButton, CircularProgress, Button, makeStyles} from '@material-ui/core';
import {ReactComponent as StarIcon} from 'images/star.svg';
import useAppState from 'lib/hooks/useAppState';
import DataController from 'lib/controllers/DataController';
import actions from 'store/actions';

const useStyles = makeStyles((theme) => ({
  starGray: {
    '& path': {
      stroke: theme.palette.neutral[500],
    },
  },

  starFavorite: {
    fill: theme.palette.primary.main,

    '& path': {
      stroke: theme.palette.primary.main,
    },
  },

  button: {
    width: 28,
    height: 28,
    minWidth: 28,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    background: theme.palette.common.offWhite,

    '& svg': {
      marginRight: 0,
    },
  },
}));

export default function FilesFavoriteButton({file, isCurrent = false, type = 'icon', ...otherProps}) {
  const classes = useStyles();
  const [isUpdatingFavorite, setIsUpdatingFavorite] = useState(false);
  const {dispatch} = useAppState();

  const updateFavorite = (id, isFavorite) => {
    setIsUpdatingFavorite(id);
    DataController.updateFileFavorite(id, isFavorite).then(({success, data}) => {
      if (success) {
        dispatch({
          type: actions.UPDATE_ROW,
          payload: {
            location: isCurrent ? `files.current` : `files.data`,
            data: isCurrent
              ? data
              : {
                  is_favorite: data.is_favorite,
                  id: data.id,
                },
            type: isCurrent ? '' : 'merge',
          },
        });
        setIsUpdatingFavorite(null);
      }
    });
  };

  return (
    <>
      {type === 'icon' ? (
        <IconButton onClick={() => updateFavorite(file.id, !file.is_favorite)} {...otherProps}>
          {isUpdatingFavorite === file.id ? (
            <CircularProgress variant="indeterminate" color="secondary" size={20} thickness={4} value={50} />
          ) : (
            <StarIcon className={classNames(file.is_favorite ? classes.starFavorite : '')} />
          )}
        </IconButton>
      ) : (
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => updateFavorite(file.id, !file.is_favorite)}
          {...otherProps}
          className={classes.button}
        >
          {isUpdatingFavorite === file.id ? (
            <CircularProgress variant="indeterminate" color="secondary" size={20} thickness={4} value={50} />
          ) : (
            <StarIcon className={classNames(classes.starGray, file.is_favorite ? classes.starFavorite : '')} />
          )}
        </Button>
      )}
    </>
  );
}
