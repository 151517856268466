import React, {useMemo} from 'react';
import {useHistory, useLocation} from 'react-router';

export default function () {
  const history = useHistory();
  const location = useLocation();

  const getQueryParams = () => {
    if (!location.search) return {};
    const retObj = {};
    const currentURLSearchParams = new URLSearchParams(location.search);
    for (let pair of currentURLSearchParams.entries()) {
      retObj[pair[0]] = pair[1];
    }
    return retObj;
  };

  const queryParams = useMemo(getQueryParams, [location.search]);

  /**
   * Replace current pathname with query params in obj
   *
   * @param obj
   */
  const replaceQueryParams = (obj) => {
    history.replace(`${location.pathname}?${new URLSearchParams(obj).toString()}`);
  };

  /**
   * Push current pathname with query params in obj
   *
   * @param obj
   */
  const pushQueryParams = (obj) => {
    history.push(`${location.pathname}?${new URLSearchParams(obj).toString()}`);
  };

  return {
    queryParams,
    pushQueryParams,
    replaceQueryParams,
    history,
    location,
  };
}
