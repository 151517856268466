import {Box, Typography, makeStyles} from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '50px',
    padding: '10px 0px',
    borderBottom: `2px solid ${theme.palette.neutral[300]}`,
  },
  discount: {
    '& p': {
      color: theme.palette.accent.customerCoral[500],
      fontWeight: 800,
    },
  },
  rowWrapper:{
    position: 'relative',
    flexGrow: 1,
  },
  subscription2:{
    position: 'absolute',
    left: 0,
    bottom: -13,
    fontSize: 12
  },
}));

function CheckoutReceiptLine({receiptLines, skipTotal = false}) {
  const classes = useStyles();
  return (
    <Box>
      {receiptLines.map(
        (receiptLine, i) =>
          !receiptLine.is_total &&
          !skipTotal && (
            <Box key={i} className={classNames(classes.container, receiptLine.is_discount ? classes.discount : '')}>
              <div className={classes.rowWrapper}>
                <Typography>{receiptLine.description}</Typography>
                <Typography className={classes.subscription2}>{receiptLine.description_line2}</Typography>
              </div>
              <Typography>{receiptLine.value}</Typography>
            </Box>
          )
      )}
    </Box>
  );
}

export default CheckoutReceiptLine;
