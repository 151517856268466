import React from 'react';
import {BarChart, Bar, Cell, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip} from 'recharts';
import {makeStyles} from '@material-ui/core';
import palette from '../../../../theme/palette';
import CustomTooltip from './CustomTooltip';

const useStyles = makeStyles((theme) => ({
  chartWrapper: {
    width: '100%',
    height: 400,
  },
  chartTitle: {
    fontSize: 20,
    lineHeight: '25px',
    alignItems: 'center',
    color: theme.palette.neutral[900],
    margin: '0 0 20px 10px',
  },
}));

export default function BarChartComponent({data, nameKey, dataKey}) {
  const classes = useStyles();
  const lastIndex = data.length - 1;

  return (
    <div className={classes.chartWrapper}>
      <div className={classes.chartTitle}>By Age</div>
      <ResponsiveContainer width="99%">
        <BarChart data={data} margin={{top: 10, right: 0, left: 0, bottom: 0}}>
          <CartesianGrid stroke={palette.primary[100]} />
          <XAxis dataKey={nameKey} />
          <YAxis tickFormatter={(number) => number.toLocaleString()} />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey={dataKey}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={lastIndex === index ? palette.neutral.paper : palette.primary[500]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
