import React, {useState} from 'react';
import CommonHeader from 'components/elements/common/CommonHeader';
import BackArrow from 'components/elements/common/BackArrow';
import {Box, LinearProgress, Button} from '@material-ui/core';
import useData from 'lib/hooks/useData';
import {useParams} from 'react-router-dom';
import FileSummary from 'components/elements/FilesPage/FileSummary';
import {makeStyles} from '@material-ui/styles';
import FilesMoreMenu from 'components/elements/FilesPage/FilesMoreMenu';
import FilesFavoriteButton from 'components/elements/FilesPage/FilesFavoriteButton';
import FileReuseDialog from 'components/elements/FilesPage/FileReuseDialog';
import OrderDuplicateAdDialog from 'components/elements/OrderPage/OrderDuplicateAdDialog';
import DataController from 'lib/controllers/DataController';
import OrderSubmitSuccess from 'components/elements/OrderPage/OrderSubmitSuccess';
import {useSnackbar} from 'notistack';

const useStyles = makeStyles((theme) => ({
  box: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  wrap: {
    [theme.breakpoints.up('tb')]: {
      display: 'flex',
      alignItems: 'stretch',
      flex: 1,
    },
  },
  image: {
    display: 'block',
    maxWidth: '100%',
    margin: 'auto',
  },
  left: {
    marginBottom: 32,

    [theme.breakpoints.up('tb')]: {
      marginBottom: 0,
      marginRight: 32,
    },
  },
  right: {
    background: theme.palette.accent.marketMint[100],
    padding: 12,
    border: `1px solid ${theme.palette.neutral[400]}`,

    [theme.breakpoints.up('tb')]: {
      minWidth: 300,
      width: 300,
      marginTop: -29, // neutralize divider margin + 1px to match the borders
      marginRight: -24, // neutralize paper padding
      marginBottom: -24,
      borderBottomRightRadius: 10,
    },

    [theme.breakpoints.up('md')]: {
      marginRight: -32,
      marginBottom: -32,
    },
  },

  video: {
    maxWidth: '100%',
  },
}));

export default function FilePage() {
  const {id} = useParams();
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();

  const [step, setStep] = useState('INIT'); // INIT | FILESET_SELECT | ORDER_SELECT | SUCCESS
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedTargetOrders, setSelectedTargetOrders] = useState([]);
  const [duplicateDialogLoading, setDuplicateDialogLoading] = useState(false);
  const [dupeAdDialogData, setDupeAdDialogData] = useState([]);
  const [isSingleFile, setIsSingleFile] = useState(false); // false || file
  const [submitting, setSubmitting] = useState(false);
  const [successDialogMsg, setSuccessDialogMsg] = useState('');

  const state = useData({
    queries: [['getFile', [id]]],
    cbError: () => {
      // TODO: 404
    },
  });

  const handleReuseClick = async () => {
    if (state.files.current.is_fileset) {
      setStep('FILESET_SELECT');
    } else {
      try {
        const candidateOrders = await DataController.getFileCandidateOrders(id);

        if (candidateOrders.success) {
          if (candidateOrders.data && candidateOrders.data.length > 0) {
            setDupeAdDialogData(candidateOrders.data);
            setIsSingleFile(state.files.current);
          }
        } else {
          enqueueSnackbar(candidateOrders.message, {
            variant: 'error',
          });
          setStep('INIT');
        }

        setStep('ORDER_SELECT');
      } catch (e) {
        enqueueSnackbar(e, {
          variant: 'error',
        });
        setStep('INIT');
      }
    }
  };

  const onOrderReuseClick = async (orderId) => {
    setSelectedOrderId(orderId);

    try {
      const filesPickup = await DataController.getFilesPickup(orderId);

      if (filesPickup.success) {
        if (filesPickup.data && filesPickup.data.length > 0) {
          setDupeAdDialogData(filesPickup.data);
        }
      }

      setStep('ORDER_SELECT');
    } catch (e) {
      enqueueSnackbar(e, {
        variant: 'error',
      });
      setStep('INIT');
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);

    if (isSingleFile) {
      DataController.postReuseFileOnOrders(state.files.current.id, selectedTargetOrders)
        .then((res) => {
          if (res.success) {
            setSuccessDialogMsg(res.message);
            setStep('SUCCESS');
          } else {
            // TODO: handle errors
            enqueueSnackbar(res.message, {
              variant: 'error',
            });
            setStep('INIT');
          }
        })
        .catch((err) => {
          enqueueSnackbar(err, {
            variant: 'error',
          });
          setStep('INIT');
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      DataController.postFilesPickup(selectedOrderId, selectedTargetOrders)
        .then((res) => {
          if (res.success) {
            setSuccessDialogMsg(res.message);
            setStep('SUCCESS');
          } else {
            enqueueSnackbar(res.message, {
              variant: 'error',
            });
            setStep('INIT');
          }
        })
        .catch((err) => {
          enqueueSnackbar(err, {
            variant: 'error',
          });
          setStep('INIT');
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <Box className={classes.box}>
      {state.files.current ? (
        <>
          <CommonHeader
            heading={state.files.current.filename}
            fontSize="small"
            right={
              <Box display="flex" alignItems="center">
                <FilesFavoriteButton file={state.files.current} isCurrent={true} />
                <FilesMoreMenu file={state.files.current} />
              </Box>
            }
          />

          <BackArrow />

          <Box className={classes.wrap}>
            <Box flexGrow={1} className={classes.left}>
              {state.files.current.filespec.media_type === 'tv' && (
                <video controls className={classes.video} src={state.files.current.url} />
              )}
              {state.files.current.filespec.media_type != 'tv' && (
                <>
                  {state.files.current.mimetype.includes('image') ? (
                    <img src={state.files.current.url} className={classes.image} height="auto" />
                  ) : (
                    <img src={state.files.current.thumbnail} className={classes.image} height="auto" />
                  )}
                </>
              )}
              {/* : 
                                } */}
            </Box>
            <Box className={classes.right}>
              <Box mb={2}>
                {state.files.current.has_open_orders && (
                  <Button fullWidth variant="contained" color="primary" onClick={handleReuseClick}>
                    Re-use this ad
                  </Button>
                )}
              </Box>
              <FileSummary selectedFile={state.files.current} onFilePage />
            </Box>
          </Box>
          <FileReuseDialog
            file={state.files.current}
            isOpen={step === 'FILESET_SELECT'}
            onClose={() => setStep('INIT')}
            setOrderId={setSelectedOrderId}
            onOrderReuseClick={onOrderReuseClick}
          />
          <OrderDuplicateAdDialog
            isOpen={step === 'ORDER_SELECT'}
            orderId={selectedOrderId}
            selected={selectedTargetOrders}
            setSelected={setSelectedTargetOrders}
            isLoading={duplicateDialogLoading}
            setIsLoading={setDuplicateDialogLoading}
            data={dupeAdDialogData}
            isSingleFile={isSingleFile}
            handleSubmit={handleSubmit}
            submitting={submitting}
            // todo: onClose - clear data and selectedOrder
            // maybe: if is_fileset - return to fileset_select step?
            onClose={() => setStep('INIT')}
          />

          <OrderSubmitSuccess onClose={() => setStep('INIT')} message={successDialogMsg} isOpen={step === 'SUCCESS'} />
        </>
      ) : (
        <Box display="flex" alignItems="center">
          <LinearProgress />
        </Box>
      )}
    </Box>
  );
}
