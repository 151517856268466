import React, {useEffect, useRef, useState} from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Pagination from '@material-ui/lab/Pagination';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import BillingTable from 'components/elements/BillingPage/BillingTable.js';
import useData from 'lib/hooks/useData.js';
import BillingFilters from 'components/elements/BillingPage/BillingFilters.js';
import useAppState from 'lib/hooks/useAppState.js';
import BillingHeader from 'components/elements/BillingPage/BillingHeader.js';
import useQueryParams from 'lib/hooks/useQueryParams';
import {scrollTo} from 'lib/functions.js';
import SkeletonTable from '../../elements/common/SkeletonTable.js';

const useStyles = makeStyles((theme) => ({
  table: {
    margin: '16px -16px',

    [theme.breakpoints.up('sm')]: {
      margin: '24px -24px',
    },

    [theme.breakpoints.up('md')]: {
      margin: '32px -32px',
    },
  },
  filters: {
    margin: '36px 0',
  },
  pagination: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '12px 0',

    [theme.breakpoints.up('sm')]: {
      padding: '24px 0',
    },
  },
}));

function BillingPage() {
  const classes = useStyles();
  const firstRender = useRef(true);
  const {
    state: {billing},
  } = useAppState();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const {replaceQueryParams, queryParams} = useQueryParams();
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [filters, setFilters] = useState({
    company: queryParams.company || '',
    status: queryParams.status || '',
    sort: queryParams.sort || '',
    page: queryParams.page || 1,
  });

  const state = useData({
    queries: [['getBilling', [filters.company, filters.status, filters.sort, filters.page]]],
    setLoadingState: true,
    prevent: filters.prevent,
    cbAfter: () => {
      setSkeletonLoading(false);
      replaceQueryParams(filters);
    },
    cbBefore: () => {
      setSkeletonLoading(true);
    },
  });

  useEffect(() => {
    scrollTo(0, 250);
  }, [filters.page]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    const optionList = state.billing.meta.options;
    if (!optionList) {
      return;
    }

    let apiFilters = {};

    optionList.forEach(function ({id, selected_index, options}) {
      const option = options[selected_index];
      if (filters[id] !== option?.value) {
        apiFilters[id] = option?.value;
      }
    });

    if (!Object.keys(apiFilters).length) {
      return;
    }

    apiFilters = {...filters, ...apiFilters};

    setFiltersSilent(apiFilters);
    replaceQueryParams(apiFilters);
  }, [state.billing.meta]);

  function setFiltersSilent(data) {
    setFilters({...data, prevent: true});
  }

  const handlePageChange = (e, value) => {
    setFilters(({company, status, sort}) => ({
      company,
      status,
      sort,
      page: value,
      prevent: false,
    }));
  };

  return (
    <Box>
      {skeletonLoading ? (
        <SkeletonTable />
      ) : (
        <>
          <BillingHeader termsText={state.billing?.meta?.terms_text} />
          <Box className={classes.filters}>
            <BillingFilters filters={filters} setFilters={setFilters} />
          </Box>
          <BillingTable />
          {billing?.meta?.count && billing?.meta?.count > 0 ? (
            <Box className={classes.pagination}>
              <Pagination
                boundaryCount={isSmallScreen ? 1 : undefined}
                count={billing?.meta?.total_pages || 1}
                onChange={handlePageChange}
                page={billing?.meta?.page || 1}
                siblingCount={isSmallScreen ? 0 : undefined}
              />
            </Box>
          ) : (
            ''
          )}
        </>
      )}
    </Box>
  );
}

export default BillingPage;
