import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import useAppState from '../../../lib/hooks/useAppState.js';
import useLinkHandler from '../../../lib/hooks/useLinkHandler.js';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: 24,
  },
}));

function OrdersListAlerts() {
  const classes = useStyles();
  const {state} = useAppState();
  const {getLinkButtonProps} = useLinkHandler();

  if (!state.ordersStats?.data?.messages || state.ordersStats.data.messages.length === 0) return '';

  return (
    <>
      {state.ordersStats.data.messages.map((message, messageIndex) => (
        <Alert
          key={messageIndex}
          icon={false}
          className={classes.alert}
          action={
            message.cta_label ? (
              <Button variant="contained" size="small" {...getLinkButtonProps({url: message.cta_url})}>
                {message.cta_label}
              </Button>
            ) : (
              ''
            )
          }
        >
          <span dangerouslySetInnerHTML={{__html: message.title}} />
        </Alert>
      ))}
    </>
  );
}

export default OrdersListAlerts;
