import React, {useRef} from 'react';
import {Box, InputAdornment, makeStyles, Popover, TextField, Typography} from '@material-ui/core';
import {format, isSameDay, isWithinInterval} from 'date-fns';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import classNames from 'classnames';
import {PickersDay, StaticDatePicker} from '@material-ui/pickers';
import {ReactComponent as CalendarIcon} from 'images/calendar.svg';
import {ReactComponent as ChevronDown} from 'images/chevron-down-line.svg';
import {ReactComponent as ChevronUp} from 'images/chevron-up.svg';
import Button from '@material-ui/core/Button';
import MagazineDateSelector from './MagazineDateSelector';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  highlight: {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  firstHighlight: {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  endHighlight: {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
  dateField: {
    width: 175,
    margin: '0 5px',
    '& input[type="text"]': {
      fontSize: 15,
    },
    '& input[type="button"]': {
      fontSize: 15,
    },
    '& label': {
      color: theme.palette.neutral[900],
    },
    [theme.breakpoints.down('md')]: {
      width: 160,
      '& input[type="text"]': {
        fontSize: 13,
      },
      '& input[type="button"]': {
        fontSize: 13,
        paddingTop: 5,
        paddingBottom: 10,
      },
    },
  },
  keyDateWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: '#957EFE',
    border: '1px solid #7F6BD8',
    borderRadius: '0px 10px 10px 0px',
    padding: '24px',
    width: '337px',
  },
  datePickerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  keyDateHeader: {
    fontWeight: '800',
    fontSize: '15px',
    lineHeight: '20px',
    alignItems: 'center',
    textTransform: 'uppercase',
    height: '40px',
    color: '#D0C4FF',
    padding: '6px',
  },
  dateDescription: {
    fontSize: '15px',
    lineHeight: '20px',
    borderTop: '2px solid #AA98FE',
    color: '#FDFDFD',
    overflow: 'hidden',
    padding: '6px',
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
  },
  icon: {
    height: 20,
    width: 20,
  },
  applyButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 50,
  },
  applyButton: {
    width: '90%',
  },
}));

function CheckoutDateSelect({
  handleClick,
  iconInfo,
  anchorEl,
  handleClose,
  disableUpdate,
  selectedDate,
  item,
  dateLimit,
  loading,
  handleDateChange,
  keyDates,
  itemDates,
  handleApplyClick,
}) {
  const classes = useStyles();
  const firstChangeTriggered = useRef(false);
  const open = React.useMemo(() => Boolean(anchorEl) && !disableUpdate, [anchorEl]);
  const id = open ? 'simple-popover' : undefined;

  const formatDate = (date, formatter = iconInfo.format) => {
    return format(new Date(date), formatter);
  };

  const renderWeekPickerDay = (date, _selectedDates, DayComponentProps) => {
    const dateClone = date;
    const selectedDateClone = new Date(selectedDate);

    const dateStart = keyDates?.date_start?.replaceAll('-', '/');
    const dateEnd = keyDates?.date_end?.replaceAll('-', '/');

    const start = new Date(dateStart ?? selectedDateClone);
    const end = new Date(dateEnd ?? selectedDateClone);

    const dayIsBetween = isWithinInterval(dateClone, {start, end});

    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    return (
      <PickersDay
        {...DayComponentProps}
        className={classNames(
          dayIsBetween ? classes.highlight : '',
          isFirstDay ? classes.firstHighlight : '',
          isLastDay ? classes.endHighlight : ''
        )}
      />
    );
  };

  const shouldDisableDate = (date) => {
    return !itemDates.some((itemDate) => {
      const newDate = itemDate?.date_start?.replaceAll('-', '/');
      return isSameDay(date, new Date(newDate));
    });
  };

  const handleAccept = (date) => {};

  function renderInput(props) {
    return <TextField {...props} />;
  }

  function renderNewComponent() {
    console.log(itemDates);
    return itemDates.map((item, index) => {
      return (
        <div key={index} className={classes.issueItem}>
          {item.issue_formatted}
        </div>
      );
    });
  }

  function handleOnChange(date) {
    if (!firstChangeTriggered.current) {
      firstChangeTriggered.current = true;
      return;
    }
    handleDateChange(date);
  }

  const fixedDate = item?.date_start ? item.date_start.replaceAll('-', '/') : `${item.issue}/01 00:00:00`;
  const itemStartDate = new Date(fixedDate);
  const isSmallScreen = useMediaQuery('(max-width:1024px)');
  const type = isSmallScreen ? 'button' : 'text';

  return (
    <Box>
      <TextField
        type={type}
        value={formatDate(itemStartDate)}
        aria-describedby={id}
        onClick={handleClick}
        label={iconInfo.inputLabel}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalendarIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {open ? <ChevronUp className={classes.icon} /> : <ChevronDown className={classes.icon} />}
            </InputAdornment>
          ),
          variant: 'outlined',
        }}
        className={classes.dateField}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box className={classes.datePickerWrapper}>
          <Box>
            {item.media_type === 'printmag' ? (
              <MagazineDateSelector handleDateChange={handleDateChange} itemDates={itemDates} value={selectedDate} />
            ) : (
              <StaticDatePicker
                dateAdapter={new DateFnsUtils()}
                id={`date-${item.id}`}
                label={iconInfo.inputLabel}
                views={iconInfo.view}
                value={selectedDate}
                inputFormat={iconInfo.format}
                minDate={dateLimit.minDate}
                maxDate={dateLimit.maxDate}
                loading={loading}
                renderInput={renderInput}
                onChange={handleOnChange}
                renderDay={renderWeekPickerDay}
                shouldDisableDate={shouldDisableDate}
                onAccept={handleAccept}
                showToolbar={false}
                disablePast
                disableCloseOnSelect
              />
            )}
          </Box>
          <Box className={classes.keyDateWrapper}>
            <Box>
              <Typography variant="h6" className={classes.keyDateHeader}>
                {'Key Dates'}
              </Typography>
              {keyDates?.keydates?.length &&
                keyDates?.keydates?.map((keyDate, i) => (
                  <Box key={`keydate-${i}`} className={classes.dateDescription}>
                    <Box>{keyDate?.description}</Box> <Box>{keyDate?.value}</Box>
                  </Box>
                ))}
            </Box>
            <Box className={classes.applyButtonWrapper}>
              <Button className={classes.applyButton} variant="contained" size="small" onClick={handleApplyClick}>
                Apply
              </Button>
            </Box>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}

export default CheckoutDateSelect;
