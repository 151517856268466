import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import DataTable from '../common/DataTable.js';
import {ReactComponent as CloseDialogIcon} from '../../../images/closeDialog.svg';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import {DialogActions, DialogTitle, DialogContent} from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    paddingTop: '0!important',
  },
  invoiceNumberWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    '& div': {
      marginBottom: 5,
    },
  },
  invoiceInfoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 50,
  },
  amountInfoRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px 0 50px 0',
  },
  amountInfoItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 350,
    padding: 15,
    borderBottom: `1px solid ${theme.palette.neutral[300]}`,

    '& :first-child': {
      fontSize: 15,
      lineHeight: '20px',
      color: theme.palette.neutral[600],
    },
    '& :last-child': {
      fontSize: 15,
      lineHeight: '20px',
      color: theme.palette.neutral[800],
    },
  },
  amountDuoItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 350,
    padding: 15,
    borderBottom: `1px solid ${theme.palette.neutral[300]}`,

    '& div': {
      fontWeight: 800,
      fontSize: 20,
      lineHeight: '25px',
      color: theme.palette.neutral[900],
    },
  },
  invoiceN: {
    fontSize: 20,
    lineHeight: '25px',
    color: theme.palette.neutral[900],
  },
  invoiceD: {
    fontSize: 15,
    lineHeight: '25px',
    color: theme.palette.neutral[900],
    textAlign: 'right',
  },
  logo: {
    width: 80,
    height: 44,
    marginRight: 5,
  },
  button: {
    width: '100%',
  },
  chipOutlined: {
    background: theme.palette.common.white,
    borderColor: theme.palette.accent.subscriberSunshine[500],

    '& span': {
      color: theme.palette.accent.subscriberSunshine[500],
    },
  },
  chipPrimary: {
    borderColor: theme.palette.accent.buyerBlue[600],

    '& span': {
      color: theme.palette.accent.buyerBlue[600],
    },
  },
  chipSecondary: {
    borderColor: theme.palette.accent.customerCoral[500],

    '& span': {
      color: theme.palette.accent.customerCoral[500],
    },
  },
  chipDisabled: {
    borderColor: theme.palette.neutral[600],

    '& span': {
      color: theme.palette.neutral[600],
    },
  },
  chiplabel: {
    borderColor: theme.palette.text.primary,

    '& span': {
      color: theme.palette.text.primary,
    },
  },
  infoWrapper: {
    display: 'flex',
  },
  el: {
    width: 150,
    textAlign: 'left',
    marginRight: 20,
  },
  title: {
    fontWeight: 800,
    fontSize: 15,
    lineHeight: '20px',
    color: theme.palette.neutral[800],
  },
  transferType: {
    fontSize: 25,
  },
  note: {
    marginTop: 15,
  },
  footer: {
    backgroundColor: theme.palette.neutral[200],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: 10,
    marginLeft: '0!important',
  },
  total: {
    backgroundColor: theme.palette.neutral[200],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 15px',
    fontWeight: 800,
    fontSize: 15,
    lineHeight: '22px',
    color: theme.palette.neutral[900],
    borderBottom: `1px solid ${theme.palette.neutral[300]}`,
  },
  adDescription: {
    color: theme.palette.neutral[900],
    fontSize: 20,
    lineHeight: '25px',
    marginBottom: 4,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  adQty: {
    color: theme.palette.neutral[800],
    fontSize: 13,
    overflow: 'hidden',
    lineHeight: '18px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  adRun: {
    color: theme.palette.neutral[800],
    fontSize: 13,
    overflow: 'hidden',
    lineHeight: '18px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  dialogTitle: {
    fontSize: 34,
    lineHeight: '34px',
    letterSpacing: '-0.45px',
    color: theme.palette.neutral[900],

    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
  },
  xCloseButton: {
    marginLeft: 16,
    marginRight: 0,
    marginTop: 2,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
  },
  invoiceTableWrapper: {
    position: 'relative',
  },
  selectAll: {
    position: 'absolute',
    top: 3,
    borderColor: 'white',
    zIndex: 5,
    left: 10,

    '& svg': {
      width: 16,
      height: 16,
    },
  },
  checkboxCell: {
    width: 60,

    '& svg': {
      width: 16,
      height: 16,
    },
  },
  textCell: {
    minWidth: 450,
  },
  orderCell: {
    maxWidth: 130,
  },
  orderDateCell: {
    maxWidth: 100,
  },
  headerCheckBox: {
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  amountDueCell: {
    justifyContent: 'flex-end',
  },
  gtButton: {
    borderRadius: 0,
    background: '#B7FF05',
    color: '#262626',
  },
}));

const headings = [
  {id: 'checkbox', key: 'checkbox'},
  {label: 'Order', id: 'order', key: 'order'},
  {label: 'Order date', id: 'orderDate', key: 'orderDate'},
  {label: 'Item', id: 'item', key: 'item'},
  {label: 'Due', id: 'due', key: 'due'},
  {label: 'Amount due', id: 'amountDue', key: 'amountDue'},
];

const statusMap = {
  open: 'default',
  overdue: 'default',
  paid: 'primary',
};

export default function SelectMultipleOrders({invoices = [], onClose, onPayClick, isGT}) {
  const updatedInvoices = invoices.map((el) => ({...el, selected: false}));
  const [invoiceList, setSelectedInvoiceList] = useState(updatedInvoices || []);
  const classes = useStyles();

  const handleSelectClick = (e, id) => {
    const invoices = invoiceList.map((el) => {
      return el.id === id ? {...el, selected: e.target.checked} : {...el};
    });
    setSelectedInvoiceList(invoices);
  };

  const handleSelectAll = (e) => {
    const invoices = invoiceList.map((el) => {
      return {...el, selected: e.target.checked};
    });
    setSelectedInvoiceList(invoices);
  };

  const renderCell = ({key}, row) => {
    switch (key) {
      case 'checkbox':
        return <Checkbox size="small" checked={row.selected} onChange={(e) => handleSelectClick(e, row.id)} />;
      case 'order':
        return (
          <Box className={classes.invoiceNumberWrapper}>
            <div>{`#${row.id}`}</div>
            <Chip
              classes={{
                disabled: classes.chipDisabled,
                label: classes.chiplabel,
                outlined: classes.chipOutlined,
                outlinedPrimary: classes.chipPrimary,
                outlinedSecondary: classes.chipSecondary,
              }}
              color={statusMap[row.status]}
              disabled={row.status === 'canceled'}
              label={row.status}
              variant="outlined"
            />
          </Box>
        );
      case 'orderDate':
        return <Typography>{row.order_date}</Typography>;
      case 'item':
        return (
          <Box maxWidth={340}>
            <Typography className={classes.adDescription}>{row.summary.summary_title}</Typography>
            <Typography className={classes.adRun}>Client: {row.company}</Typography>
            <Typography className={classes.adRun}>{row.summary.summary_line_1}</Typography>
            <Typography className={classes.adQty}>{row.summary.summary_line_2}</Typography>
            <Typography className={classes.adQty}>{row.addons.join(', ')}</Typography>
          </Box>
        );
      case 'due':
        return <Typography>{row.due_date}</Typography>;
      case 'amountDue':
        return <Typography>{row.totals.amount_due}</Typography>;
      default:
        return row[key] || '-';
    }
  };

  const cellPropsGenerator = (heading, row) => {
    switch (heading.id) {
      case 'checkbox':
        return {className: classes.checkboxCell};
      case 'order':
        return {className: classes.orderCell};
      case 'orderDate':
        return {className: classes.orderDateCell};
      case 'item':
        return {className: classes.textCell};
      case 'amountDue':
        return {className: classes.amountDueCell};
    }
  };

  const selectedInvoices = invoiceList.filter((el) => el.selected);
  const total = selectedInvoices.length
    ? selectedInvoices.map((el) => el.totals.amount_due_float).reduce((accumulator, a) => accumulator + a)
    : 0;
  const formattedTotal = total.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});

  return (
    <>
      <DialogTitle disableTypography>
        <Box className={classes.headerContainer}>
          <div className={classes.dialogTitle}>Pay Multiple Orders</div>
          <IconButton
            className={classes.xCloseButton}
            onClick={onClose}
            size="small"
            aria-label="close"
            color="secondary"
          >
            <CloseDialogIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.invoiceTableWrapper}>
          <Checkbox className={classes.selectAll} size="small" onChange={handleSelectAll} />
          <DataTable
            cellPropsGenerator={cellPropsGenerator}
            cellRenderer={renderCell}
            headings={headings}
            rows={invoiceList}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box className={classes.total}>
          <div>Subtotal</div>
          <div>${formattedTotal}</div>
        </Box>
        <Box className={classes.footer}>
          <Button
            disabled={!selectedInvoices.length}
            variant="contained"
            className={classNames(classes.button, isGT && classes.gtButton)}
            size="small"
            onClick={() => onPayClick(selectedInvoices)}
          >
            {`Pay ${selectedInvoices.length ? selectedInvoices.length : ''} Selected Orders`}
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}
