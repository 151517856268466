import * as React from 'react';
import PropTypes from 'prop-types';
import {Box, makeStyles, Typography, LinearProgress} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 30,
  },

  progressContainer: {
    width: '100%',
    height: 8,
    marginRight: 1,
  },

  labelContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
}));

function LinearProgressWithLabel(props) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.progressContainer}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box className={classes.labelContainer}>
        <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel({progress}) {
  return (
    <Box sx={{width: '100%'}}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}
