import React, {useRef} from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import {makeStyles} from '@material-ui/core';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

    '&:hover': {
      '& $select': {
        borderColor: theme.palette.accent.marketMint[700],
      },
    },

    '& fieldset': {
      borderColor: theme.palette.accent.marketMint[400],
    },
  },
  select: {
    borderRadius: 10,
    padding: 0,
  },
  selectSelect: {
    '&[aria-expanded="true"]': {
      '& ~ .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.neutral[400],
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      },

      '& .MuiSelect-icon': {
        transform: 'rotate(180deg)',
      },
    },
  },
  small: {
    marginTop: 0,

    '& .MuiSelect-outlined': {
      padding: '9px 12px 9px 12px',
      maxHeight: 22,
    },
  },
  selectInput: {
    color: theme.palette.neutral[900],

    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  menuItem: (separator) => ({
    minWidth: '100%',

    '&:not(:last-child)': {
      borderBottom: separator ? `1px solid ${theme.palette.accent.marketMint[100]}` : '',
    },
  }),
  menuItemUnstyled: {
    padding: '0 !important',
  },
  popover: {
    maxHeight: 270,
    paddingTop: 0,
    paddingBottom: 0,
  },
  popoverPaper: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
}));

const Dropdown = ({
  inputId,
  image,
  value,
  onChange,
  inputProps,
  label,
  name,
  options,
  className,
  small,
  separator,
  open,
  onOpen,
  onClose,
  menuClassName,
  renderValue,
  ...rest
}) => {
  const classes = useStyles(separator);
  const rootEl = useRef(null);

  const onPopoverEnter = (el) => {
    el.style.width = `${rootEl.current.clientWidth}px`;
  };

  return (
    <FormControl className={classNames(classes.root, className)} variant="outlined" {...rest} ref={rootEl}>
      {label !== false && (
        <InputLabel htmlFor={inputId} shrink={false}>
          {label}
        </InputLabel>
      )}
      <Select
        MenuProps={{
          className: menuClassName,
          onEnter: onPopoverEnter,
          MenuListProps: {
            className: classes.popover,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          PaperProps: {
            className: classes.popoverPaper,
          },
        }}
        className={classNames(classes.select, small ? classes.small : '')}
        classes={{select: classes.selectSelect}}
        value={value}
        displayEmpty
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        onChange={onChange}
        label={label}
        renderValue={renderValue}
        inputProps={{
          name,
          id: inputId,
          className: classes.selectInput,
          ...inputProps,
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            className={classNames(classes.menuItem, option.unstyled ? classes.menuItemUnstyled : '')}
          >
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

Dropdown.defaultProps = {
  separator: false,
};

export default Dropdown;
