import React, {useState} from 'react';
import OrderSidebarRow from './OrderSidebarRow';
import OrderAddonsAddressDialog from './OrderAddonsAddressDialog';

export default function OrderReceiptAddons({addons, order}) {
  const [selectedAddon, setSelectedAddon] = useState(false);

  const handleEditAddress = (row) => {
    setSelectedAddon(row);
  };

  return (
    <>
      {addons.map((row, index) => (
        <OrderSidebarRow
          row={row}
          key={index}
          darkLabels={true}
          handleEditAddress={() => {
            handleEditAddress(row);
          }}
          isEditable={order.is_editable_checking_copy_address}
        />
      ))}

      <OrderAddonsAddressDialog
        handleClose={() => setSelectedAddon(false)}
        open={!!selectedAddon}
        order={order}
        addon={selectedAddon}
      />
    </>
  );
}
