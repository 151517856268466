import {Box, IconButton, makeStyles} from '@material-ui/core';
import {ReactComponent as EditIcon} from 'images/edit.svg';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  row: {
    minHeight: 48,
    padding: '12px 10px',
    borderBottom: `1px solid ${theme.palette.neutral[300]}`,
    color: theme.palette.neutral[700],
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    fontSize: 15,
  },
  inner: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  boldedRow: {
    fontWeight: 'bold',
    color: theme.palette.neutral[900],

    '& $value': {
      color: theme.palette.neutral[900],
    },
  },
  totalRow: {
    fontWeight: 'bold',
    color: theme.palette.neutral[900],
    fontSize: 20,

    '& $value': {
      color: theme.palette.neutral[900],
    },
  },
  value: {
    color: `${theme.palette.neutral[900]} ! important`,
  },
  editIcon: {
    marginLeft: 'auto',
    minWidth: 20,
    justifySelf: 'flex-end',

    '& path': {
      stroke: theme.palette.primary.main,
      transition: 'stroke 0.3s ease-in-out',
    },

    '&:hover': {
      cursor: 'pointer',

      '& path': {
        stroke: theme.palette.primary[500],
      },
    },
  },
  address: {
    color: theme.palette.neutral[700],
  },
  addressAttn: {
    color: theme.palette.neutral[600],
  },
}));

export default function OrderSidebarRow({row, handleEditAddress, isEditable, darkLabels = false, darkValues = true}) {
  const classes = useStyles();

  return (
    <Box
      className={classNames(
        classes.row,
        row.is_subtotal ? classes.boldedRow : '',
        row.is_total ? classes.totalRow : ''
      )}
    >
      <Box className={classes.inner}>
        <span
          className={darkLabels ? classes.value : ''}
          dangerouslySetInnerHTML={{
            __html: row.description,
          }}
        ></span>
        <span className={darkValues ? classes.value : ''}>{row.value}</span>
      </Box>

      {row.addresses &&
        row.addresses.map((address, i) => (
          <Box className={classes.inner} key={i} mt={3}>
            <Box className={classes.address}>
              <div>{address.attention}</div>
              <div>{address.street}</div>
              <div>{address.street2}</div>
              <div>{address.street3}</div>
              <div>
                {address.city}, {address.state} {address.zip}
              </div>
            </Box>

            {isEditable && (
              <IconButton color="primary" aria-label="upload picture" component="button" onClick={handleEditAddress}>
                <EditIcon className={classes.editIcon} />
              </IconButton>
            )}
          </Box>
        ))}
    </Box>
  );
}
