import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import Box from '@material-ui/core/Box';

import {Dropdown} from 'components/elements/common';
import useAppState from 'lib/hooks/useAppState';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  gap: {
    margin: '0 8px',

    [theme.breakpoints.up('sm')]: {
      margin: '0 16px',
    },
  },
}));

function BillingFilters({filters, setFilters}) {
  const classes = useStyles();

  const {state} = useAppState();

  const handleChange = (key) => (e) => {
    setFilters((prevFilter) => ({
      ...prevFilter,
      [key]: e.target.value,
      page: 1,
      prevent: false,
    }));
  };

  if (!state.billing.isLoaded) return '';

  return (
    <Box className={classes.root}>
      {state.billing.meta.options.map(({id, name, options}, i) => (
        <Fragment key={id}>
          {i !== 0 && <Box className={classes.gap} />}

          <Dropdown
            id={`filter-${id}`}
            label={name}
            onChange={handleChange(id)}
            options={options}
            value={filters[id]}
          />
        </Fragment>
      ))}
    </Box>
  );
}

BillingFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default BillingFilters;
