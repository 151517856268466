import React from 'react';
import {ReactComponent as CloseIcon} from 'images/closeCircle.svg';
import {makeStyles} from '@material-ui/core/styles';
import {Box, IconButton} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wipWrapper: {
    background: theme.palette.accent.subscriberSunshine[100],
    borderRadius: 10,
    margin: 24,
  },
  wipContent: {
    padding: 32,
    fontSize: 20,
    lineHeight: '25px',
    fontWeight: 400,

    [theme.breakpoints.down('md')]: {
      fontSize: 15,
      lineHeight: '22px',
    },
  },
  wipTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'Ahkio',
    textTransform: 'uppercase',
    fontSize: 30,
    lineHeight: '100%',
    color: theme.palette.neutral[900],
    letterSpacing: '-1px',
    fontWeight: 400,

    '& .MuiIconButton-root': {
      padding: 0,
    },

    [theme.breakpoints.down('md')]: {
      fontSize: 21,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  closeBtn: {
    width: 24,
    height: 24,
    '& svg': {
      '& path': {
        stroke: theme.palette.neutral[900],
        strokeWidth: 3,
      },
    },
  },
}));

function CheckoutWIP({wipTitle, wipDescription}) {
  const classes = useStyles();

  const [hideWIP, setHideWIP] = React.useState(false);
  const handleClick = () => setHideWIP(true);

  if (hideWIP) {
    return <></>;
  }

  return (
    <Box className={classes.wipWrapper}>
      <Box className={classes.wipContent}>
        <Box className={classes.wipTitle}>
          <div>{wipTitle}</div>
          <IconButton className={classes.closeBtn} onClick={handleClick}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>{wipDescription}</Box>
      </Box>
    </Box>
  );
}

export default CheckoutWIP;
