import {useState, useLayoutEffect} from 'react';

export default function (callback) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleOnResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
    if (callback) callback([window.innerWidth, window.innerHeight]);
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', handleOnResize);
    handleOnResize();
    return () => window.removeEventListener('resize', handleOnResize);
  }, []);

  return {windowWidth, windowHeight};
}
