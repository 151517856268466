import {useState} from 'react';
import {makeStyles, Box, Link, Typography, Divider, IconButton} from '@material-ui/core';
import LoginForm from 'components/elements/LoginPage/LoginForm';
import ForgotPasswordForm from 'components/elements/LoginPage/ForgotPasswordForm';
import SignUpForm from 'components/elements/LoginPage/SignUpForm';
import useAppState from 'lib/hooks/useAppState';
import DataController from 'lib/controllers/DataController';
import {actions} from '../../../store/store';
import {ReactComponent as CloseDialogIcon} from 'images/closeDialog.svg';
import {useSnackbar} from 'notistack';
import {formatErrorObj} from '../../../lib/functions';

const useStyles = makeStyles((theme) => ({
  footer: {
    color: `${theme.palette.neutral[700]}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: 10,
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '25px',
  },
  xCloseButton: {
    position: 'absolute',
    right: 25,
    top: 25,
  },
}));

function AuthenticateDialog({onDone, onClose}) {
  const classes = useStyles();

  const SIGNIN = 'signin';
  const SIGNUP = 'signup';
  const RESETPASSWORD = 'reset';
  const [step, setStep] = useState(SIGNIN);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const {
    dispatch,
    state: {userData},
  } = useAppState();
  const {enqueueSnackbar} = useSnackbar();
  // const [login, setLogin] = useState(true);
  // const [skeletonLoading, setSkeletonLoading] = useState(true);

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setStep(RESETPASSWORD);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setErrors({});
    setIsLoading(true);

    DataController.login(email, password).then(({data, errors, message, success}) => {
      if (success) {
        dispatch({
          type: actions.SET_USER_DATA,
          payload: data,
        });
        onDone();
      } else {
        if (Object.keys(errors).length > 0) {
          const errorObject = formatErrorObj(errors);
          setErrors(errorObject);
        } else if (message)
          enqueueSnackbar(message, {
            variant: 'error',
          });
      }
      setIsLoading(false);
    });
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    setErrors({});
    setIsLoading(true);

    DataController.register(email, password, firstName, lastName, companyName).then(
      ({data, errors, message, success}) => {
        if (success) {
          dispatch({
            type: actions.SET_USER_DATA,
            payload: data,
          });
          onDone();
        } else {
          if (Object.keys(errors).length > 0) {
            const errorObject = formatErrorObj(errors);
            setErrors(errorObject);
          } else if (message)
            enqueueSnackbar(message, {
              variant: 'error',
            });
        }
        setIsLoading(false);
      }
    );
  };

  const handleResetSend = (e) => {
    e.preventDefault();

    DataController.forgotPassword(email).then(({errors, message, success}) => {
      if (!success) {
        if (Object.keys(errors).length > 0) {
          const errorObject = formatErrorObj(errors);
          setErrors(errorObject);
        } else {
          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      } else {
        enqueueSnackbar(message);
      }
    });
  };

  return (
    <Box>
      <IconButton className={classes.xCloseButton} onClick={onClose} size="small" aria-label="close" color="secondary">
        <CloseDialogIcon />
      </IconButton>
      <Box hidden={step !== SIGNIN}>
        <LoginForm
          email={email}
          errors={errors}
          handleLogin={handleLogin}
          handleForgotPassword={handleForgotPassword}
          isLoading={isLoading}
          password={password}
          setEmail={setEmail}
          setPassword={setPassword}
        />
        <Divider />

        <Typography className={classes.footer}>
          <Box>
            Don't have an account? <Link onClick={() => setStep(SIGNUP)}>Sign up</Link>
          </Box>
        </Typography>
      </Box>

      <Box hidden={step !== RESETPASSWORD}>
        <ForgotPasswordForm
          email={email}
          errors={errors}
          handleResetSend={handleResetSend}
          isLoading={isLoading}
          setEmail={setEmail}
          handleBackClick={() => setStep(SIGNIN)}
        />
      </Box>

      <Box hidden={step !== SIGNUP}>
        <SignUpForm
          firstName={firstName}
          lastName={lastName}
          companyName={companyName}
          email={email}
          errors={errors}
          isLoading={isLoading}
          password={password}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setCompanyName={setCompanyName}
          handleSignUp={handleSignUp}
          setEmail={setEmail}
          setPassword={setPassword}
        />
        <Divider />

        <Typography className={classes.footer}>
          <Box>
            Already have an account? <Link onClick={() => setStep(SIGNIN)}>Sign in</Link>
          </Box>
        </Typography>
      </Box>
    </Box>
  );
}

export default AuthenticateDialog;
