import React from 'react';
import {Box, makeStyles, List, ListItem, ListItemIcon, ListItemText, Typography} from '@material-ui/core';
import {ReactComponent as CheckmarkIcon} from 'images/checkmark.svg';

const useStyles = makeStyles((theme) => ({
  listWrapper: {
    border: `1px solid ${theme.palette.neutral[500]}`,
    borderRadius: 4,
    width: 500,
    maxWidth: '100%',
    height: 400,
    overflow: 'hidden',
    color: theme.palette.neutral[800],
    display: 'flex',
    flexDirection: 'column',
  },

  listInner: {
    overflowY: 'scroll',
    flexGrow: 1,
  },

  list: {
    padding: 0,

    '& .MuiListItem-root': {
      borderBottom: `1px solid ${theme.palette.neutral[300]}`,

      '&:last-of-type': {
        marginBottom: 50,
      },

      '&.Mui-selected': {
        background: theme.palette.neutral[200],

        '&:hover': {
          background: theme.palette.neutral[300],
        },
      },
    },

    '& .MuiListItemIcon-root': {
      justifyContent: 'flex-end',
    },
  },
}));

export default function MultiSelectList({
  idRoot,
  items,
  itemsLoaded,
  noItemsText,
  listFooter,
  listInnerRef,
  itemTextFormater,
  selected,
  setSelected,
  allowMultiselect,
}) {
  const classes = useStyles();

  const toggleItem = (clientId) => {
    const currentIndex = selected.indexOf(clientId);
    const newChecked = [...selected];

    if (currentIndex === -1) {
      newChecked.push(clientId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelected(newChecked);
  };

  const updateItem = (clientId) => {
    setSelected([clientId]);
  };

  return (
    <Box className={classes.listWrapper}>
      <Box className={classes.listInner} ref={listInnerRef}>
        {!items || items.length === 0 ? (
          itemsLoaded ? (
            <Box p={3}>
              <Typography align="center">{noItemsText}</Typography>
            </Box>
          ) : (
            ''
          )
        ) : (
          <List className={classes.list} component="nav" aria-label="main mailbox folders">
            {items.map((item) => {
              const itemIsSelected = selected.indexOf(item.id) > -1;
              return (
                <ListItem
                  id={`${idRoot ? idRoot : 'list-item-'}${item.id}`}
                  button
                  onClick={
                    allowMultiselect
                      ? () => {
                          toggleItem(item.id);
                        }
                      : () => {
                          updateItem(item.id);
                        }
                  }
                  selected={itemIsSelected}
                  key={item.id}
                >
                  <ListItemText primary={itemTextFormater(item)} />
                  {itemIsSelected && (
                    <ListItemIcon>
                      <CheckmarkIcon />
                    </ListItemIcon>
                  )}
                </ListItem>
              );
            })}
          </List>
        )}
      </Box>
      {listFooter}
    </Box>
  );
}

MultiSelectList.defaultProps = {
  allowAddingNewClient: true,
  allowMultiselect: true,
};
