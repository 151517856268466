import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import {ReactComponent as CloseDialogIcon} from '../../../images/closeDialog.svg';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import LoginForm from '../LoginPage/LoginForm';
import DataController from '../../../lib/controllers/DataController';
import {formatErrorObj, sendGtagEvent} from '../../../lib/functions';
import {useSnackbar} from 'notistack';
import {actions} from '../../../store/store';
import useAppState from '../../../lib/hooks/useAppState';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: 10,
  },
  xCloseButton: {
    position: 'absolute',
    right: 25,
    top: 25,
  },
  formContainer: {
    '& h2': {
      fontSize: 21,
    },

    '& h3': {
      fontSize: 21,
    },

    '& h6': {
      fontSize: 15,
    },

    [theme.breakpoints.up('sm')]: {
      '& h2': {
        fontSize: 34,
      },

      '& h3': {
        fontSize: 34,
      },

      '& h6': {
        fontSize: 20,
      },
    },

    [theme.breakpoints.up('lg')]: {
      '& h2': {
        fontSize: 55,
      },
    },
  },
}));

export default function Login({onClose, onDone}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const classes = useStyles();
  const {
    dispatch,
    state: {userData},
  } = useAppState();
  const {enqueueSnackbar} = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    setErrors({});
    setIsLoading(true);

    DataController.login(email, password).then(({data, errors, message, success}) => {
      if (success) {
        dispatch({
          type: actions.SET_USER_DATA,
          payload: data,
        });
        sendGtagEvent('event', 'click', {
          event_category: 'login',
          event_label: 'login_success',
          value: data?.uuid || '',
        });
        onDone();
      } else {
        if (Object.keys(errors).length > 0) {
          const errorObject = formatErrorObj(errors);
          setErrors(errorObject);
        } else if (message)
          enqueueSnackbar(message, {
            variant: 'error',
          });
      }
      setIsLoading(false);
    });
  };

  return (
    <div style={{maxWidth: '448px', padding: 30}}>
      <Box className={classes.formContainer}>
        <IconButton
          className={classes.xCloseButton}
          onClick={onClose}
          size="small"
          aria-label="close"
          color="secondary"
        >
          <CloseDialogIcon />
        </IconButton>
        <div>
          <LoginForm
            email={email}
            errors={errors}
            handleLogin={handleLogin}
            isLoading={isLoading}
            password={password}
            setEmail={setEmail}
            setPassword={setPassword}
            hideForgotPasswordLink
          />
        </div>
      </Box>
      <Divider />
      <Box className={classes.footer}>
        <Button variant="outlined" className={classes.button} onClick={onClose}>
          Continue as a Guest
        </Button>
      </Box>
    </div>
  );
}
