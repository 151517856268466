import Skeleton from '@material-ui/lab/Skeleton';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import React from 'react';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {},
  progressWrap: {
    paddingTop: 57,
    paddingBottom: 56,
    color: theme.palette.neutral[700],
    fontSize: 20,
    lineHeight: '25px',

    [theme.breakpoints.up('sm')]: {
      paddingTop: 53,
      paddingBottom: 53,
    },

    [theme.breakpoints.up('md')]: {
      paddingTop: 57,
      paddingBottom: 57,
    },
  },
  progress: {
    marginTop: 24,

    '& svg': {
      color: theme.palette.accent.buyerBlue[500],
    },
  },
  skel: {
    marginTop: 24,
  },
  skelHeight: {
    height: 50,

    [theme.breakpoints.up('sm')]: {
      height: 60,
    },
  },
}));

function SkeletonLoginForm() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Skeleton className={classes.skelHeight} animation="wave" variant="rect" width="100%" />
      <Box
        className={classes.progressWrap}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        Validating your login...
        <CircularProgress size={24} className={classes.progress} />
      </Box>
      <Skeleton className={classes.skelHeight} animation="wave" variant="rect" width="100%" />
      <Skeleton className={classNames(classes.skel, classes.skelHeight)} animation="wave" variant="rect" width="100%" />

      <Divider />

      <Skeleton className={classes.skelHeight} animation="wave" variant="rect" width="100%" />
    </div>
  );
}

export default SkeletonLoginForm;
