import React from 'react';
import Transition from 'react-transition-group/Transition';
import {useTheme} from '@material-ui/styles';
import {useForkRef} from '@material-ui/core';

export function setTranslateValue(node) {
  const transform = `translateY(100vh)`;
  node.style.webkitTransform = transform;
  node.style.transform = transform;
}

const TransitionBounce = React.forwardRef(function TransitionBounce(props, ref) {
  const {
    children,
    container: containerProp,
    in: inProp,
    appear = true,
    style,
    onEnter,
    onEntered,
    onEntering,
    onExit,
    onExited,
    onExiting,
    // eslint-disable-next-line react/prop-types
    TransitionComponent = Transition,
    ...other
  } = props;

  const theme = useTheme();
  const childrenRef = React.useRef(null);
  const handleRefIntermediary = useForkRef(children.ref, childrenRef);
  const handleRef = useForkRef(handleRefIntermediary, ref);

  const offset = 1000;

  const normalizedTransitionCallback = (callback) => (isAppearing) => {
    if (callback) {
      // onEnterXxx and onExitXxx callbacks have a different arguments.length value.
      if (isAppearing === undefined) {
        callback(childrenRef.current);
      } else {
        callback(childrenRef.current, isAppearing);
      }
    }
  };

  const handleEnter = normalizedTransitionCallback((node, isAppearing) => {
    setTranslateValue(node);
  });

  const handleEntering = normalizedTransitionCallback((node, isAppearing) => {
    const options = {
      duration: 400,
      easing: `cubic-bezier(.58,1.67,.53,.83)`,
    };

    node.style.webkitTransition = theme.transitions.create('-webkit-transform', {
      ...options,
    });

    node.style.transition = theme.transitions.create('transform', {
      ...options,
    });

    node.style.webkitTransform = 'none';
    node.style.transform = 'none';
    node.style.opacity = 1;

    if (onEntering) {
      onEntering(node, isAppearing);
    }
  });

  const handleEntered = normalizedTransitionCallback(onEntered);

  const handleExit = normalizedTransitionCallback((node) => {
    const options = {
      duration: 300,
      easing: theme.transitions.easing.sharp,
    };

    node.style.webkitTransition = theme.transitions.create('-webkit-transform', options);
    node.style.transition = theme.transitions.create('transform', options);
  });

  const handleExiting = normalizedTransitionCallback((node) => {
    setTranslateValue(node);
  });

  const handleExited = normalizedTransitionCallback((node) => {
    node.style.webkitTransition = '';
    node.style.transition = '';
  });

  return (
    <TransitionComponent
      nodeRef={childrenRef}
      appear={appear}
      in={inProp}
      timeout={900}
      onEnter={handleEnter}
      onEntered={handleEntered}
      onEntering={handleEntering}
      onExit={handleExit}
      onExited={handleExited}
      onExiting={handleExiting}
    >
      {(state, childProps) => {
        return React.cloneElement(children, {
          ref: handleRef,
          style: {
            visibility: state === 'exited' && !inProp ? 'hidden' : undefined,
            ...style,
            ...children.props.style,
          },
          ...childProps,
        });
      }}
    </TransitionComponent>
  );
});

export default TransitionBounce;
