import React, {useState} from 'react';
import {Box, Typography, Button, IconButton, makeStyles} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {ReactComponent as EditIcon} from 'images/edit.svg';
import {ReactComponent as ClockIcon} from 'images/clock.svg';
import OrderEditClientDialog from './OrderEditClientDialog';
import RoutesDialog from './RoutesDialog';
import ZipsDialog from './ZipsDialog';
import CustomDialog from 'components/elements/common/CustomDialog';
import DataController from 'lib/controllers/DataController';
import actions from 'store/actions';
import useAppState from 'lib/hooks/useAppState';
import {useSnackbar} from 'notistack';
import {useHistory} from 'react-router';

const useStyles = makeStyles((theme) => ({
  receiptWrap: {
    padding: '24px 16px',

    [theme.breakpoints.up('xl')]: {
      padding: '24px 32px',
    },
  },
  row: {
    minHeight: 48,
    paddingLeft: 10,
    paddingRight: 10,
    borderBottom: `1px solid ${theme.palette.neutral[300]}`,
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',

    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
  receiptLabel: {
    fontSize: 15,
    color: theme.palette.neutral[700],
    minWidth: '40%',
    width: '40%',
  },
  receiptValue: {
    fontSize: 15,
    marginRight: 16,
    color: theme.palette.neutral[900],
  },
  editIcon: {
    marginLeft: 'auto',
    minWidth: 20,
    justifySelf: 'flex-end',

    '& path': {
      stroke: theme.palette.primary.main,
      transition: 'stroke 0.3s ease-in-out',
    },

    '&:hover': {
      cursor: 'pointer',

      '& path': {
        stroke: theme.palette.primary[500],
      },
    },
  },
  btn: {
    '& .MuiButton-label': {
      textTransform: 'lowercase',
      fontWeight: 400,
      fontSize: 15,
    },
  },
  editBtn: {
    marginLeft: 'auto',

    '& path': {
      stroke: theme.palette.primary.main,
    },
  },
  routesBtn: {
    '& .MuiButton-label': {
      fontWeight: 400,
      fontSize: 15,
    },
  },
  orderId: {
    color: theme.palette.primary.main,
  },
  alert: {
    marginBottom: 20,
    '& .MuiAlert-message': {
      display: 'flex',
      alignItems: 'center',
    },

    '& .MuiAlert-icon': {
      alignItems: 'center',
    },
  },
  date: {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },
  xCloseButton: {
    background: theme.palette.accent.subscriberSunshine[700],

    '&:hover': {
      background: theme.palette.accent.subscriberSunshine[700],
      opacity: 0.7,
      transition: 'opacity 0.1s ease-in-out',
    },

    '& svg path': {
      fill: theme.palette.accent.subscriberSunshine[500],
    },
  },
}));

export default function OrderSidebarHeader({order}) {
  const classes = useStyles();
  const [clientsDialogOpen, setClientsDialogOpen] = useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [routesDialogOpen, setRoutesDialogOpen] = useState(false);
  const [zipsDialogOpen, setZipsDialogOpen] = useState(false);

  const {dispatch} = useAppState();
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();

  const handleEditClient = () => {
    setClientsDialogOpen(true);
  };

  const handleCancelClick = () => {
    setCancelDialogOpen(true);
  };

  const handleRoutesLinkClick = () => {
    setRoutesDialogOpen(true);
  };

  const handleZipsLinkClick = () => {
    setZipsDialogOpen(true);
  };

  const handleCancelOrder = () => {
    DataController.cancelOrder(order.id).then(({data, error, success, message}) => {
      if (success) {
        dispatch({
          type: actions.UPDATE_ROW,
          payload: {
            location: 'orders.data',
            // delete item in location by id
            deleteId: order.id,
          },
        });
        enqueueSnackbar('Order canceled successfully.');
        history.push('/orders/all');
      } else {
        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
    });
  };

  return (
    <Box className={classes.receiptWrap}>
      {order.is_editable && (
        <Alert icon={<ClockIcon />} className={classes.alert} severity={'warning'}>
          <span>
            You can edit this order until <span className={classes.date}>{order.date_close_formatted}</span>
          </span>
        </Alert>
      )}
      {order.receipt_header.map((item, index) => (
        <Box className={classes.row} key={index}>
          <Typography className={classes.receiptLabel}>{item.description}</Typography>
          {item.description !== 'Routes' && item.description !== 'Zip codes' && (
            <Typography className={classes.receiptValue}>{item.value}</Typography>
          )}
          {item.description === 'Routes' && (
            <Typography className={classes.receiptValue}>
              <Button className={classes.routesBtn} onClick={handleRoutesLinkClick}>
                {item.value}
              </Button>
            </Typography>
          )}
          {item.description === 'Zip codes' && (
            <Typography className={classes.receiptValue}>
              <Button className={classes.routesBtn} onClick={handleZipsLinkClick}>
                {item.value}
              </Button>
            </Typography>
          )}
        </Box>
      ))}
      <Box className={classes.row}>
        <Typography className={classes.receiptLabel}>Client</Typography>
        <Typography className={classes.receiptValue}>{order.company.name}</Typography>
        {order.is_editable_client && (
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="button"
            onClick={handleEditClient}
            className={classes.editBtn}
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>
      {!order.is_canceled && order.is_cancelable && (
        <Box className={classes.row}>
          <Button onClick={handleCancelClick} className={classes.btn}>
            cancel order
          </Button>
        </Box>
      )}

      <OrderEditClientDialog handleClose={() => setClientsDialogOpen(false)} open={clientsDialogOpen} order={order} />

      <CustomDialog
        actions={[
          {label: 'Not Now', onClick: () => setCancelDialogOpen(false), color: 'secondary'},
          {label: 'Cancel Order', onClick: () => handleCancelOrder(), color: 'default'},
        ]}
        disableBackdropClick
        hasXClose
        isOpen={cancelDialogOpen}
        onClose={() => {
          setCancelDialogOpen(false);
        }}
        title="Cancel Order"
        maxWidth="xs"
      >
        <Typography>
          You are about to cancel the order <span className={classes.orderId}>#{order.id}</span>. This action can't be
          undone.
        </Typography>
      </CustomDialog>

      {order.receipt_header.some((item) => item.description === 'Routes') && (
        <RoutesDialog handleClose={() => setRoutesDialogOpen(false)} open={routesDialogOpen} order={order} />
      )}
      {order.receipt_header.some((item) => item.description === 'Zip codes') && (
        <ZipsDialog handleClose={() => setZipsDialogOpen(false)} open={zipsDialogOpen} order={order} />
      )}
    </Box>
  );
}
