import React from 'react';
import {Chip} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

export default function UserRoleChip({role, description = ''}) {
  let color = '';
  let lowercased = typeof role === 'string' ? role.toLowerCase() : null;

  if (lowercased && lowercased.includes('owner')) {
    color = '#8AA6A3';
  } else if (lowercased && lowercased.includes('admin')) {
    color = '#FFA71E';
  } else {
    color = '#73B3E5';
  }

  return (
    <>
      {role != null && (
        <Tooltip title={description} placement="left" arrow>
          <Chip label={role} style={{backgroundColor: color}} />
        </Tooltip>
      )}
    </>
  );
}
