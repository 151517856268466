import React, {useState, useEffect, useRef} from 'react';
import CustomDialog from '../common/CustomDialog.js';
import {Box, Button, Checkbox, Divider, makeStyles} from '@material-ui/core';
import DataTable from '../common/DataTable.js';
import OrderThumbnail from '../OrdersPage/OrderThumbnail.js';
import DataController from '../../../lib/controllers/DataController.js';
import TransitionBounce from '../common/TransitionBounce.js';
import LinearProgressWithLabel from '../common/LinearProgressWithLabel';
import {ReactComponent as DownloadIcon} from 'images/download.svg';
import {ReactComponent as ViewIcon} from 'images/iconsEye.svg';
import classNames from 'classnames';
import LoadingButton from '../common/LoadingButton.js';

const headings = [
  {
    id: 'checkbox',
  },
  {
    id: 'logo',
    breakpoint: 'sm',
  },
  {
    id: 'text',
  },
  {
    id: 'file_status',
  },
];

const useStyles = makeStyles((theme) => ({
  dialogClass: {
    padding: 0,

    '& .MuiDialog-paper': {
      padding: 0,
      minHeight: '80vh',

      [theme.breakpoints.down('xs')]: {
        minHeight: '60vh',
      },
    },

    '& .MuiDivider-root': {
      margin: '0',
    },
  },
  dialogTitleClass: {
    padding: '25px !important',

    [theme.breakpoints.down('sm')]: {
      '& h2': {
        fontSize: 16,
      },
    },
  },
  dialogContentClass: {
    padding: 0,
    display: 'flex',
    overflowY: 'hidden',

    '& > .MuiBox-root': {
      margin: 0,
      padding: 0,
      width: '100%',
      display: 'flex',
      position: 'relative',

      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
  },
  innerWrap: {
    margin: 0,
    padding: 0,
    width: '100%',
    display: 'flex',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      maxHeight: '100%',
      overflowY: 'scroll',
    },
  },
  header: {
    margin: 0,
    padding: 0,
    width: '50%',
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRight: `1px solid ${theme.palette.neutral[300]}`,
    backgroundColor: theme.palette.background.start,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '50%',
      backgroundColor: `${theme.palette.background.start} !important`,
    },

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  headerText: {
    fontSize: 20,
    width: '90%',
    lineHeight: '28px',
    color: theme.palette.neutral[900],
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ordersSec: {
    width: '50%',
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
    },
  },
  smallHeaderText: {
    fontSize: 21,
    padding: '10px 0',
    lineHeight: '26px',
    textAlign: 'center',
    color: theme.palette.neutral[900],

    [theme.breakpoints.down('xs')]: {
      fontSize: 17,
      lineHeight: '24px',
      padding: '10px',
    },
  },
  dataTable: {
    height: '100%',
    position: 'relative',
    paddingBottom: 120,

    [theme.breakpoints.up('md')]: {
      overflowY: 'auto',
    },

    '& .MuiTableHead-root': {
      display: 'none',
    },
  },
  actions: {
    padding: '0 20px',
    paddingTop: 10,
    background: theme.palette.neutral[200],
    boxShadow: '10px 1px 14px rgba(0, 0, 0, 0.12), 10px 5px 8px rgba(0, 0, 0, 0.14), 10px 3px 5px rgba(0, 0, 0, 0.2)',

    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '50%',
    },

    '& > .MuiButton-text': {
      padding: '12px 24px',
    },
  },
  textId: {
    fontSize: 11,
    marginBottom: 4,
    lineHeight: '13px',
    color: theme.palette.neutral[800],
  },
  textTitle: {
    fontSize: 20,
    marginBottom: 4,
    lineHeight: '25px',
    fontWeight: 'normal',
    color: theme.palette.neutral[900],
  },
  downloadButton: {
    marginTop: 10,

    '& a': {
      color: 'white',
    },

    '& svg': {},
  },
  textRow: {
    fontSize: 13,
    lineHeight: '18px',
    whiteSpace: 'normal',
    color: theme.palette.neutral[800],
  },
  thumbWrap: {
    position: 'relative',
  },
  previewImages: {
    width: '100%',
    minHeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  previewWrapper: {
    width: '80%',
    minHeight: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
    },
  },
  previewImage: {
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      maxHeight: 260,
      width: 'auto',
    },
  },
  noExtraCarouselImages: {
    margin: 'auto !important',
  },

  carouselWrapper: {},

  carousel: {
    marginRight: 10,
    display: 'flex',
    overflowY: 'auto',
    flexDirection: 'column',
    maxHeight: '100%',
    paddingTop: 10,
    paddingBottom: 10,

    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      maxWidth: '95%',
      marginRight: 0,
      overflowX: 'scroll',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    [theme.breakpoints.down('xs')]: {
      marginLeft: 15,
    },
  },
  carouselImgWrapper: {
    width: 90,
    height: 90,
    minWidth: 90,
    minHeight: 90,
    padding: 10,
    display: 'flex',
    borderRadius: 4,
    marginBottom: 10,
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.accent.marketMint[600]}`,
    backgroundColor: theme.palette.accent.marketMint[200],

    '&:hover': {
      backgroundColor: theme.palette.accent.marketMint[300],
      border: `1px solid ${theme.palette.accent.marketMint[700]}`,
    },

    [theme.breakpoints.down('sm')]: {
      width: 60,
      height: 60,
      marginRight: 10,
    },
  },
  carouselActiveImgWrapper: {
    backgroundColor: theme.palette.primary[100],
    border: `2px solid ${theme.palette.primary.main}`,

    '&:hover': {
      backgroundColor: theme.palette.primary[100],
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  carouselImg: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 40,
      maxHeight: 40,
    },
  },
  checkboxCell: {
    maxWidth: 56,
    width: 56,
    paddingLeft: 16,
    paddingRight: 0,

    '& svg': {
      width: 16,
      height: 16,
    },
  },
  textCell: {
    maxWidth: 230,

    [theme.breakpoints.down('xs')]: {
      maxWidth: 214,
    },
  },
  logoCell: {
    maxWidth: 142,
    width: 142,
  },
  mediaTypeCell: {
    textAlign: 'center',
  },
  fileStatus: {
    maxWidth: 150,
    width: 150,
    whiteSpace: 'normal',

    '& .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',

      '& div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
    },
  },
  fileStatusApprov: {
    color: theme.palette.active.main,
  },
  fileStatusWarn: {
    color: theme.palette.error.main,
  },
  fileStatusIcon: {
    marginLeft: 5,

    '& path': {
      stroke: theme.palette.primary.main,
    },

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  defaultRow: {
    cursor: 'pointer',

    '&:hover': {
      background: theme.palette.neutral[300],
    },
  },
  graphicWrapper: {
    textAlign: 'center',
  },
  graphic: {
    maxWidth: 311,
    maxHeight: 176,
  },
  title: {
    fontSize: 34,
    lineHeight: 1,
    fontWeight: 400,
    color: theme.palette.neutral[900],
  },
  popup: {
    width: 320,
    zIndex: 9999,
    height: 'auto',
    position: 'fixed',
    display: 'flex',
    borderRadius: '5px',
    border: `11px solid ${theme.palette.neutral[900]}`,
  },
  popupImg: {
    minWidth: 300,
    height: 'auto',
  },
  hide: {
    display: 'none',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <TransitionBounce ref={ref} {...props} />;
});

function OrderDuplicateAdDialog({
  isOpen,
  isLoading,
  setIsLoading,
  selected,
  setSelected,
  order,
  orderId,
  data,
  onClose,
  handleSubmit,
  isSingleFile = false,
  submitting,
}) {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [isMainFileReady, setIsMainFileReady] = useState(false);
  const [progress, setProgress] = useState(0);
  const [timeout, setTimeOut] = useState();
  const popup = useRef(null);
  const [activePreviewImageIndex, setActivePreviewImageIndex] = useState(0);
  const [activePopupImageUrl, setActivePopupImageUrl] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setProgress(0);
    return () => clearTimeout(timeout);
  }, []);

  const fetchFiles = () => {
    if (orderId && progress <= 100) {
      DataController.getOrderFiles(orderId).then((res) => {
        setProgress(progress + 25);
        if (res?.data?.length > 0) {
          setIsMainFileReady(res.data[0]?.storage?.original?.is_available);
          let newFiles = [];
          res?.data?.forEach((file) => {
            let newFile = {...file};
            let {innerWidth: windowWidth} = window;
            if (windowWidth < 576) windowWidth -= windowWidth * 0.25;
            else windowWidth = 400;
            let ar = calculateAspectRatio(file.filespec.pixel_width, file.filespec.pixel_height, windowWidth, 620);
            newFile.pixel_width = Math.round(file.filespec.pixel_width * ar);
            newFile.pixel_height = Math.round(file.filespec.pixel_height * ar);
            let carouselAR = calculateAspectRatio(file.filespec.pixel_width, file.filespec.pixel_height, 70, 70);
            newFile.carousel_width = Math.round(file.filespec.pixel_width * carouselAR);
            newFile.carousel_height = Math.round(file.filespec.pixel_height * carouselAR);
            newFiles.push(newFile);
          });
          setFiles(newFiles);
          setProgress(100);
          setIsLoading(false);
        } else {
          setTimeOut(
            setTimeout(() => {
              fetchFiles();
            }, 5000)
          );
        }
        return clearTimeout(timeout);
      });
    } else {
      setProgress(100);
      setFiles([]);
      setIsLoading(false);
      setIsMainFileReady(false);
      return clearTimeout(timeout);
    }
  };

  useEffect(() => {
    if (progress >= 100) {
      clearTimeout(timeout);
    }
  }, [progress]);

  useEffect(() => {
    if (isOpen) {
      setProgress(0);
      setIsMainFileReady(false);
      setSelected([]);

      if (!isSingleFile) {
        setTimeOut(
          setTimeout(() => {
            fetchFiles();
          }, 5000)
        );
        setIsLoading(true);
      }
    }
  }, [isOpen]);

  const calculateAspectRatio = (width, height, maxWidth, maxHeight) => {
    return Math.min(maxWidth / width, maxHeight / height);
  };

  const handleOpenPopup = (url) => (event) => {
    setActivePopupImageUrl(url);
    setOpen((prevOpen) => !prevOpen);
    let targetViewport = event.target.getBoundingClientRect();
    popup.current.style.top = targetViewport.top - 220 + 'px';
    popup.current.style.left = targetViewport.left - 320 + 'px';
  };

  const onMouseLeave = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const cellRenderer = (heading, row) => {
    switch (heading.id) {
      case 'checkbox':
        return <Checkbox size="small" checked={selected.includes(row.id)} />;
      case 'logo':
        return (
          row.image_thumbnail && (
            <Box className={classes.thumbWrap}>
              <OrderThumbnail src={row.image_thumbnail} isNew={true} />
            </Box>
          )
        );
      case 'text':
        return (
          <Box>
            <Box className={classes.textId}>#{row.id}</Box>
            <Box className={classes.textTitle}>{row.title}</Box>
            {row.companyname && <Box className={classes.textRow}>Client: {row.companyname}</Box>}
            {row.summary_line_1 && <Box className={classes.textRow}>{row.summary_line_1}</Box>}
            {row.summary_line_2 && <Box className={classes.textRow}>{row.summary_line_2}</Box>}
          </Box>
        );
      case 'file_status':
        return (
          <Box>
            <div
              className={
                row.file_status.status === 'error'
                  ? classes.fileStatusWarn
                  : row.file_status.status === 'approved'
                  ? classes.fileStatusApprov
                  : ''
              }
            >
              <span>
                {row.file_status.label && row.file_status.label}
                {row.file_status.date && (
                  <div>
                    <strong>{row.file_status.date}</strong>
                  </div>
                )}
              </span>
              {row.file_status.status === 'approved' && row.file_status.thumb && (
                <>
                  <ViewIcon
                    className={classes.fileStatusIcon}
                    onMouseEnter={handleOpenPopup(row.file_status.thumb)}
                    onMouseLeave={onMouseLeave}
                  />
                </>
              )}
            </div>
          </Box>
        );
      default:
        return '-';
    }
  };

  const cellPropsGenerator = (heading, row) => {
    switch (heading.id) {
      case 'checkbox':
        return {className: classes.checkboxCell};
      case 'text':
        return {className: classes.textCell};
      case 'logo':
        return {className: classes.logoCell};
      case 'file_status':
        return {className: classes.fileStatus};
    }
  };

  const rowPropsGenerator = (row) => {
    return {className: classes.defaultRow};
  };

  const handleClickRow = (e, row) => {
    setSelected((val) => {
      if (val.includes(row.id)) {
        return [...val].filter((item) => item !== row.id);
      } else {
        const newArr = [...val];
        newArr.push(row.id);
        return newArr;
      }
    });
  };

  const onConfirm = () => {
    if (isSingleFile) {
      handleSubmit();
    } else {
      if (orderId) {
        handleSubmit();
      }
    }
  };

  return (
    <CustomDialog
      TransitionComponent={Transition}
      onClose={onClose}
      disableBackdropClick={true}
      isOpen={isOpen}
      title="Use Your Ad for Multiple Orders"
      actions={[]}
      className={classes.dialogClass}
      dialogTitleClass={classes.dialogTitleClass}
      dialogContentClass={classes.dialogContentClass}
      maxWidth="xl"
      hideContentDivider
      hasXClose
      disableAutoFocus
      disableEnforceFocus
      positionedAbove
    >
      {data && data.length > 0 ? (
        <>
          <Box className={classes.innerWrap}>
            <Box className={classes.header}>
              {isSingleFile && (
                <>
                  <Box className={classes.previewImages}>
                    <div className={classes.previewWrapper}>
                      <img
                        className={classNames(
                          classes.previewImage,
                          files.length < 2 ? classes.noExtraCarouselImages : ''
                        )}
                        src={isSingleFile.thumbnail}
                        width={isSingleFile.filespec.pixel_width}
                        height={isSingleFile.filespec.pixel_height}
                        alt="preview image"
                      />
                    </div>
                  </Box>
                </>
              )}
              {!isSingleFile && (
                <>
                  {progress >= 100 ? (
                    isMainFileReady ? (
                      <Box className={classes.previewImages}>
                        <div className={classes.previewWrapper}>
                          <img
                            className={classNames(
                              classes.previewImage,
                              files.length < 2 ? classes.noExtraCarouselImages : ''
                            )}
                            src={files[activePreviewImageIndex].storage.thumbnail.url}
                            width={files[activePreviewImageIndex].pixel_width}
                            height={files[activePreviewImageIndex].pixel_height}
                            alt="preview image"
                          />
                        </div>

                        {files.length > 1 && (
                          <div className={classes.carousel}>
                            {files.map((file, index) => (
                              <div
                                className={classNames(
                                  classes.carouselImgWrapper,
                                  index === activePreviewImageIndex ? classes.carouselActiveImgWrapper : ''
                                )}
                                key={'carouselNav' + index}
                                onClick={() => setActivePreviewImageIndex(index)}
                              >
                                <img
                                  src={file.storage.thumbnail?.url}
                                  className={classes.carouselImg}
                                  style={{
                                    width:
                                      file.carousel_width > file.carousel_height ? file.carousel_width + 'px' : 'auto',
                                    height:
                                      file.carousel_width < file.carousel_height ? file.carousel_height + 'px' : 'auto',
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </Box>
                    ) : (
                      <Box className={classes.headerText}>
                        Couldn't preview the files because it is too large to load
                        <Button variant={'contained'} className={classes.downloadButton} color={'primary'}>
                          <a href={files[0]?.url} id="downloadfile" target="_blank">
                            <DownloadIcon />
                            Download
                          </a>
                        </Button>
                      </Box>
                    )
                  ) : (
                    <Box className={classes.headerText}>
                      Please wait while the ad is loading...
                      <LinearProgressWithLabel progress={progress} />
                    </Box>
                  )}
                </>
              )}
            </Box>
            <Box className={classes.ordersSec}>
              <Box className={classes.smallHeaderText}>Select any other orders you would like to use this ad for.</Box>
              <Box className={classes.dataTable}>
                <DataTable
                  headings={headings}
                  cellRenderer={cellRenderer}
                  cellPropsGenerator={cellPropsGenerator}
                  rowPropsGenerator={rowPropsGenerator}
                  onRowClick={handleClickRow}
                  rows={data}
                />
                <div ref={popup} className={classNames(classes.popup, open ? '' : classes.hide)}>
                  <img src={activePopupImageUrl} className={classes.popupImg} />
                </div>
              </Box>
              <Divider style={{margin: 0}} />
            </Box>
          </Box>
          <Box className={classes.actions}>
            <LoadingButton
              variant={'contained'}
              onClick={onConfirm}
              fullWidth={true}
              disabled={selected.length === 0 || isLoading}
              color={selected.length > 0 && !isLoading ? 'primary' : 'default'}
              loading={submitting}
            >
              Confirm
            </LoadingButton>
            <Button variant={'text'} onClick={onClose} fullWidth={true} color={'default'}>
              not now
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box display="flex" alignItems="center" justifyContent="center" height={200} width="100%">
            No open orders match your file specs
          </Box>
        </>
      )}
    </CustomDialog>
  );
}

export default OrderDuplicateAdDialog;
