import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 'calc(100vh - 132px)', // todo responsive
    backgroundColor: theme.palette.neutral[300],
  },
  top: {
    backgroundColor: theme.palette.neutral[200],
    padding: 32,
  },
  rootBlock: {
    '& + $rootBlock': {
      marginTop: 22,
    },
  },
  skel: {
    '& + $skel': {
      marginTop: 10,
    },
  },
}));

function SkeletonOrderSidebar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <div className={classes.rootBlock}>
          {[...Array(5)].map((e, i) => (
            <Skeleton key={i} className={classes.skel} animation="wave" variant="rect" height={32} />
          ))}
        </div>
        <div className={classes.rootBlock}>
          {[...Array(5)].map((e, i) => (
            <Skeleton key={i} className={classes.skel} animation="wave" variant="rect" height={32} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default SkeletonOrderSidebar;
