import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import theme from 'theme';

const useStyles = makeStyles(() => ({
  options: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',

    '& span': {
      fontSize: 17,
      fontWeight: 400,
    },

    [theme.default.breakpoints.up('lg')]: {
      flexDirection: 'row',

      '> button': {
        marginBottom: 8,
      },
    },
  },
  subtitle: {
    color: theme.default.palette.neutral[700],
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '25px',
    marginTop: 14,

    [theme.default.breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },
}));

export default function LoginForm({
  email,
  errors,
  handleForgotPassword,
  handleLogin,
  isLoading,
  password,
  setEmail,
  setPassword,
  hideForgotPasswordLink,
}) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h2">Sign in</Typography>

      <Typography className={classes.subtitle}>
        Welcome back! Please sign in and we'll get your order placed in no time.
      </Typography>

      <Box mt={4}>
        <form autoComplete="off" noValidate onSubmit={handleLogin}>
          <Box>
            <TextField
              id="login-email"
              error={!!errors.email}
              helperText={errors.email}
              label={errors.email || 'Email address'}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="email@domain.com"
              required
              value={email}
            />
          </Box>
          <Box mt={4}>
            <TextField
              id="login-password"
              error={!!errors.password}
              helperText={errors.password}
              label={errors.password || 'Password'}
              onChange={(e) => setPassword(e.target.value)}
              required
              type="password"
              value={password}
            />
          </Box>
          {!hideForgotPasswordLink && (
            <>
              <Box mt={3} mb={2} className={classes.options}>
                <Button onClick={handleForgotPassword} variant="text">
                  forgot password
                </Button>
              </Box>
              <Divider />
            </>
          )}
          <Box mt={3}>
            <Button variant="contained" type="submit" disabled={!email || !password || isLoading}>
              Sign in
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
}
