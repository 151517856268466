import React, {useRef} from 'react';
import useData from 'lib/hooks/useData';
import AssigNewClient from '../TeamPage/AssignNewClient';
import MultiSelectList from '../common/MultiSelectList';

export default function ClientSelect({selectedClients, setSelectedClients, allowAddingNewClient, allowMultiselect}) {
  const listInner = useRef(null);

  const state = useData({
    queries: ['getMyClients'],
    setLoadingState: true,
  });

  const handleAfterClientAdded = (id) => {
    setSelectedClients([...selectedClients, id]);
    document.getElementById(`client-id-${id}`).scrollIntoView({behavior: 'smooth'});
  };

  return (
    <MultiSelectList
      idRoot="client-id-"
      items={state.myClients.clients}
      itemsLoaded={state.myClients.isLoaded}
      noItemsText={'No clients found.'}
      listFooter={allowAddingNewClient && <AssigNewClient onClientAdded={handleAfterClientAdded} />}
      listInnerRef={listInner}
      selected={selectedClients}
      setSelected={setSelectedClients}
      itemTextFormater={(item) => item.name}
      allowMultiselect={allowMultiselect}
    />
  );
}

ClientSelect.defaultProps = {
  allowAddingNewClient: true,
  allowMultiselect: true,
};
