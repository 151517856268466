import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import {ReactComponent as OhmGuy} from 'images/ohmGuy.svg';
import {useLocation} from 'react-router-dom';
import useLinkHandler from '../../../lib/hooks/useLinkHandler';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  wrap: {
    maxWidth: '100%',
    '& svg': {
      maxWidth: '100%',
      height: 'auto',
    },
  },
  text: {
    color: theme.palette.neutral[800],
    fontSize: 20,
    lineHeight: '25px',
    marginBottom: 16,
  },
  cta: {
    marginBottom: 16,
  },
}));

const locations = {
  '/orders/history': 'In History',
  '/orders/inprocess': 'In Process',
  '/orders/upcoming': 'In Upcoming',
};

function SubOrdersEmpty({renderRightSide}) {
  const location = useLocation();
  const classes = useStyles();
  const {getLinkButtonProps} = useLinkHandler();

  return (
    <>
      <Box style={{textAlign: 'right'}}>{renderRightSide()}</Box>
      <Box className={classes.root}>
        <Box className={classes.wrap}>
          <Box className={classes.text}>{`You have no orders currently ${locations[location.pathname]}.`}</Box>
          <Box className={classes.cta}>
            <Button variant="contained" {...getLinkButtonProps({url: '/orders/all'})}>
              Take Me to All Orders
            </Button>
          </Box>
          <OhmGuy />
        </Box>
      </Box>
    </>
  );
}

export default SubOrdersEmpty;
