import React from 'react';
import {Page} from 'components/elements/common';
import {Box, Button, Typography, makeStyles} from '@material-ui/core';
import {ReactComponent as Graphic404} from 'images/404.svg';
import {ReactComponent as Graphic404Guy} from 'images/404-guy.svg';
import {useIntercom} from 'react-use-intercom';

const useStyles = makeStyles((theme) => ({
  wrap: {
    minHeight: 'calc(100vh - 72px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
    overflow: 'hidden',
  },
  g404: {
    position: 'relative',
    left: -29,
    marginTop: -58,
    marginBottom: 42,
  },
  g404guy: {
    zIndex: 10,
  },
  title: {
    fontSize: 34,
    textAlign: 'center',
    margin: '0 20px',

    [theme.breakpoints.up('tb')]: {
      fontSize: 55,
    },
  },
  copy: {
    margin: '24px 20px 32px',
    textAlign: 'center',
    fontSize: 20,
    color: theme.palette.neutral[700],
  },
  btn: {
    display: 'inline',
    padding: 0,
    lineHeight: '30px',

    '& .MuiButton-label': {
      fontSize: 20,
      fontWeight: 400,
      textDecoration: 'underline',
    },
  },
}));

const NotFoundPage = () => {
  const classes = useStyles();

  const intercom = useIntercom();

  const showIntercom = () => {
    intercom.show();
  };

  return (
    <Page>
      <Box className={classes.wrap}>
        <Graphic404Guy className={classes.g404guy} />
        <Graphic404 className={classes.g404} />
        <Typography variant="h2" className={classes.title}>
          Page not Found...
        </Typography>
        <Typography className={classes.copy}>
          That page you were looking for isn't here.
          <br />
          We're sorry about that!{' '}
          <Button className={classes.btn} onClick={showIntercom}>
            Chat with us
          </Button>{' '}
          or head home.
        </Typography>

        <Button variant="contained" href="/">
          Let's Go Home
        </Button>
      </Box>
    </Page>
  );
};

export default NotFoundPage;
