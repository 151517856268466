import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  trimline: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minHeight: 48,
    padding: '8px 0',
    borderBottom: `1px solid ${theme.palette.neutral[300]}`,
  },
  trimlineLeft: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  trimlineRight: {
    fontSize: 15,
    lineHeight: '20px',
    marginLeft: 'auto',
    marginRight: 0,
    color: theme.palette.neutral[900],
  },
  trimlineExample: {
    width: 54,
    height: 1,
    marginRight: 8,
  },
  trimlineName: {
    fontSize: 15,
    lineHeight: '20px',
  },
}));

function OrderTrimlinesLegend({trimlines}) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {trimlines.map((line, lineIndex) => (
        <Box key={lineIndex} className={classes.trimline}>
          <Box className={classes.trimlineLeft}>
            <Box
              className={classes.trimlineExample}
              style={{border: `3px ${line.border_style} ${line.border_color}`}}
            />
            <Box className={classes.trimlineName} style={{color: line.border_color}}>
              {line.title}
            </Box>
          </Box>
          <Box className={classes.trimlineRight}>{line.value}</Box>
        </Box>
      ))}
    </Box>
  );
}

export default OrderTrimlinesLegend;
