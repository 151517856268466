import {Box, Typography, makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  priceWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 40,
    fontWeight: 'normal',
    borderBottom: `2px solid ${theme.palette.neutral[200]}`,
  },
  title: {
    color: theme.palette.neutral[600],
  },
  price: {
    color: theme.palette.neutral[900],
  },
  strong: {
    fontWeight: 800,
  },
}));

function CheckoutOrderDetails({item}) {
  const classes = useStyles();

  return (
    <Box>
      {item.receipt_lines.map((receiptLine, i) => (
        <Box key={i} className={classes.priceWrapper}>
          <Typography className={receiptLine.is_total ? classes.strong : classes.title}>
            {receiptLine.description}
          </Typography>
          <Typography className={receiptLine.is_total ? classes.strong : classes.price}>{receiptLine.value}</Typography>
        </Box>
      ))}
    </Box>
  );
}

export default CheckoutOrderDetails;
