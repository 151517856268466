import React, {useState} from 'react';
import {Box, Typography, Button} from '@material-ui/core';
import AssignedTeamMembers from './AssignedTeamMembers';
import AddTeamMembersDialog from './AddTeamMembersDialog';

export default function TeamSection({client, meta}) {
  const [teamDialogOpen, setTeamDialogOpen] = useState(false);

  return (
    <>
      <Typography variant="h4">Team Members</Typography>

      <AssignedTeamMembers client={client} meta={meta} hideRole="true" />

      <Box mt={3}>
        <Button
          color="secondary"
          onClick={() => {
            setTeamDialogOpen(true);
          }}
          variant="outlined"
        >
          Add Team Member
        </Button>
      </Box>

      <AddTeamMembersDialog handleClose={() => setTeamDialogOpen(false)} open={teamDialogOpen} client={client} />
    </>
  );
}
