import React from 'react';
import {Box, ButtonGroup, Button, makeStyles} from '@material-ui/core';
import {ReactComponent as GridViewIcon} from 'images/gridView.svg';
import {ReactComponent as ListViewIcon} from 'images/listView.svg';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  buttonGroupWrap: {
    marginLeft: 'auto',
  },

  viewSelectButton: {
    width: 52,
    height: 46,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      marginRight: 0,

      '& path': {
        stroke: theme.palette.primary.main,
      },
    },

    '&:not(:last-child)': {
      borderRight: 'none',
    },
  },

  selected: {
    '& svg path': {
      stroke: theme.palette.white,
    },
  },
}));

export default function FilesViewToggle({view, setView}) {
  const classes = useStyles();

  const handleSetView = (selected) => {
    setView(selected);
    localStorage.setItem('ad_files_view', selected);
  };

  return (
    <Box className={classes.buttonGroupWrap}>
      <ButtonGroup aria-label="" disableElevation>
        <Button
          variant={view === 'list' ? 'contained' : 'outlined'}
          color="primary"
          className={classNames(classes.viewSelectButton, view === 'list' ? classes.selected : '')}
          onClick={() => handleSetView('list')}
        >
          <ListViewIcon />
        </Button>
        <Button
          variant={view === 'grid' ? 'contained' : 'outlined'}
          color="primary"
          className={classNames(classes.viewSelectButton, view === 'grid' ? classes.selected : '')}
          onClick={() => handleSetView('grid')}
        >
          <GridViewIcon />
        </Button>
      </ButtonGroup>
    </Box>
  );
}
