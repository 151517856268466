import CustomDialog from './CustomDialog.js';
import useAppState from '../../../lib/hooks/useAppState.js';
import React, {useEffect, useState} from 'react';
import actions from '../../../store/actions.js';
import LinearProgress from '@material-ui/core/LinearProgress';
import {makeStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {useHistory} from 'react-router-dom';

const throttleWaitTime = 60000;

const useStyles = makeStyles((theme) => ({
  root: {
    // using !important to override zIndex added in JS
    zIndex: `8000 !important`,

    '& .MuiDialogContent-root': {
      paddingTop: 0,
    },
  },
  text: {
    fontSize: 15,
    lineHeight: '25px',
    marginBottom: 16,
  },
}));

function ThrottledModal() {
  const classes = useStyles();
  const {dispatch, state} = useAppState();
  const [intvlId, setIntvlId] = useState(null);
  const [intvlProgress, setIntvlProgress] = useState(0);

  const history = useHistory();

  useEffect(() => {
    if (state.temporary.isThrottled && !intvlId) {
      setIntvlId(
        window.setInterval(() => {
          // set progress in ms
          setIntvlProgress((val) => val + 1000);
        }, 1000)
      );
    }
  }, [state.temporary.isThrottled]);

  useEffect(() => {
    if (intvlProgress > throttleWaitTime) {
      // done waiting
      dispatch({
        type: actions.SET_TEMPORARY,
        payload: {
          key: 'isThrottled',
          val: false,
        },
      });

      // cleanup
      window.setTimeout(() => {
        window.clearInterval(intvlId);
        setIntvlId(null);
        setIntvlProgress(0);
        window.location.reload();
      }, 150);
    }
  }, [intvlProgress]);

  const handleClose = () => {
    return history.go(-1);
  };

  return (
    <CustomDialog
      className={classes.root}
      maxWidth="xs"
      isOpen={state.temporary.isThrottled}
      actions={[
        {
          label: 'Cancel',
          fullWidth: false,
          variant: 'contained',
          color: 'secondary',
          style: {marginRight: '20px'},
          onClick: handleClose,
        },
      ]}
    >
      <Box className={classes.text}>Too many requests, please wait.</Box>
      <LinearProgress variant="determinate" value={(intvlProgress / throttleWaitTime) * 100} />
    </CustomDialog>
  );
}

export default ThrottledModal;
