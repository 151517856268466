import Box from '@material-ui/core/Box';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles.js';

const useStyles = makeStyles((theme) => ({
  textRow: {
    color: theme.palette.neutral[800],
    fontSize: 13,
    lineHeight: '18px',
    whiteSpace: 'normal',
  },
}));

function OrderSummaryText({order, isAgency}) {
  const classes = useStyles();

  return (
    <>
      {isAgency && order.companyname && <Box className={classes.textRow}>Client: {order.companyname}</Box>}
      {order.summary_line_1 && <Box className={classes.textRow}>{order.summary_line_1}</Box>}
      {order.summary_line_2 && <Box className={classes.textRow}>{order.summary_line_2}</Box>}
    </>
  );
}

export default OrderSummaryText;
