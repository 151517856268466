import React, {useState, useEffect, useRef} from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useData from 'lib/hooks/useData.js';
import OrdersHeader from './OrdersHeader.js';
import OrdersFilters from './OrdersFilters.js';
import OrdersTable from './OrdersTable.js';
import Pagination from '@material-ui/lab/Pagination';
import OrdersEmpty from './OrdersEmpty.js';
import SubOrdersEmpty from './SubOrdersEmpty';
import OrdersListAlerts from '../OrderPage/OrdersListAlerts.js';
import useQueryParams from '../../../lib/hooks/useQueryParams.js';
import {scrollTo} from '../../../lib/functions.js';
import SkeletonTable from '../common/SkeletonTable.js';
import {Link, useHistory} from 'react-router-dom';
import {ReactComponent as ArrowLeftIcon} from '../../../images/arrow-left.svg';

const useStyles = makeStyles((theme) => ({
  pagination: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '24px 0',

    '& nav': {
      minWidth: 350,

      '& ul': {
        justifyContent: 'center',
      },
    },
  },
  canceledOrdersLink: {
    fontSize: 17,
  },
}));

function OrdersList({status, title}) {
  const firstRender = useRef(true);
  const history = useHistory();
  const classes = useStyles();
  const {replaceQueryParams, queryParams} = useQueryParams();
  const [filters, setFilters] = useState({
    company: queryParams.company || '',
    media_type: queryParams.media_type || '',
    sort: queryParams.sort || '',
    page: queryParams.page || 1,
  });
  const [loading, setLoading] = useState(false);
  const [skeletonLoading, setSkeletonLoading] = useState(false);

  const state = useData({
    queries: [['getOrders', [filters.company, filters.media_type, filters.sort, filters.page, status]]],
    setLoadingState: true,
    prevent: filters.prevent,
    cbBefore: () => {
      setLoading(true);
      setSkeletonLoading(true);
    },
    cbAfter: () => {
      setLoading(false);
      setSkeletonLoading(false);
      replaceQueryParams(filters);
    },
  });

  useEffect(() => {
    scrollTo(0, 250);
  }, [filters.page]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    const optionList = state.orders.meta.options;
    if (!optionList) {
      return;
    }

    let apiFilters = {};

    optionList.forEach(function ({id, selected_index, options}) {
      const option = options[selected_index];
      if (filters[id] !== option?.value) {
        apiFilters[id] = option?.value;
      }
    });

    if (!Object.keys(apiFilters).length) {
      return;
    }

    apiFilters = {...filters, ...apiFilters};

    setFiltersSilent(apiFilters);
    replaceQueryParams(apiFilters);
  }, [state.orders.meta]);

  function setFiltersSilent(data) {
    setFilters({...data, prevent: true});
  }

  const handlePageChange = (e, value) => {
    setFilters(({company, media_type, sort}) => ({
      company,
      media_type,
      sort,
      page: value,
      prevent: false,
    }));
  };

  const isAgency = state.userData.is_agency;
  const upcomingCount = state.ordersStats.data?.open;
  const inProgressCount = state.ordersStats.data?.inflight;
  const inHistoryCount = state.ordersStats.data?.completed;
  const ordersCount = upcomingCount + inProgressCount + inHistoryCount;

  const renderRightSide = () => {
    if (status === 'completed') {
      return (
        <Link className={classes.canceledOrdersLink} to="/orders/canceled">
          see canceled orders
        </Link>
      );
    }
  };

  if (!loading && state.orders.isLoaded && state.orders.data.length === 0 && !state.orders?.meta?.is_filtered) {
    return ordersCount ? <SubOrdersEmpty renderRightSide={renderRightSide} /> : <OrdersEmpty />;
  }

  const renderLeftSide = () => {
    if (status === 'canceled') {
      return <Button startIcon={<ArrowLeftIcon />} variant="text" onClick={() => history.goBack()} />;
    }
  };

  return (
    <Box>
      {skeletonLoading ? (
        <SkeletonTable />
      ) : (
        <>
          <OrdersHeader title={title} rightSide={renderRightSide()} leftSide={renderLeftSide()} />
          <OrdersFilters filters={filters} setFilters={setFilters} isAgency={isAgency} />
          <OrdersListAlerts />
          <OrdersTable isAgency={isAgency} status={status} />
          {state.orders?.meta?.count && state.orders?.meta?.count > 0 ? (
            <Box className={classes.pagination}>
              <Pagination
                count={state.orders?.meta?.total_pages || 1}
                onChange={handlePageChange}
                page={state.orders?.meta?.page || 1}
                boundaryCount={1}
                siblingCount={1}
              />
            </Box>
          ) : (
            ''
          )}
        </>
      )}
    </Box>
  );
}

export default OrdersList;
