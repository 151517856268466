import {useState} from 'react';
import {Avatar, Box, FormControl, InputLabel, makeStyles, MenuItem, Select, Typography} from '@material-ui/core';
import useAppState from 'lib/hooks/useAppState.js';
import theme from 'theme/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 80,
    width: '100%',
    padding: 16,
    borderRadius: 10,

    [theme.breakpoints.up('sm')]: {
      marginBottom: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 16,
      marginTop: 60,
    },
    [theme.breakpoints.down('xs')]: {
      height: 45,
      padding: 0,
    },
  },
  icon: {
    padding: 5,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  agencyName: {
    fontWeight: 800,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  selectWrapper: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  agencyNameWrapper: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      top: -30,
      left: -10,
    },
  },
  agencyWrapper: {
    fontSize: 20,
    lineHeight: '25px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
  },
  clientListDropdown: {
    height: 45,
    width: 442,
    '& .MuiOutlinedInput-input': {
      height: 40,
      display: 'flex',
      alignContents: 'center',
      alignItems: 'center',
      padding: 0,
      backgroundColor: theme.palette.white,
    },

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },

    '& fieldset': {
      top: 0,
    },
  },
  avatarIcon: {
    height: 24,
    width: 24,
  },
  clientName: {
    height: 24,
    marginLeft: 8,
  },
  clientItem: {
    height: 40,
  },
  input: {
    margin: '-6.5px 24px',
    zIndex: 100,
  },
  formControl: {
    marginTop: 10,
    marginBottom: 30,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

function AgencyHeader({companyId, updateCheckoutCompany}) {
  const classes = useStyles();
  const {state} = useAppState();
  const [client, setClient] = useState(companyId);

  const handleChange = (e) => {
    setClient(e.target.value);
    updateCheckoutCompany(e.target.value);
  };

  const AvatarIcon = ({src}) => {
    return <Avatar className={classes.avatarIcon} src={src} border={theme.palette.neutral[900]} badge="Client" />;
  };

  return (
    <>
      <Box className={classes.container}>
        <Typography className={classes.agencyWrapper}>
          <span className={classes.agencyName}>{state.userData.company ?? '[Agency name]'}</span> buying for:
        </Typography>
        <Box>
          <FormControl className={classes.formControl}>
            <InputLabel className={classes.input} shrink={false}>
              <span hidden={client}>Select a Client...</span>
            </InputLabel>
            <Select value={client} className={classes.clientListDropdown} onChange={handleChange} variant="outlined">
              {state.userData?.companies?.map((client) => (
                <MenuItem className={classes.clientItem} key={client.id} value={client.id}>
                  <AvatarIcon src={client.avatar} />
                  <Typography className={classes.clientName} noWrap={true}>
                    {client.name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </>
  );
}

export default AgencyHeader;
