import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  top: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: ({isGT}) => (isGT ? '#84ECDB' : '#FFE5BB'),
    borderRadius: 10,
    marginBottom: 20,

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },

    '@media print': {
      display: 'none',
    },
  },
  info: {
    fontWeight: 800,
    fontSize: 20,
    lineHeight: '25px',

    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  viewAllButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 20,
    },
  },
  gtButton: {
    borderRadius: 0,
    background: '#B7FF05',
    color: '#262626',
  },
}));

function ViewAll({order, all, all_total, handleViewAllClick, isGT}) {
  const classes = useStyles({isGT});

  return (
    <Card className={classes.top}>
      <Box className={classes.info}>
        <div>{`${order.company} has ${all.length} open orders, ${all_total} in total`}</div>
      </Box>
      <Button
        className={classNames(classes.viewAllButton, isGT && classes.gtButton)}
        variant="contained"
        size="small"
        onClick={handleViewAllClick}
      >
        View All
      </Button>
    </Card>
  );
}

export default ViewAll;
