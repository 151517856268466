import React, {useState} from 'react';
import {Box, Button, CircularProgress, IconButton, makeStyles} from '@material-ui/core';
import {ReactComponent as DownloadIcon} from 'images/download.svg';
import DataController from 'lib/controllers/DataController';
import LoadingButton from '../common/LoadingButton';
import {useSnackbar} from 'notistack';

const useStyles = makeStyles((theme) => ({
  filesetRow: {
    borderTop: `1px solid ${theme.palette.neutral[400]}`,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',

    [theme.breakpoints.up('tb')]: {
      padding: 32,
      flexDirection: 'row',
      alignItems: 'center',
    },
  },

  transactionMeta: {
    display: 'flex',
    marginBottom: 16,
  },

  orderThumb: {
    width: 100,
    height: 100,
    minWidth: 100,
    borderRadius: 4,
    overflow: 'hidden',

    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center center',
    },
  },

  summaryWrap: {
    marginLeft: 16,
    maxWidth: 200,
  },
  summaryId: {
    fontSize: 11,
    marginBottom: 4,
    lineHeight: '13px',
    color: theme.palette.neutral[800],
  },
  summaryTitle: {
    fontSize: 20,
    marginBottom: 4,
    lineHeight: '25px',
    fontWeight: 'normal',
    color: theme.palette.neutral[900],
  },
  summaryRow: {
    fontSize: 13,
    lineHeight: '18px',
    whiteSpace: 'normal',
    color: theme.palette.neutral[800],
  },

  filesets: {
    position: 'relative',
    flexGrow: 1,
    marginBottom: 16,
    minHeight: 120,

    [theme.breakpoints.up('tb')]: {
      marginLeft: 16,
      marginRight: 16,
      maxWidth: 'calc(100% - 500px)',
    },
  },

  filesetPreviewWrap: {
    overflowX: 'auto',
    paddingBottom: 12,
    paddingTop: 6,
  },

  filesetPreviewBorder: {
    display: 'none',

    [theme.breakpoints.up('tb')]: {
      display: 'block',
      position: 'absolute',
      top: 0,
      right: -2,
      height: '100%',
      width: 10,
      overflow: 'hidden',
    },
  },

  filesetPreviewShadow: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: 1,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.12), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 3px 5px rgba(0, 0, 0, 0.2)',
  },

  filesetPreview: {
    display: 'flex',
    paddingRight: 32,
  },

  gap: {
    display: 'none',

    [theme.breakpoints.up('tb')]: {
      display: 'block',
      minWidth: 1,
      height: 100,
    },
  },

  previewThumb: {
    width: 100,
    minWidth: 100,
    height: 100,
    borderRadius: 4,
    background: theme.palette.accent.marketMint[300],
    border: `1px solid ${theme.palette.accent.marketMint[700]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16,
  },

  previewThumbImg: {
    width: 92,
    height: 92,
    objectFit: 'contain',
    objectPosition: 'center center',
  },

  filesetActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',

    [theme.breakpoints.up('tb')]: {
      marginLeft: 'auto',
      width: 200,
      flexDirection: 'column',
    },
  },

  downloadIconButton: {
    [theme.breakpoints.up('tb')]: {
      display: 'none',
    },
  },

  downloadTextButton: {
    display: 'none',

    [theme.breakpoints.up('tb')]: {
      display: 'flex',
      marginTop: 16,

      '& svg': {
        width: 24,
        height: 24,
      },

      '& .MuiButton-label': {
        fontWeight: 400,
      },
    },
  },

  downloadIcon: {
    '& path': {
      stroke: theme.palette.primary.main,
    },
  },

  circularProgress: {
    '& svg': {
      marginRight: 0,
    },
  },
}));

export default function FileReuseDialogRow({item, onOrderReuseClick}) {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();

  const [downloading, setDownloading] = useState(false);
  const [loading, setLoading] = useState(false);

  const downloadFileset = () => {
    setDownloading(true);

    DataController.APICallAuth(item.download_url, {
      method: 'GET',
    })
      .then(({data, success, error, message}) => {
        if (success) {
          const a = document.createElement('a');
          a.href = data.download_url;
          a.download = true;
          // a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  const handleReuseClick = async () => {
    setLoading(true);
    await onOrderReuseClick(item.transaction.id);
    setLoading(false);
  };

  return (
    <Box className={classes.filesetRow}>
      <Box className={classes.transactionMeta}>
        <Box className={classes.orderThumb}>
          <img src={item.transaction.image_thumbnail} alt="" className={classes.previewThumbImg} />
        </Box>

        {/* TODO: this format keeps repeating throughout - is there a reusable component? */}
        <Box className={classes.summaryWrap}>
          <Box className={classes.summaryId}>#{item.transaction.id}</Box>
          <Box className={classes.summaryTitle}>{item.transaction.summary_title}</Box>
          {item.transaction.summary_line_1 && (
            <Box className={classes.summaryRow}>{item.transaction.summary_line_1}</Box>
          )}
          {item.transaction.summary_line_2 && (
            <Box className={classes.summaryRow}>{item.transaction.summary_line_2}</Box>
          )}
        </Box>
      </Box>
      <Box className={classes.filesets}>
        <Box className={classes.filesetPreviewWrap}>
          <Box className={classes.filesetPreview}>
            {item.thumbnails.map((thumbnail, index) => (
              <Box key={index} className={classes.previewThumb}>
                <img src={thumbnail.thumbnail} alt="" className={classes.previewThumbImg} />
              </Box>
            ))}

            <Box className={classes.gap}></Box>
          </Box>
        </Box>
        <Box className={classes.filesetPreviewBorder}>
          <Box className={classes.filesetPreviewShadow}></Box>
        </Box>
      </Box>

      <Box className={classes.filesetActions}>
        <LoadingButton loading={loading} variant="contained" color="primary" onClick={handleReuseClick}>
          Re-use these files
        </LoadingButton>
        <IconButton className={classes.downloadIconButton} onClick={downloadFileset} disabled={downloading}>
          {downloading ? <CircularProgress size={24} /> : <DownloadIcon className={classes.downloadIcon} />}
        </IconButton>
        <Button color="primary" className={classes.downloadTextButton} onClick={downloadFileset} disabled={downloading}>
          {downloading ? (
            <Box width={24} height={24} display="flex" alignItems="center" justifyContent="center" mr={2}>
              <CircularProgress size={24} className={classes.circularProgress} />
            </Box>
          ) : (
            <DownloadIcon className={classes.downloadIcon} />
          )}
          download set
        </Button>
      </Box>
    </Box>
  );
}
