import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import theme from 'theme';

const useStyles = makeStyles(() => ({
  options: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',

    '& span': {
      fontSize: 17,
      fontWeight: 400,
    },

    [theme.default.breakpoints.up('lg')]: {
      flexDirection: 'row',

      '> button': {
        marginBottom: 8,
      },
    },
  },
  subtitle: {
    color: theme.default.palette.neutral[700],
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '25px',
    marginTop: 14,

    [theme.default.breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },
  nameWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

export default function SignUpForm({
  firstName,
  lastName,
  companyName,
  setFirstName,
  setLastName,
  setCompanyName,
  email,
  errors,
  handleSignUp,
  isLoading,
  password,
  setEmail,
  setPassword,
}) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h2">Sign Up</Typography>

      <Typography className={classes.subtitle}>
        Unlock access to hundreds of the very best local advertising options in your market with your FREE Addy account.
      </Typography>

      <Box mt={4}>
        <form autoComplete="off" noValidate onSubmit={handleSignUp}>
          <Box className={classes.nameWrapper}>
            <Box mr={2}>
              <TextField
                id="signup-name"
                error={!!errors.firstname}
                helperText={errors.firstname}
                label={errors.email || 'First Name'}
                onChange={(e) => setFirstName(e.target.value)}
                required
                value={firstName}
              />
            </Box>
            <Box>
              <TextField
                id="signup-lastname"
                error={!!errors.lastname}
                helperText={errors.lastname}
                label={errors.email || 'Last Name'}
                onChange={(e) => setLastName(e.target.value)}
                required
                value={lastName}
              />
            </Box>
          </Box>
          <Box mt={4}>
            <TextField
              id="signup-company-name"
              error={!!errors.company}
              helperText={errors.company}
              label={errors.email || 'Company name'}
              onChange={(e) => setCompanyName(e.target.value)}
              required
              value={companyName}
            />
          </Box>
          <Box className={classes.nameWrapper} mt={4}>
            <Box mr={2}>
              <TextField
                id="signup-email"
                error={!!errors.email}
                helperText={errors.email}
                label={errors.email || 'Email address'}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email@domain.com"
                required
                value={email}
              />
            </Box>
            <Box>
              <TextField
                id="signup-password"
                error={!!errors.password}
                helperText={errors.password}
                label={errors.password || 'Password'}
                onChange={(e) => setPassword(e.target.value)}
                required
                type="password"
                value={password}
              />
            </Box>
          </Box>
          <Box mt={3} mb={2}>
            <Checkbox />
            sign up for the Addy Newsletter!
          </Box>
          <Box mt={3}>
            <Button variant="contained" type="submit" disabled={!email || !password || isLoading}>
              Sign Up
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
}
