import React, {useRef} from 'react';
import {SnackbarProvider} from 'notistack';
import {Slide, Button, makeStyles} from '@material-ui/core';
import {ReactComponent as CloseIcon} from 'images/x.svg';

const progressBar = {
  position: 'relative',
  overflow: 'hidden',

  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 5,
    background: 'rgba(0, 0, 0, 0.1)',
    transformOrigin: 'center left',

    animationName: 'toastProgress',
    animationDuration: '5s',
    animationRepeat: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  containerAnchorOriginTopRight: {
    top: 86,
    zIndex: 10000,
  },

  variantSuccess: {
    background: theme.palette.primary.main,
    ...progressBar,
  },

  variantError: {
    background: theme.palette.error.main,
    ...progressBar,
  },
}));

export default function SnackbarWrapper({children}) {
  const notistackRef = useRef();
  const classes = useStyles();

  return (
    <SnackbarProvider
      ref={notistackRef}
      classes={{...classes}}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      TransitionComponent={Slide}
      hideIconVariant
      variant="success"
      action={(key) => (
        <Button
          onClick={() => {
            notistackRef.current.closeSnackbar(key);
          }}
        >
          <CloseIcon />
        </Button>
      )}
    >
      {children}
    </SnackbarProvider>
  );
}
