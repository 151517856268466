import React, {useState} from 'react';
import {ReactComponent as DownloadIcon} from 'images/download.svg';
import {ReactComponent as ShareIcon} from 'images/share.svg';
import MoreMenu from '../common/MoreMenu';
import {Box, Button, makeStyles, Typography} from '@material-ui/core';
import CustomDialog from '../common/CustomDialog';
import EmailTextarea from '../TeamPage/EmailTextarea';
import DataController from 'lib/controllers/DataController';
import {useSnackbar} from 'notistack';

const useStyles = makeStyles((theme) => ({
  purpleIcon: {
    marginRight: 8,
    width: 24,
    height: 24,

    '& path': {
      stroke: theme.palette.primary[100] + ' !important',
    },
  },

  subtitle: {
    fontWeight: 800,
    fontSize: 17,
    color: theme.palette.neutral[900],
  },

  customActions: {
    background: theme.palette.accent.marketMint[100],
    padding: 24,
    margin: '0 -24px -24px -24px',
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${theme.palette.neutral[400]}`,
  },
}));

export default function FilesMoreMenu({file}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);

  const {enqueueSnackbar} = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = () => {
    setLoading(true);

    DataController.shareFile(file.id, emails)
      .then(({data, success, message}) => {
        if (success) {
          enqueueSnackbar(message);
          handleClose();
          setEmails([]);
        } else {
          enqueueSnackbar(message);
        }
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar(err);
      });
  };

  return (
    <>
      <MoreMenu
        items={[
          {
            label: (
              <a href={file.url} download target="_blank">
                <Box display="flex" alignItems="center">
                  <DownloadIcon className={classes.purpleIcon} /> Download
                </Box>
              </a>
            ),
          },
          {
            label: (
              <Box display="flex" alignItems="center">
                <ShareIcon className={classes.purpleIcon} /> Share via Email
              </Box>
            ),
            onClick: () => setOpen(true),
          },
        ]}
      />

      <CustomDialog
        onClose={handleClose}
        scroll="body"
        title="Share via email"
        maxWidth="xs"
        dialogContentClass={classes.body}
        open={open}
        actions={[
          {
            label: 'Cancel',
            fullWidth: false,
            variant: 'text',
            color: 'secondary',
            style: {marginRight: 12},
            onClick: handleClose,
          },
          {
            label: 'Send',
            fullWidth: false,
            useLoadingButton: true,
            loading: loading,
            onClick: handleSend,
            // progress: uploadProgress,
            // disabled: !src
          },
        ]}
        dialogActionsClass={classes.customActions}
        hideContentDivider
        hasXClose
      >
        <Box className={classes.uploadImage}>
          <Typography className={classes.subtitle}>E-mails separated by commas</Typography>
          <EmailTextarea items={emails} setItems={setEmails} />
        </Box>
      </CustomDialog>
    </>
  );
}
