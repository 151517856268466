import React, {useState, useEffect} from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Button from '@material-ui/core/Button';
import highFive from 'images/high_five.svg';
import {Divider, makeStyles} from '@material-ui/core';
import FilePreviewDialog from '../common/FilePreviewDialog';
import useAppState from '../../../lib/hooks/useAppState';
import useLinkHandler from '../../../lib/hooks/useLinkHandler';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    display: 'flex',
    padding: '0 0 0 10px',
  },
  date: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    lineHeight: '18px',
  },
  title: {
    fontSize: 15,
    margin: '10px 0',
    lineHeight: '20px',
  },
  imgWrapper: {
    marginLeft: 25,
  },
  error: {
    color: theme.palette.error.main,
  },
  timelineTitle: {
    fontWeight: 800,
    fontSize: 20,
    lineHeight: '25px',
    marginBottom: 50,
    color: theme.palette.neutral[900],
  },
  timeLineConnectorPast: {
    border: `1px solid ${theme.palette.primary[500]}`,
    backgroundColor: 'transparent',
  },
  timeLineDotPast: {
    border: `2px solid ${theme.palette.primary[500]}`,
  },
  actionButtons: {
    borderColor: theme.palette.neutral[900],
  },
}));

function OrderTimeLine({data, isCanceled}) {
  const classes = useStyles();
  const {state} = useAppState();

  const [viewingFile, setViewingFile] = useState(null);
  const [fileViewerOpen, setFileViewerOpen] = useState(false);
  const {getLinkButtonProps} = useLinkHandler();

  useEffect(() => {
    const fileId = state.hashPath && state.hashPath[2];
    const item = data.find((el) => el?.file?.id === Number(fileId));
    if (state.hashPath && item?.file) {
      setViewingFile(item.file);
      setFileViewerOpen(true);
    }
  }, [state.hashPath, data]);

  const handleViewFileDialogClose = () => {
    setFileViewerOpen(false);
    window.setTimeout(() => {
      setViewingFile(null);
    }, 300);
  };

  return (
    <div>
      {isCanceled ? '' : <Divider />}
      <div className={classes.timelineTitle}>History</div>
      <Timeline>
        {data.map((el, index) => {
          const isLastItem = data.length - 1 === index;
          return (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot
                  color="secondary"
                  variant={el.type === 'error' ? 'default' : 'outlined'}
                  className={el.is_future ? '' : classes.timeLineDotPast}
                />
                {!isLastItem && <TimelineConnector className={el.is_future ? '' : classes.timeLineConnectorPast} />}
              </TimelineSeparator>
              <TimelineContent>
                <div className={classes.contentWrapper}>
                  <div>
                    <div className={classes.date}>{el.date_label}</div>
                    <div className={`${classes.title} ${el.type === 'error' && classes.error}`}>{el.title}</div>
                    {el.cta_label && (
                      <Button
                        className={classes.actionButtons}
                        variant="outlined"
                        {...getLinkButtonProps({url: el.cta_url, from: 'timeline'})}
                      >
                        {el.cta_label}
                      </Button>
                    )}
                  </div>
                  {el.type === 'inflight' && (
                    <div className={classes.imgWrapper}>
                      <img width="60" height="69" src={highFive} alt="high five" />
                    </div>
                  )}
                </div>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
      <FilePreviewDialog onClose={handleViewFileDialogClose} file={viewingFile} isOpen={fileViewerOpen} />
    </div>
  );
}

export default OrderTimeLine;
