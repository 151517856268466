import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import {ReactComponent as CloseDialogIcon} from '../../../images/closeDialog.svg';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {useSnackbar} from 'notistack';
import EmailTextarea from '../TeamPage/EmailTextarea';
import LoadingButton from '../common/LoadingButton';
import DataController from '../../../lib/controllers/DataController.js';
import {Divider} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: 10,
  },
  inputWrap: {
    padding: 10,
    marginTop: 20,
    fontWeight: '800',
    fontSize: 17,
  },
  footer: {
    backgroundColor: theme.palette.accent.marketMint[100],
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 15,
  },
  cancelBtn: {
    marginRight: 10,
    color: theme.palette.neutral[900],
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 10px 0 10px',
  },
  dialogTitle: {
    fontSize: 34,
    lineHeight: '34px',
    letterSpacing: '-0.45px',
    color: theme.palette.neutral[900],

    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
  },
}));

export default function SendEmails({onClose, cartId, orderId}) {
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);

  const {enqueueSnackbar} = useSnackbar();
  const classes = useStyles();

  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (!emails || emails.length < 1) return;

    setLoading(true);

    DataController.sendInvoice({emails, cartId, orderId}).then(({data, errors, message, success}) => {
      if (success) {
        enqueueSnackbar(message);
        setEmails([]);
        onClose();
      } else {
        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
      setLoading(false);
    });
  };

  return (
    <div>
      <Box className={classes.wrap}>
        <Box className={classes.headerContainer}>
          <div className={classes.dialogTitle}>Send via email</div>
          <IconButton onClick={onClose} size="small" aria-label="close" color="secondary">
            <CloseDialogIcon />
          </IconButton>
        </Box>
        <Box pl={1} pr={2}>
          <Divider />
        </Box>
        <Box>
          <form onSubmit={handleSubmit}>
            <Box className={classes.inputWrap}>
              <div>(Hint: you can add multiple emails at once by separating them with commas.)</div>
              <EmailTextarea items={emails} setItems={setEmails} />
            </Box>
          </form>
        </Box>
      </Box>
      <Box className={classes.footer}>
        <Button className={classes.cancelBtn} variant="text" color="default" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" color="default" type="submit" loading={loading} onClick={handleSubmit}>
          Send
        </LoadingButton>
      </Box>
    </div>
  );
}
