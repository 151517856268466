import React from 'react';
import useAppState from '../../../lib/hooks/useAppState.js';
import AuthFooter from './AuthFooter';
import NonAuthFooter from './NonAuthFooter';

const Footer = () => {
  const {state} = useAppState();
  const authenticated = !!state.token;
  const isGT = window.location.host.includes('groundtruth');
  console.log('isGT', isGT);

  return authenticated ? <AuthFooter isGT={isGT} /> : <NonAuthFooter isGT={isGT} />;
};

export default Footer;
