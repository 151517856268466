import React, {useState} from 'react';
import {CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe} from '@stripe/react-stripe-js';
import FieldGroup from '../form/FieldGroup.js';
import {actions} from '../../../store/store.js';
import useAppState from '../../../lib/hooks/useAppState.js';
import {TextField} from '@material-ui/core';
import useFormControl from '../../../lib/hooks/useFormControl.js';
import Divider from '@material-ui/core/Divider';
import StripeInputWrap from '../form/StripeInputWrap.js';
import {ReactComponent as CardIcon} from 'images/card.svg';
import DataController from '../../../lib/controllers/DataController.js';
import {useHistory} from 'react-router';
import {useSnackbar} from 'notistack';

const defaultForm = {
  cardNickname: '',
};

function PaymentCreateCardForm({
  existingCard,
  successCallback,
  triggerStripeTokenOnly,
  submitButtonProps,
  ignoreChangeIsMade,
}) {
  const history = useHistory();
  const {dispatch} = useAppState();
  const {enqueueSnackbar} = useSnackbar();
  const stripe = useStripe();
  const elements = useElements();
  const [invalid, setInvalid] = useState(true);

  const {getFieldProps, formData, renderSubmitButton, setIsWorking} = useFormControl({
    defaultForm,
    ignoreChangeIsMade,
    loadValues: existingCard,
    SubmitButtonProps: {
      disabled: !stripe || invalid,
      label: 'Save',
      ...submitButtonProps,
    },
  });

  function createPaymentMethod(token) {
    DataController.createPaymentMethod({
      type: 'card',
      token: token.id,
      nickname: formData.cardNickname,
    }).then((res) => {
      setIsWorking(false);
      console.log('[createPaymentMethod]', res);
      if (res && res.success) {
        dispatch({
          type: actions.UPDATE_ROW,
          payload: {
            location: 'paymentMethods.cards',
            data: res.data,
          },
        });
        enqueueSnackbar('Your card has been added.');
        if (successCallback && typeof successCallback === 'function') {
          successCallback(res.data);
        } else {
          history.push(`/account/paymentmethods`);
        }
      } else {
        enqueueSnackbar('Your card could not be added at this time. Please try again.', {
          variant: 'error',
        });
      }
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setIsWorking(true);

    const cardNumberElement = elements.getElement(CardNumberElement);

    const {error, token} = await stripe.createToken(cardNumberElement);

    if (error) {
      console.log('[stripe.createToken error]', error);
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
      setIsWorking(false);
      return;
    }

    console.log('[stripe.createToken success]', token);
    if (typeof successCallback === 'function' && triggerStripeTokenOnly) {
      successCallback(token);
      return;
    }
    createPaymentMethod(token);
  };

  const validate = () => {
    const elements = document.querySelectorAll('.StripeElement');
    for (let i = 0; i < elements.length; ++i) {
      if (
        elements[i].classList.contains('StripeElement--empty') ||
        elements[i].classList.contains('StripeElement--invalid')
      ) {
        setInvalid(true);
        return;
      }
    }
    setInvalid(false);
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <FieldGroup>
        <TextField label="Card nickname" {...getFieldProps('cardNickname')} />
      </FieldGroup>
      <FieldGroup>
        <StripeInputWrap
          label="Card number"
          Component={CardNumberElement}
          endAdornment={<CardIcon />}
          onChangeCallback={validate}
        />
      </FieldGroup>
      <FieldGroup half>
        <StripeInputWrap label="Expiry date" Component={CardExpiryElement} onChangeCallback={validate} />
        <StripeInputWrap label="CVC" Component={CardCvcElement} onChangeCallback={validate} />
      </FieldGroup>
      <Divider />
      {renderSubmitButton}
    </form>
  );
}

export default PaymentCreateCardForm;
