import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FilterTextField from './FilterTextField.js';
import Fade from '@material-ui/core/Fade';
import {makeStyles} from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  title: {
    fontSize: 55,
    lineHeight: '100%',
    whiteSpace: 'nowrap',
    marginBottom: 20,

    [theme.breakpoints.down('sm')]: {
      fontSize: 34,
      letterSpacing: -0.45,
    },
  },
  termsText: {
    fontWeight: 400,
    fontSize: 17,
    color: '#939393',
  },
  rightSide: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 24,
      width: '100%',
      marginLeft: 0,
    },
  },

  xsRow: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
    },

    '& $rightSide': {
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
    },
  },
}));

function AccountSectionHeader({title, rightSide, isLoaded, hasFilter, leftSide, xsRow = false, termsText}) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" className={classNames(classes.root, xsRow ? classes.xsRow : '')}>
      {leftSide}
      <Box ml={0} mr="auto" className={classes.titleWrap}>
        <Typography variant="h2" className={classes.title}>
          <Fade in={isLoaded || typeof isLoaded === 'undefined'}>
            <span>{title}</span>
          </Fade>
        </Typography>
        {termsText && <span className={classes.termsText}>{termsText}</span>}
      </Box>
      {hasFilter || rightSide ? (
        <Box className={classes.rightSide} display="flex" ml={2} mr={0}>
          {hasFilter && <FilterTextField />}
          {rightSide}
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
}

export default AccountSectionHeader;
