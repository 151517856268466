import React from 'react';
import {Box} from '@material-ui/core';
import {actions} from 'store/store.js';
import {formatErrorObj} from 'lib/functions';
import DataController from 'lib/controllers/DataController.js';
import FieldGroup from 'components/elements/form/FieldGroup';
import TextField from '@material-ui/core/TextField';
import useAppState from 'lib/hooks/useAppState.js';
import Button from '@material-ui/core/Button';
import useFormControl from '../../../lib/hooks/useFormControl.js';
import {useSnackbar} from 'notistack';

const defaultForm = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
};

export default function ProfileForm() {
  const {enqueueSnackbar} = useSnackbar();

  const {
    dispatch,
    state: {userData},
  } = useAppState();

  const {getFieldProps, setErrors, formData, renderSubmitButton, setIsWorking} = useFormControl({
    defaultForm,
    loadValues: userData,
    SubmitButtonProps: {
      label: 'Update Profile',
    },
  });

  const handleProfileSubmit = (e) => {
    e.preventDefault();

    setIsWorking(true);
    setErrors({});

    DataController.postProfile(formData).then(({data, errors, message, success}) => {
      if (success) {
        dispatch({
          type: actions.SET_USER_PROFILE,
          payload: data,
        });
        enqueueSnackbar('Profile updated successfully!');
      } else {
        if (Object.keys(errors).length > 0) {
          const errorObject = formatErrorObj(errors);
          setErrors(errorObject);
        } else {
          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      }
      setIsWorking(false);
    });
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleProfileSubmit}>
      <FieldGroup>
        <TextField label="First name" required {...getFieldProps('firstname')} />
        <TextField label="Last name" required {...getFieldProps('lastname')} />
      </FieldGroup>

      <FieldGroup>
        <TextField label="Email" required {...getFieldProps('email')} />
        <TextField label="Phone" {...getFieldProps('phone')} />
      </FieldGroup>

      <Box mt={3}>
        {/* <Button
                    variant="contained"
                    type="submit"
                    disabled={false}
                >
                    Update Profile
                </Button> */}
        {renderSubmitButton}
      </Box>
    </form>
  );
}
