import React, {useMemo} from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

function StepperComponent({fileSteps, ...props}) {
  const activeStep = useMemo(() => {
    const fileStepsReversed = [...fileSteps].reverse();
    const reverseIndex = fileStepsReversed.findIndex((step) => step.is_active);
    return reverseIndex > -1 ? fileStepsReversed.length - reverseIndex - 1 : -1;
  }, [fileSteps]);

  // console.log(activeStep)

  return (
    <Stepper activeStep={activeStep} {...props}>
      {fileSteps.map((step, stepIndex) => {
        const stepProps = {};
        if (step.is_active) stepProps.completed = false;
        if (step.is_active) stepProps.active = true;
        return (
          <Step key={stepIndex} {...stepProps}>
            <StepLabel>{step.title}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

export default StepperComponent;
