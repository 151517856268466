import React, {useState} from 'react';
import DataTable from '../common/DataTable';
import {makeStyles} from '@material-ui/styles';
import useLinkHandler from 'lib/hooks/useLinkHandler';
import useAppState from 'lib/hooks/useAppState';
import MediaTypeIcon from '../common/MediaTypeIcon';
import {Box} from '@material-ui/core';
import classNames from 'classnames';
import FilesMoreMenu from './FilesMoreMenu';
import FilesFavoriteButton from './FilesFavoriteButton';

const useStyles = makeStyles((theme) => ({
  filename: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },

  selectedRow: {
    background: theme.palette.accent.marketMint[100] + ' !important',
  },

  starFavorite: {
    fill: theme.palette.primary.main,
  },

  filesTableWrap: {
    '& table': {
      display: 'block',
      minWidth: '100%',
    },

    '& thead': {
      display: 'block',
      minWidth: '100%',

      '& th:first-child': {
        width: 300,
        maxWidth: 300,
        minWidth: 300,

        [theme.breakpoints.up('tb')]: {
          maxWidth: 400,
          minWidth: 400,
        },
      },

      '& th:nth-child(2)': {
        width: 200,
        maxWidth: 200,
        minWidth: 200,
      },

      '& th:nth-child(3)': {
        minWidth: 240,
        width: '100%',
        maxWidth: '100%',
      },
    },

    '& tbody': {
      display: 'block',
      minWidth: '100%',

      '& td:first-child': {
        width: 300,
        maxWidth: 300,
        minWidth: 300,

        [theme.breakpoints.up('tb')]: {
          maxWidth: 400,
          minWidth: 400,
        },
      },

      '& td:nth-child(2)': {
        width: 200,
        maxWidth: 200,
        minWidth: 200,
      },

      '& td:nth-child(3)': {
        minWidth: 240,
        width: '100%',
        maxWidth: '100%',
      },
    },
  },

  filesTableRow: {
    position: 'relative',
    display: 'block',
    minWidth: '100%',
  },

  actionsCell: {
    position: 'absolute',
    right: 'calc(0px - var(--scrollOffset))',
    top: '50%',
    transform: 'translateY(-50%)',
    borderBottom: 'none',
    padding: 0,
    zIndex: 10,
    height: '100%',
    boxShadow: '-4px 0px 10px rgba(0, 0, 0, 0.06)',
    background: theme.palette.white,
  },
  actionsBox: {
    width: 96,
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  actionButton: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  firstCell: {
    paddingLeft: 16,
    textOverflow: 'ellipsis',
    overflow: 'hidden',

    [theme.breakpoints.up('sm')]: {
      paddingLeft: 24,
    },

    [theme.breakpoints.up('tb')]: {
      paddingLeft: 32,
    },

    '&>*': {
      display: 'inline-block',
      maxWidth: 300,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },

  lastCell: {
    paddingRight: 240,
  },

  smallPadding: {
    paddingTop: 8,
    paddingBottom: 8,
  },
}));

const headings = [
  {
    id: 'filename',
    label: 'Name',
    key: 'filename',
  },
  {
    id: 'media_type',
    label: 'Media Type',
    key: 'media_type',
  },
  {
    id: 'last_used',
    label: 'Last Used',
    key: 'last_used',
  },
  {
    id: 'actions',
    label: '',
    key: 'actions',
  },
];

export default function FilesTable({onRowClick, selectedRow}) {
  const classes = useStyles();
  const {getLinkAnchorProps} = useLinkHandler();
  const {state, dispatch} = useAppState();
  const [scrollOffset, setScrollOffset] = useState(0);

  const renderCell = ({key}, data) => {
    switch (key) {
      case 'filename':
        return (
          <a className={classes.filename} {...getLinkAnchorProps({url: `/file/${data.id}`})}>
            {data.filename}
          </a>
        );
      case 'media_type':
        return <MediaTypeIcon mediaType={data.media_type} iconType={data.media_icon} inline size={28} />;
      case 'actions':
        return (
          <Box className={classes.actionsBox}>
            <FilesFavoriteButton file={data} />
            <FilesMoreMenu file={data} />
          </Box>
        );
      default:
        return data[key] || '-';
    }
  };

  const cellPropsGenerator = (heading, row) => {
    switch (heading.id) {
      case 'filename':
        return {
          className: classes.firstCell,
          'data-redirect-on-click': true,
        };

      case 'actions':
        return {
          className: classes.actionsCell,
          style: {},
        };

      case 'media_type':
        return {
          className: classNames(classes.smallCell, classes.smallPadding),
          'data-redirect-on-click': true,
        };

      case 'last_used':
        return {
          className: classNames(classes.smallCell, classes.lastCell),
          'data-redirect-on-click': true,
        };
    }
  };

  const rowPropsGenerator = (row) => {
    return {
      className: classNames(classes.filesTableRow, selectedRow && row.id === selectedRow.id ? classes.selectedRow : ''),
    };
  };

  const onScroll = (e, containerRef) => {
    setScrollOffset(containerRef.scrollLeft);
  };

  return (
    <Box className={classes.filesTableWrap} style={{'--scrollOffset': `${scrollOffset}px`}}>
      <DataTable
        breakpointPaddings={{
          xsUp: [16, 16],
          smUp: [24, 24],
          tbUp: [24, 0],
          mdUp: [32, 0],
        }}
        cellPropsGenerator={cellPropsGenerator}
        cellRenderer={renderCell}
        headings={headings}
        rows={state.files.data}
        tooManyFilters={state.files?.meta?.is_filtered === true && state.files.data.length === 0}
        onRowClick={onRowClick}
        rowPropsGenerator={rowPropsGenerator}
        selectable
        onScroll={onScroll}
      />
    </Box>
  );
}
