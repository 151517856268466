import React from 'react';
import {useParams} from 'react-router';
import useData from 'lib/hooks/useData';
import {Box} from '@material-ui/core';
import CommonHeader from 'components/elements/common/CommonHeader';
import BackArrow from 'components/elements/common/BackArrow';
import AddressCreateForm from 'components/elements/AddressesPage/AddressCreateForm';

function AddressesCreatePage() {
  const {id} = useParams();
  const state = useData({
    queries: ['getMyAddresses'],
    cbError: () => {
      // TODO: 404
    },
  });

  const address =
    id && state.addresses && state.addresses.addresses && state.addresses.addresses.length > 0
      ? state.addresses.addresses.find((item) => item.id.toString() === id)
      : '';

  return (
    <Box>
      <CommonHeader heading={id ? 'Edit Mailing Address' : 'New Mailing Address'} />

      <BackArrow path="/account/addresses" />

      <AddressCreateForm address={address} />
    </Box>
  );
}

export default AddressesCreatePage;
