import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import Box from '@material-ui/core/Box';
import {Dropdown} from 'components/elements/common';
import useAppState from '../../../lib/hooks/useAppState.js';
import OrdersSearchQuery from './OrdersSearchQuery.js';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '24px 0',

    '& > * + *': {
      [theme.breakpoints.up('sm')]: {
        marginLeft: 16,
      },
    },
  },
  filter: {
    display: 'none',

    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex',
    },
  },
}));

function OrdersFilters({filters, setFilters, isAgency}) {
  const classes = useStyles();
  const {state} = useAppState();

  const handleChange = (key) => (e) => {
    setFilters((prevFilter) => ({
      ...prevFilter,
      prevent: false,
      [key]: e.target.value,
      page: 1,
    }));
  };

  if (!state.orders.isLoaded) return '';
  let options = state.orders.meta.options;
  options = isAgency ? options : options.filter((option) => option.id !== 'company');

  return (
    <Box className={classes.root}>
      {options.map(({id, name, options}) => (
        <Dropdown
          inputId={`filter-${id}`}
          className={classes.filter}
          key={id}
          label={name}
          onChange={handleChange(id)}
          options={options}
          value={filters[id]}
        />
      ))}
      <OrdersSearchQuery />
    </Box>
  );
}

OrdersFilters.propTypes = {};

export default OrdersFilters;
