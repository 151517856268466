import Skeleton from '@material-ui/lab/Skeleton';
import {makeStyles} from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  skel: {
    marginBottom: 5,
    width: (props) => props.width,
    height: (props) => props.height,

    [theme.breakpoints.down('sm')]: {
      width: (props) => props.width * 0.7,
    },
  },
}));

function SkeletonRow({width, height}) {
  const classes = useStyles({
    width,
    height,
  });

  return <Skeleton className={classes.skel} animation="wave" variant="rect" />;
}

export default SkeletonRow;
