import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuBase from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

import {ReactComponent as DownIcon} from 'images/chevron-down-line.svg';
import theme from 'theme/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    '& button': {
      borderWidth: 1,
      borderColor: theme.palette.neutral[400],
      borderStyle: 'solid',
      justifyContent: 'space-between',
      minWidth: 80,
      padding: 8,
      transition: 'background 0.2s ease-in-out',

      '&:hover': {
        textDecoration: 'none',
        background: theme.palette.neutral[200],
      },

      '& svg.down-icon': {
        marginLeft: 6,
      },
    },

    [theme.breakpoints.up('md')]: {
      '& button': {
        minWidth: 120,
        padding: '11px 4px 11px 13px',

        '& svg.down-icon': {
          marginLeft: 6,
        },
      },
    },
  },
  menu: {
    zIndex: '1600 !important',

    '& .MuiPaper-root': {
      transform: 'translate(62px, -4px)',
    },

    '& ul': {
      padding: 0,

      '& li': {
        borderTop: ({border}) => (border ? `1px solid ${theme.palette.common.base}1a` : ''),
      },
    },
  },
  menuIcon: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 -24px 0 12px',
  },
}));

function Menu({
  border,
  children,
  className,
  dropdownClassName,
  itemClassName,
  icon,
  id,
  items = [],
  panel,
  transformOrigin = {vertical: -64, horizontal: 'center'},
  ...rest
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (onClick) => (e) => {
    onClick();
    handleClose();
  };

  const classes = useStyles({border});

  return (
    <Box className={`${classes.root} ${className}`} {...rest}>
      <Button
        aria-controls={id}
        aria-haspopup="true"
        onClick={handleClick}
        style={{background: !!anchorEl ? theme.palette.neutral[200] : ''}}
      >
        {children}
        {!icon && <DownIcon className="down-icon" />}
      </Button>
      <MenuBase
        className={classNames(classes.menu, dropdownClassName)}
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={transformOrigin}
        TransitionComponent={Fade}
      >
        {panel && panel}
        {items.map(({icon: Icon, name, onClick}) => (
          <MenuItem key={name} className={itemClassName} onClick={handleMenuClick(onClick)}>
            {Icon && (
              <Box className={classes.menuIcon}>
                <ListItemIcon>
                  <Icon fontSize="small" />
                </ListItemIcon>
              </Box>
            )}
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </MenuBase>
    </Box>
  );
}

Menu.propTypes = {
  border: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  icon: PropTypes.element,
  id: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.object,
      name: PropTypes.string,
    })
  ),
  panel: PropTypes.element,
  transformOrigin: PropTypes.shape({
    horizontal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    vertical: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default Menu;
