import useLoadScript from '../../../lib/hooks/useLoadScript.js';
import Box from '@material-ui/core/Box';
import React, {useEffect, useState, useRef, useLayoutEffect, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import MagnifyImage from './MagnifyImage.js';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  canvas: {
    width: '100%',
  },
  progress: {
    position: 'absolute',
    zIndex: 10,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  zoomContainer: {
    position: 'absolute',
    zIndex: 10,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
}));

function TrimLine({line}) {
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 2,
        top: `${line.border_offset.top}%`,
        bottom: `${line.border_offset.bottom}%`,
        left: `${line.border_offset.left}%`,
        right: `${line.border_offset.right}%`,
        border: `3px ${line.border_style} ${line.border_color}`,
      }}
    />
  );
}

function PDFViewer({
  className,
  trimlines,
  src,
  pageNumber = 1,
  measurements,
  onWarning,
  onLoad,
  wrapRef,
  size,
  enableZoom = true,
}) {
  const classes = useStyles();
  const canvasEl = useRef(null);
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: '100%',
    height: 'auto',
  });
  const [zoomCount, setZoomCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isHoveringOver, setIsHoveringOver] = useState(false);
  const [isScriptLoaded, isScriptError] = useLoadScript(
    'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.9.359/pdf.min.js'
  );

  const handleLoad = ({width, height}) => {
    if (typeof onLoad === 'function') {
      onLoad({width, height});
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (onWarning) onWarning('');
    setCanvasDimensions({
      width: '100%',
      height: 'auto',
    });
  }, [src]);

  useEffect(async () => {
    if (isScriptLoaded && src) {
      window.pdfjsLib.GlobalWorkerOptions.workerSrc =
        'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.9.359/pdf.worker.min.js';
      const pdfDoc = await window.pdfjsLib.getDocument({url: src}).promise;
      const page = await pdfDoc.getPage(pageNumber);
      const pageOriginalWidth = page.getViewport({scale: 1}).width;
      const scaleRequired = (size === 'large' ? 1920 : canvasEl?.current?.clientWidth) / pageOriginalWidth;
      const updatedPDFViewport = page.getViewport({scale: scaleRequired});
      setCanvasDimensions({
        width: updatedPDFViewport.width,
        height: updatedPDFViewport.height,
      });
      handleLoad({
        width: updatedPDFViewport.width,
        height: updatedPDFViewport.height,
      });
      const renderContext = {
        canvasContext: canvasEl?.current?.getContext('2d'),
        viewport: updatedPDFViewport,
      };
      await page.render(renderContext);

      setIsLoading(false);
    }
  }, [isScriptLoaded, src, pageNumber]);

  useEffect(() => {
    if (isNaN(canvasDimensions.width) || isNaN(canvasDimensions.height)) return;
    const currentFileRatio = canvasDimensions.width / canvasDimensions.height;
    if (
      measurements &&
      measurements.max_ratio &&
      measurements.min_ratio &&
      (currentFileRatio > measurements.max_ratio || currentFileRatio < measurements.min_ratio)
    ) {
      if (onWarning) {
        onWarning(`This document size on this file doesn't match the spec of 
                          ${measurements.width}x${measurements.height} inches. You can upload the file, but this may 
                          cause an error during processing. For more information, please review 
                          <a target="_blank" href="https://www.addy.co/specs">Addy's detailed specs</a> or 
                          <a href="#!" onclick="window.Intercom('show');return false;">get design help</a>.`);
      }
    }
  }, [canvasDimensions]);

  const isHovering = (e) => {
    let isOverPDF = false;
    for (let i = 0; i < e?.path?.length; i++) {
      const pathEl = e.path[i];
      if (pathEl.classList?.contains('js-pdf-wrap')) {
        isOverPDF = true;
        break;
      }
    }

    if (!isOverPDF) return;

    setIsHoveringOver(isOverPDF);
    setZoomCount((count) => count + 1);
  };

  useLayoutEffect(() => {
    document.body.addEventListener('mouseover', isHovering);
    return () => {
      document.body.removeEventListener('mouseover', isHovering);
    };
  }, []);

  const canvasToImage = useMemo(() => canvasEl?.current?.toDataURL('image/png'), [src, isLoading, canvasEl, zoomCount]);

  if (!isScriptLoaded) return 'loading';

  return (
    <Box ref={wrapRef} className={classNames(classes.root, className ? className : '', 'js-pdf-wrap')}>
      {!isLoading && trimlines ? trimlines.map((line, lineIndex) => <TrimLine key={lineIndex} line={line} />) : ''}
      {isLoading ? (
        <Box className={classes.progress}>
          <CircularProgress />
        </Box>
      ) : (
        ''
      )}
      <canvas
        width={canvasDimensions.width}
        height={canvasDimensions.height}
        className={classes.canvas}
        ref={canvasEl}
      />
      {enableZoom && isHoveringOver ? (
        <Box className={classes.zoomContainer}>
          <MagnifyImage width={canvasDimensions.width} height={canvasDimensions.height} src={canvasToImage} />
        </Box>
      ) : (
        ''
      )}
    </Box>
  );
}

export default PDFViewer;
