import {Switch, Route} from 'react-router-dom';
import {useHistory, useLocation} from 'react-router';
import useAppState from 'lib/hooks/useAppState.js';
import accountRoutes from 'lib/accountRoutes.js';

import {Typography, Box, makeStyles} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import {Dropdown, Tabs} from 'components/elements/common';
import NotFoundPage from '../NotFoundPage.js';
import TeamEditPage from '../dashboard/team/TeamEditPage.js';
import TeamInvitePage from '../dashboard/team/TeamInvitePage.js';
import AddressesCreatePage from '../dashboard/addresses/AddressesCreatePage.js';
import PaymentMethodCreateCard from '../dashboard/paymentMethods/PaymentMethodCreateCard.js';
import BillingPage from 'components/pages/dashboard/BillingPage.js';
import ClientsPage from '../dashboard/ClientsPage';
import ClientEditPage from 'components/pages/dashboard/clients/ClientEditPage.js';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: 16,
  },
  dropdownMenu: {
    display: 'block',

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  tabsMenu: {
    display: 'none',
    marginBottom: 24,

    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  tabsPanel: {},
}));

export default function AccountIndex() {
  const history = useHistory();
  const {pathname} = useLocation();
  const {state} = useAppState();
  const classes = useStyles();

  const handleTabClick = (e, index) => {
    e.preventDefault();
    const key = typeof index === 'number' ? index : index.props.value;
    const selectedOpt = accountRoutes.find((item) => item.id === key);
    history.push(selectedOpt.route);
  };

  const activeRoute = accountRoutes.find((item) => item.route === pathname);
  const activeRouteId = activeRoute ? activeRoute.id : -1;

  const dropdownOpts = [];
  for (let i = 0; i < accountRoutes.length; i++) {
    dropdownOpts.push({
      value: accountRoutes[i].id,
      text: accountRoutes[i].label,
    });
  }

  return (
    <>
      {activeRouteId !== -1 ? (
        <>
          <Box className={classes.dropdownMenu} mb={3}>
            <Dropdown onChange={handleTabClick} options={dropdownOpts} value={activeRouteId} />
          </Box>

          <Box className={classes.header}>{activeRoute && activeRoute.header}</Box>

          <Box className={classes.tabsMenu}>
            <Tabs onChange={handleTabClick} value={activeRouteId}>
              {accountRoutes.map((item) => (
                <Tabs.Label
                  key={item.id}
                  label={
                    <>
                      {item.icon}
                      <Typography>{item.label}</Typography>
                    </>
                  }
                />
              ))}
            </Tabs>
          </Box>
        </>
      ) : (
        ''
      )}

      <Fade in={!state.isLoading}>
        <Tabs.Panel value={activeRouteId} index={activeRouteId} className={classes.tabsPanel}>
          <Switch>
            {accountRoutes.map((item) => (
              <Route key={item.id} path={item.route} exact>
                {item.page}
              </Route>
            ))}

            <Route exact path="/account/addresses/create">
              <AddressesCreatePage />
            </Route>
            <Route exact path="/account/addresses/:id/edit">
              <AddressesCreatePage />
            </Route>
            <Route exact path="/account/paymentmethods/create/card">
              <PaymentMethodCreateCard />
            </Route>

            <Route>
              <NotFoundPage />
            </Route>
          </Switch>
        </Tabs.Panel>
      </Fade>
    </>
  );
}
