import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'linear-gradient(180deg, #FFB81E 0%, #FF585A 100%)',
    color: theme.palette.common.offWhite,

    '& .MuiButton-label': {
      textAlign: 'center',
    },
  },
}));

function AccentButton({className, ...rest}) {
  const classes = useStyles();

  return <Button className={`${classes.root} ${className}`} variant="contained" {...rest} />;
}

AccentButton.propTypes = {
  className: PropTypes.string,
};

export default AccentButton;
