import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import useHasScrollbar from '../../../lib/hooks/useHasScrollbar.js';
import React, {useRef, useEffect, useState} from 'react';
import useAppState from 'lib/hooks/useAppState.js';
import actions from 'store/actions.js';
import classNames from 'classnames';
import useMediaQueries from '../../../lib/hooks/useMediaQueries.js';
import {ReactComponent as EmptyIcon} from '../../../images/slash.svg';

const generateEdgeCellPaddings = (theme, cssAttr, tdInitStyles) => (props) => {
  const tdStyles = tdInitStyles ? tdInitStyles : {};
  if (props.breakpointPaddings.xsUp) {
    tdStyles[cssAttr] = props.breakpointPaddings.xsUp;
  }
  if (props.breakpointPaddings.smUp) {
    tdStyles[[theme.breakpoints.up('sm')]] = {
      [cssAttr]: props.breakpointPaddings.smUp,
    };
  }
  if (props.breakpointPaddings.mdUp) {
    tdStyles[[theme.breakpoints.up('md')]] = {
      [cssAttr]: props.breakpointPaddings.mdUp,
    };
  }
  return tdStyles;
};

const calcBreakpointMargins = (breakpointPaddings) => {
  const styles = {};

  if (Array.isArray(breakpointPaddings)) {
    styles.marginLeft = breakpointPaddings[0] * -1;
    styles.marginRight = breakpointPaddings[1] * -1;
  } else {
    styles.marginLeft = breakpointPaddings * -1;
    styles.marginRight = breakpointPaddings * -1;
  }

  return styles;
};

const useStyles = makeStyles((theme) => ({
  root: (props) => {
    let rootStyles;
    if (props) {
      if (props.breakpointPaddings.xsUp) {
        rootStyles = calcBreakpointMargins(props.breakpointPaddings.xsUp);
      }
      if (props.breakpointPaddings.smUp) {
        rootStyles[[theme.breakpoints.up('sm')]] = calcBreakpointMargins(props.breakpointPaddings.smUp);
      }
      if (props.breakpointPaddings.tbUp) {
        rootStyles[[theme.breakpoints.up('tb')]] = calcBreakpointMargins(props.breakpointPaddings.tbUp);
      }
      if (props.breakpointPaddings.mdUp) {
        rootStyles[[theme.breakpoints.up('md')]] = calcBreakpointMargins(props.breakpointPaddings.mdUp);
      }
    }
    return {
      position: 'relative',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      ...rootStyles,
    };
  },
  rootScrollable: {
    '&::after': {
      content: '""',
      width: 50,
      height: '100%',
      position: 'absolute',
      right: -50,
      top: 0,
      background: 'blue',
      boxShadow: `-10px 0px 29px 20px #fff`,
    },
  },
  lastCellScrollable: {
    paddingRight: 30,
  },
  accentedRow: {
    animationName: 'newTableRow',
    animationDuration: '1s',
    animationRepeat: 1,
  },
  firstCell: generateEdgeCellPaddings(theme, 'paddingLeft'),
  lastCell: generateEdgeCellPaddings(theme, 'paddingRight', {textAlign: 'right'}),

  noItemsFound: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.neutral[800],

    '& svg': {
      marginRight: 10,
    },
  },

  selectableRow: {
    '&:hover': {
      backgroundColor: theme.palette.neutral[100],
    },
  },
}));

function DataTable({
  headings,
  rows = [],
  cellRenderer,
  cellPropsGenerator,
  rowPropsGenerator,
  filterFunction,
  breakpointPaddings = {},
  onRowClick,
  hideTableHeadInSm,
  hideTableHead,
  tooManyFilters,
  selectable = false,
  onScroll,
}) {
  const classes = useStyles({breakpointPaddings});
  const containerRef = useRef(null);
  const {dispatch} = useAppState();
  const mediaQueries = useMediaQueries({
    sm: true,
    tb: true,
    md: true,
    lg: true,
    up: true,
  });
  const [responsiveHeadings, setResponsiveHeadings] = useState(headings);

  // responsive headings
  useEffect(() => {
    if (!headings || headings.length === 0) return;
    setResponsiveHeadings(headings.filter((item) => !item.breakpoint || mediaQueries[item.breakpoint].up));
  }, [mediaQueries.sm.up, mediaQueries.tb.up, mediaQueries.md.up, mediaQueries.lg.up, headings]);

  // handle accented rows
  useEffect(() => {
    const accentedRows = rows.filter((row) => row.is_table_accented && row.is_table_accented.state);

    accentedRows.forEach((row) => {
      setTimeout(() => {
        dispatch({
          type: actions.UPDATE_ROW,
          payload: {
            location: row.is_table_accented.location,
            unsetAccentId: row.id,
          },
        });
      }, 1000);
    });
  }, [rows]);

  const filteredRows = filterFunction ? filterFunction(rows) : rows;
  const hasScrollbar = useHasScrollbar(containerRef);

  const tooManyFiltersCondition = tooManyFilters || (rows.length > 0 && filteredRows && filteredRows.length === 0);

  return (
    <Box className={classNames(classes.root, hasScrollbar ? classes.rootScrollable : '')}>
      {/* If there is data, but no item matches filters */}
      {tooManyFiltersCondition ? (
        <Box ml={4} mb={6} className={classes.noItemsFound}>
          <EmptyIcon />
          <Box>no item matches this filter</Box>
        </Box>
      ) : (
        <TableContainer ref={containerRef} onScroll={onScroll ? (e) => onScroll(e, containerRef.current) : undefined}>
          <Table>
            {!hideTableHead && (!hideTableHeadInSm || mediaQueries.sm.up) ? (
              <TableHead>
                <TableRow>
                  {responsiveHeadings.map((heading, headingIndex) => {
                    let cellProps = {};
                    if (cellPropsGenerator) cellProps = {...cellPropsGenerator(heading)};
                    cellProps.className = classNames(
                      cellProps.className ? cellProps.className : '',
                      headingIndex === responsiveHeadings.length - 1 && hasScrollbar ? classes.lastCellScrollable : '',
                      headingIndex === responsiveHeadings.length - 1 ? classes.lastCell : '',
                      headingIndex === 0 ? classes.firstCell : ''
                    );
                    return (
                      <TableCell key={headingIndex} {...cellProps}>
                        {heading.label || ''}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            ) : (
              <></>
            )}
            <TableBody>
              {filteredRows &&
                filteredRows.length > 0 &&
                filteredRows.map((row, rowIndex) => {
                  let rowProps = {};
                  if (rowPropsGenerator) rowProps = {...rowPropsGenerator(row)};
                  rowProps.key = rowIndex;
                  rowProps.className = classNames(
                    rowProps.className ? rowProps.className : '',
                    row.is_table_accented ? classes.accentedRow : '',
                    selectable ? classes.selectableRow : ''
                  );
                  if (onRowClick) rowProps.onClick = (e) => onRowClick(e, row);
                  return (
                    <TableRow {...rowProps}>
                      {responsiveHeadings.map((heading, headingIndex) => {
                        let cellProps = {};
                        if (cellPropsGenerator) cellProps = {...cellPropsGenerator(heading, row)};
                        cellProps.className = classNames(
                          cellProps.className ? cellProps.className : '',
                          headingIndex === responsiveHeadings.length - 1 && hasScrollbar
                            ? classes.lastCellScrollable
                            : '',
                          headingIndex === responsiveHeadings.length - 1 ? classes.lastCell : '',
                          headingIndex === 0 ? classes.firstCell : ''
                        );
                        return (
                          <TableCell key={`${rowIndex}-${headingIndex}`} {...cellProps}>
                            {cellRenderer(heading, row)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}

DataTable.propTypes = {
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      breakpoint: PropTypes.oneOf(['sm', 'md', 'tb', 'lg']),
    })
  ),
  rows: PropTypes.array,
  filterFunction: PropTypes.func,
  cellRenderer: PropTypes.func.isRequired,
  cellPropsGenerator: PropTypes.func,
  rowPropsGenerator: PropTypes.func,
  onRowClick: PropTypes.func,
  breakpointPaddings: PropTypes.shape({
    xsUp: PropTypes.number | PropTypes.array,
    smUp: PropTypes.number | PropTypes.array,
    mdUp: PropTypes.number | PropTypes.array,
  }),
  hideTableHeadInSm: PropTypes.bool,
  tooManyFilters: PropTypes.bool,
  selectable: PropTypes.bool,
};

DataTable.defaultProps = {
  tooManyFilters: false,
  selectable: false,
};

export default DataTable;
