import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import algoliasearch from 'algoliasearch/lite';
import {InstantSearch, connectHits, connectSearchBox, Configure} from 'react-instantsearch-dom';
import {ReactComponent as SearchIcon} from 'images/search.svg';
import {ReactComponent as TvIcon} from 'images/tv.svg';
import {ReactComponent as WifiIcon} from 'images/wifi.svg';
import {ReactComponent as LocationIcon} from 'images/map-marker.svg';
import {ReactComponent as BookIcon} from 'images/book-open.svg';
import {ReactComponent as SubwayIcon} from 'images/subway.svg';
import {ReactComponent as DefaultIcon} from 'images/file.svg';
import dooh from 'images/digital-out-of-home-purple.png';
import theme from 'theme/theme';
import {ReactComponent as CloseDialogIcon} from '../../../images/closeDialog.svg';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import {wwwUrl} from 'lib/config.js';

const searchClient = algoliasearch('4WM82PMLZE', 'e5830f67e609ddc605cafe6be285fa9c');

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  modal: {},
  paper: {
    background: theme.palette.accent.publicPink[400],
    borderRadius: '0 0 0 10px',
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.2)',
    maxHeight: '90vh',
    maxWidth: '100vw',
    left: 'unset',
    overflow: 'hidden',
    width: 493,

    '& > .MuiBox-root': {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    '& .search-box': {
      maxHeight: 310,
      minHeight: 310,
      padding: 16,
    },
    '& .search-results': {
      background: theme.palette.common.white,
      maxHeight: 'calc(90vh - 310px)',
      padding: '0 16px',
    },
    '& .search-results-hits': {
      display: 'block',
      overflow: 'auto',
      width: '100%',

      '& > *': {
        borderRadius: 10,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',

        '&:hover': {
          background: theme.palette.primary.main,
        },
      },
    },
    '& h6': {
      fontSize: 18,
      fontWeight: 700,
      marginBottom: 8,
    },
    '& p': {
      color: theme.palette.neutral[800],
      fontSize: 16,
      marginBottom: 8,
    },
  },
  searchDivider: {
    background: theme.palette.accent.customerCoral[400],
    display: 'block',
    height: '3px',
    margin: '16px 0 8px',
    width: '100%',
  },
  resultType: {
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    flex: '0 0 25px',
    justifyContent: 'center',
    height: 25,
    marginRight: 8,
    width: 25,
  },
  resultText: {
    lineHeight: 1.6,

    '& > *': {
      fontSize: 16,
      fontWeight: 800,
    },
  },
  clearnBtn: {
    opacity: 0,
    transition: 'opacity 150ms ease',
  },
  clearnBtnFilled: {
    opacity: 1,
  },
  searchForm: {
    '& .MuiOutlinedInput-notchedOutline': {
      top: 0,
    },

    '& .MuiOutlinedInput-root': {
      marginTop: 0,
    },
  },
}));

const Hits = ({hits}) => {
  const classes = useStyles();

  const iconMap = {
    location: {
      background: theme.palette.primary[500],
      icon: <LocationIcon style={{width: 11}} />,
    },
    digital: {
      background: theme.palette.accent.subscriberSunshine[500],
      icon: <WifiIcon style={{width: 16}} />,
    },
    printmag: {
      background: theme.palette.accent.buyerBlue[500],
      icon: <BookIcon style={{width: 13}} />,
    },
    tv: {
      background: theme.palette.accent.marketMint[500],
      icon: <TvIcon style={{width: 13}} />,
    },
    printooh: {
      background: theme.palette.accent.marketMint[500],
      icon: <TvIcon style={{width: 13}} />,
    },
    eddm: {
      background: theme.palette.accent.customerCoral[400],
      icon: <SubwayIcon style={{width: 12}} />,
    },
    dooh: {
      background: theme.palette.accent.buyerBlue[500],
      image: <img style={{width: 20}} src={dooh} alt="dooh" />,
    },
    default: {
      background: theme.palette.accent.publicPink[500],
      icon: <DefaultIcon style={{width: 10}} />,
    },
  };

  const handleHitClick = (route) => (e) => {
    window.location.href = wwwUrl + `${route}`;
  };

  return (
    <>
      <Box m={1}>
        <Typography variant="h6">{hits.length} results</Typography>
      </Box>
      <Box className="search-results-hits">
        {hits.map(({text, type, url, _highlightResult}, i) => (
          <Box key={i} p={2} onClick={handleHitClick(url)}>
            <Box
              className={classes.resultType}
              style={{
                background: iconMap[type]?.background || iconMap.default.background,
              }}
            >
              {iconMap[type]?.icon || iconMap[type]?.image || iconMap.default.icon}
            </Box>
            <Box
              className={classes.resultText}
              dangerouslySetInnerHTML={{__html: _highlightResult?.text?.value || text}}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};

const CustomHits = connectHits(Hits);

const SearchBox = ({currentRefinement, onChange, refine}) => {
  const classes = useStyles();

  const handleOnChange = (event) => {
    const value = event.currentTarget.value;

    onChange(value);
    refine(value);
  };

  const handleClear = (e) => {
    onChange('');
    refine('');
  };

  return (
    <form noValidate action="" role="search" className={classes.searchForm}>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          placeholder: 'Search',
          endAdornment: (
            <IconButton
              className={classNames(classes.clearnBtn, currentRefinement ? classes.clearnBtnFilled : '')}
              onClick={handleClear}
              size="small"
              aria-label="close"
              color="secondary"
            >
              <CloseDialogIcon />
            </IconButton>
          ),
        }}
        onChange={handleOnChange}
        type="text"
        value={currentRefinement}
      />
    </form>
  );
};

const CustomSearchBox = connectSearchBox(SearchBox);

function Search(props) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const classes = useStyles();

  const toggleDrawer = () => {
    setOpen((open) => !open);

    if (!open) {
      setSearch('');
    }
  };

  const handleSearch = (search) => setSearch(search);

  return (
    <>
      <Box {...props} className={classes.root}>
        <Button onClick={toggleDrawer}>
          <SearchIcon />
        </Button>
      </Box>
      <Drawer
        anchor="top"
        BackdropProps={{invisible: true}}
        className={classes.drawer}
        ModalProps={{className: classes.modal}}
        open={open}
        onClose={toggleDrawer}
        PaperProps={{className: classes.paper}}
      >
        <InstantSearch indexName="dev_addy" searchClient={searchClient}>
          <Box className="search-box">
            <Typography variant="h5">Search Addy</Typography>

            <Box className={classes.searchDivider} />

            <Typography variant="h6">Where do you want to advertise?</Typography>
            <Typography>Search for titles (eg. Architectural Digest) or places (eg. Chicago).</Typography>

            <Configure hitsPerPage={20} />

            <CustomSearchBox autoFocus={true} onChange={handleSearch} />
          </Box>

          <Box className="search-results">{!!search && <CustomHits />}</Box>
        </InstantSearch>
      </Drawer>
    </>
  );
}

export default Search;
