import React from 'react';
import {Box, Button, TextField, Divider, makeStyles} from '@material-ui/core';
import {actions} from 'store/store.js';
import {formatErrorObj} from 'lib/functions';
import DataController from 'lib/controllers/DataController.js';
import FieldGroup from 'components/elements/form/FieldGroup';
import useAppState from '../../../lib/hooks/useAppState.js';
import useFormControl from '../../../lib/hooks/useFormControl.js';
import {useHistory} from 'react-router';
import {useSnackbar} from 'notistack';

const useStyles = makeStyles((theme) => ({
  upperWrap: {
    maxWidth: 792,
  },
  lowerWrap: {
    maxWidth: 384,
  },
}));

const defaultForm = {
  addressee: '',
  attention: '',
  street: '',
  street2: '',

  city: '',
  state: '',
  zip: '',
  country: '',

  id: '',
};

export default function AddressCreateForm({address, onAddressCreated}) {
  const classes = useStyles();
  const {dispatch} = useAppState();
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();

  const {getFieldProps, setErrors, formData, renderSubmitButton, setIsWorking} = useFormControl({
    defaultForm,
    loadValues: address,
    SubmitButtonProps: {
      label: 'Save',
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    let request;

    setIsWorking(true);
    setErrors({});

    if (!!address) {
      request = DataController.updateAddress(formData);
    } else {
      request = DataController.createAddress(formData);
    }

    request.then(({data, errors, message, success}) => {
      if (success) {
        dispatch({
          type: actions.UPDATE_ROW,
          payload: {
            location: 'addresses.addresses',
            data: data,
          },
        });
        if (!!address) {
          enqueueSnackbar('Address updated successfully.');
        }
        if (!address && !onAddressCreated) {
          history.push('/account/addresses');
        }
        if (onAddressCreated && typeof onAddressCreated === 'function') {
          onAddressCreated(data);
        }
      } else {
        if (Object.keys(errors).length > 0) {
          const errorObject = formatErrorObj(errors);
          setErrors(errorObject);
        } else {
          enqueueSnackbar(message, {
            variant: 'error',
          });
        }
      }
      setIsWorking(false);
    });
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Box className={classes.upperWrap}>
        <FieldGroup>
          <TextField label="Address name" required {...getFieldProps('addressee')} />
          <TextField label="Attention" required {...getFieldProps('attention')} />
        </FieldGroup>
      </Box>

      <Divider />

      <Box className={classes.lowerWrap}>
        <FieldGroup>
          <TextField label="Address" required {...getFieldProps('street')} />
        </FieldGroup>
        <FieldGroup>
          <TextField label="Address, line 2" {...getFieldProps('street2')} />
        </FieldGroup>
        <FieldGroup>
          <TextField label="City" required {...getFieldProps('city')} />
          <TextField label="State/Province" required {...getFieldProps('state')} />
        </FieldGroup>
        <FieldGroup>
          <TextField label="Zip code" required {...getFieldProps('zip')} />
        </FieldGroup>
        <FieldGroup>
          <TextField label="Country" {...getFieldProps('country')} />
        </FieldGroup>
      </Box>

      <Divider />

      <Box mt={3}>{renderSubmitButton}</Box>
    </form>
  );
}
