import Stepper from '../common/Stepper.js';
import OrderFileUploader from './OrderFileUploader.js';
import {Box, makeStyles, Divider, TextField} from '@material-ui/core';
import ProgressButton from '../form/ProgressButton.js';
import useFormControl from '../../../lib/hooks/useFormControl.js';
import React, {useMemo, useState, useRef, useEffect} from 'react';
import {validateRequired, validateURL} from '../../../lib/validators.js';
import S3UploadController from '../../../lib/controllers/S3UploadController.js';
import {useSnackbar} from 'notistack';
import DataController from '../../../lib/controllers/DataController.js';
import OrderDuplicateAdDialog from './OrderDuplicateAdDialog.js';
import OrderSubmitSuccess from './OrderSubmitSuccess.js';
import {actions} from '../../../store/store.js';
import useAppState from '../../../lib/hooks/useAppState.js';
import OrderNotes from './OrderNotes.js';
import OrderMainAlert from './OrderMainAlert.js';
import OrderViewURL from './OrderViewURL.js';
import OrderDesignService from './OrderDesignService.js';
import Confetti from '../common/Confetti.js';
import {scrollTo} from '../../../lib/functions.js';
import {ReactComponent as ExternalLink} from '../../../images/externalLink.svg';
import useLinkHandler from '../../../lib/hooks/useLinkHandler';
import useIntersection from '../../../lib/hooks/useIntersection';

const useStyles = makeStyles((theme) => ({
  root: {},
  stepper: {
    paddingTop: 8,

    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  comment: {},
  multipleDivider: {
    marginTop: 0,
    marginBottom: 48,
    backgroundColor: theme.palette.neutral[700],
    opacity: 1,
  },
  urlDivider: {
    backgroundColor: theme.palette.neutral[700],
    opacity: 1,
    marginTop: 24,
    marginBottom: 48,
  },
  urlTitle: {
    fontSize: 20,
    lineHeight: '25px',
    color: theme.palette.neutral[900],
    marginBottom: 24,
  },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    paddingBottom: 25,
    paddingRight: 40,

    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      justifyContent: 'center',
    },
  },
  link: {
    marginLeft: 10,
    cursor: 'pointer',
    fontSize: 17,
    lineHeight: '24px',
  },
  confirmWrapper: {
    position: 'fixed',
    bottom: 20,
    width: 232,
    zIndex: 1000,
    transition: 'bottom 0.5s',
    left: '39%',
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.down(1700)]: {
      left: '35%',
    },

    [theme.breakpoints.down(1200)]: {
      left: '25%',
    },

    [theme.breakpoints.down(769)]: {
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
}));

function OrderFileSpecs({order, duplicate}) {
  const classes = useStyles();
  const {dispatch} = useAppState();
  const [progress, setProgress] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const [hasFileChanged, setHasFileChanged] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [dupeAdDialog, setDupeAdDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [successDialogMsg, setSuccessDialogMsg] = useState(
    'Thank you! We’ve received your file(s). <br> <br> You’ll receive an email shortly confirming approval and/or any issues that may need to be addressed.'
  );
  const [dupeAdDialogData, setDupeAdDialogData] = useState([]);
  const {enqueueSnackbar} = useSnackbar();
  const confetti = useRef();
  const {getLinkButtonProps} = useLinkHandler();
  const isPendingFiles = order.is_pending_files;
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [dupeAdSubmitting, setDupeAdSubmitting] = useState(false);

  const hasURL = !!(order.filespecs[0] && order.filespecs[0].requires_url);

  const defaultForm = {
    comment: '',
  };
  if (hasURL) defaultForm.url = order?.filenotes?.campaign_url || '';
  for (let i = 0; i < order.filespecs.length; i++) {
    defaultForm[`filespec${order.filespecs[i].id}`] = order.filespecs[i].file || null;
  }

  const {getFieldProps, handleInputChange, setFieldError, setWarnings, errors, warnings, formData} = useFormControl({
    defaultForm,
    onChangeGlobal: () => {
      setIsDirty(true);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('submit', formData);

    const errorCallback = (message) => {
      setProgress(0);
      setIsSubmitted(false);

      if (message)
        enqueueSnackbar(message, {
          variant: 'error',
        });
    };

    // try {

    setProgress(0);
    setIsSubmitted(true);

    // let isS3Loaded = false;

    const JSONData = {
      notes: formData.comment,
      files: [],
    };

    const totalFiles = Object.keys(formData).filter((key) => key.startsWith('filespec')).length;

    if (formData.url) JSONData.url = formData.url;

    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (key.startsWith('filespec')) {
          const filespec_id = key.split('filespec')[1];

          if (formData[key].id) {
            JSONData.files.push({
              filespec_id,
              file_id: formData[key].id,
            });

            setProgress((val) => val + 100 / totalFiles);
          } else {
            // await S3UploadController.init();

            const beforeStartProgress = progress;

            // const fileUpload = await S3UploadController.uploadFile({
            //     file: formData[key],
            //     path: order.id,
            //     onProgress: perc => {
            //         console.warn(perc);
            //         setProgress(val => beforeStartProgress + ((100 / totalFiles) * (perc / 100)));
            //     }
            // });

            const fileUploadLocation = await S3UploadController.putUploadFile({
              file: formData[key],
              path: order.id,
              onProgress: (perc) => {
                console.warn(perc);
                setProgress((val) => beforeStartProgress + (100 / totalFiles) * (perc / 100));
              },
            });

            JSONData.files.push({
              filespec_id,
              url: fileUploadLocation,
            });
          }
        }
      }
    }

    console.warn(JSONData);

    const res = await DataController.postAssociateFiles(order.id, JSONData);

    if (!res.success) {
      errorCallback(res.message);
      return;
    }

    console.warn('res', res);

    // update order
    if (res.data) {
      dispatch({
        type: actions.UPDATE_ROW,
        payload: {
          location: 'orders.current',
          data: res.data,
        },
      });
    }

    // show duplicate dialog with congrats message
    setSuccessDialogMsg(res.message);
    doDuplicate();

    // reset
    setProgress(0);
    setIsSubmitted(false);
    setHasFileChanged(false);

    // } catch (e) {
    //     errorCallback('There was an error submitting your ad.');
    // }
  };

  const doDuplicate = async (e) => {
    // look for ads to duplicate to
    const filesPickup = await DataController.getFilesPickup(order.id);

    if (filesPickup.success) {
      if (filesPickup.data && filesPickup.data.length > 0) {
        setDupeAdDialogData(filesPickup.data);
        setDupeAdDialog(true);
      }

      if (filesPickup.data.length === 0) {
        setDupeAdDialog(false);
        setSuccessDialog(true);
      }
    }
  };

  useEffect(() => {
    // launch duplicate dialog if param is passed in the route
    if (duplicate) {
      doDuplicate();
    }
  }, []);

  const handleFileSelect = (spec, file) => {
    setHasFileChanged(true);
    setFieldError(`filespec${spec.id}`);
    handleInputChange({target: {value: file}}, `filespec${spec.id}`);
  };

  const handleClearFile = (spec) => {
    setFieldError(`filespec${spec.id}`);
    handleInputChange({target: {value: null}}, `filespec${spec.id}`);
  };

  const handleStartOver = (spec) => {
    const newSpecs = [...order.filespecs];
    for (let i = 0; i < newSpecs.length; i++) {
      if (newSpecs[i].id === spec.id) {
        newSpecs[i].file = null;
        newSpecs[i].is_error = false;
      }
    }
    dispatch({
      type: actions.UPDATE_ROW,
      payload: {
        location: 'orders.current',
        data: {
          file_message: null,
          is_editable: true,
          is_editable_files: true,
          filespecs: newSpecs,
        },
      },
    });
    setFieldError(`filespec${spec.id}`);
    handleInputChange({target: {value: null}}, `filespec${spec.id}`);
    // setErrors({});
    setWarnings({});
  };

  const handleFileError = (spec, error, file) => {
    setFieldError(`filespec${spec.id}`, error);
    handleInputChange({target: {value: ''}}, `filespec${spec.id}`);
  };

  const handleFileWarning = (spec, warning) => {
    setFieldError(`filespec${spec.id}`, warning, 'warning');
  };

  const handleDupeAdClose = () => {
    setDupeAdDialog(false);

    setSuccessDialog(true);
    setTimeout(() => {
      confetti.current.playConfetti();
    }, 200);

    scrollTo(0, 250);
    window.setTimeout(() => {
      setDupeAdDialogData([]);
    }, 300);
  };

  const handleCloseSuccess = () => {
    setSuccessDialog(false);
    scrollTo(0, 250);
  };

  const isFormDataValid = useMemo(() => {
    let isValid = true;
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (key !== 'comment' && !formData[key]) {
          isValid = false;
          break;
        } else if (errors[key]) {
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  }, [formData]);

  const handleSubmitUpdateOrders = (e) => {
    setDupeAdSubmitting(true);
    DataController.postFilesPickup(order.id, selected).then((res) => {
      handleDupeAdClose();
      setDupeAdSubmitting(false);
      setDupeAdDialog(false);
      setSuccessDialog(true);
      setSuccessDialogMsg(res.message);
      setTimeout(() => {
        confetti.current.playConfetti();
      }, 200);
    });
  };

  // if file is set and
  // if file has changed, show comment and url as editable fields
  const isURLSet = !!order?.filenotes?.campaign_url;
  const isNoteSet = !!order?.filenotes?.note;
  // hasFileChanged

  const ref = document.getElementById('footer');
  const inViewport = useIntersection(ref, '-50px');
  const el = document.getElementById('confirmAdButton');
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

  if (el) {
    let bottomWidth = '20px';
    if (inViewport) {
      bottomWidth = vw <= 769 ? '300px' : '180px';
    }
    document.getElementById('confirmAdButton').style.bottom = bottomWidth;
  }

  return (
    <>
      <Box className={classes.root}>
        <Stepper id="upload_area" className={classes.stepper} fileSteps={order.file_steps} />
        <div className={classes.linkWrapper}>
          <ExternalLink />
          <a className={classes.link} {...getLinkButtonProps({url: order?.media_specs.cta_url})}>
            {order?.media_specs.cta_label}
          </a>
        </div>
        {order.has_design_service ? (
          <OrderDesignService url={order.design_service_url} />
        ) : (
          <>
            <OrderMainAlert order={order} />
            {order.filespecs.map((spec, specIndex) => (
              <OrderFileUploader
                orderId={order.id}
                value={formData[`filespec${spec.id}`]}
                error={errors[`filespec${spec.id}`] || warnings[`filespec${spec.id}`]}
                onSelect={handleFileSelect}
                onClear={handleClearFile}
                onStartOver={handleStartOver}
                onError={handleFileError}
                onWarning={handleFileWarning}
                key={spec.id}
                index={specIndex + 1}
                length={order.filespecs.length}
                hasMultiple={order.filespecs.length > 1}
                isEditableFiles={order.is_editable_files}
                spec={spec}
                isPendingFiles={isPendingFiles}
              />
            ))}
            {order.filespecs.length > 1 ? <Divider className={classes.multipleDivider} /> : ''}
            {!isPendingFiles && (
              <>
                <Box my={3}>
                  {isURLSet || (isNoteSet && !hasFileChanged) ? (
                    <>
                      <OrderViewURL order={order} />
                      <OrderNotes order={order} />
                    </>
                  ) : (
                    <>
                      {hasURL ? (
                        <Box>
                          <Box className={classes.urlTitle}>Campaign URL</Box>
                          <TextField
                            className={classes.comment}
                            placeholder="https://"
                            {...getFieldProps('url', false, null, [validateRequired, validateURL])}
                          />
                          <Divider className={classes.urlDivider} />
                        </Box>
                      ) : (
                        ''
                      )}
                      <TextField
                        className={classes.comment}
                        placeholder="Leave a comment"
                        multiline
                        rows={3}
                        {...getFieldProps('comment')}
                      />
                    </>
                  )}
                </Box>
                {order.is_editable || order.is_editable_files ? (
                  <div>
                    <ProgressButton
                      progress={progress}
                      className={classes.confirmWrapper}
                      id="confirmAdButton"
                      variant="contained"
                      disabled={!isFormDataValid || isSubmitted || !isDirty || !hasFileChanged}
                      fullWidth
                      isLoaderBlack
                      onClick={handleSubmit}
                    >
                      Confirm and Upload Ad
                    </ProgressButton>
                  </div>
                ) : (
                  ''
                )}
              </>
            )}
            <Divider />
          </>
        )}
      </Box>

      <OrderDuplicateAdDialog
        onClose={handleDupeAdClose}
        orderId={order.id}
        selected={selected}
        setSelected={setSelected}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        handleSubmit={handleSubmitUpdateOrders}
        data={dupeAdDialogData}
        isOpen={dupeAdDialog}
        submitting={dupeAdSubmitting}
      />

      <OrderSubmitSuccess onClose={handleCloseSuccess} message={successDialogMsg} isOpen={successDialog} />

      <Confetti ref={confetti} />
    </>
  );
}

export default OrderFileSpecs;
