import React, {useState, useEffect, useRef} from 'react';
import {Box, makeStyles, List, ListItem, ListItemIcon, ListItemText, Typography} from '@material-ui/core';
import {ReactComponent as CheckmarkIcon} from 'images/checkmark.svg';
import useData from 'lib/hooks/useData';
import useAppState from 'lib/hooks/useAppState';
import {actions} from 'store/store.js';
import {Divider, Button} from '@material-ui/core';
import DataController from 'lib/controllers/DataController';
import {useSnackbar} from 'notistack';
import LoadingButton from '../common/LoadingButton';

const useStyles = makeStyles((theme) => ({
  listWrapper: {
    border: `1px solid ${theme.palette.neutral[500]}`,
    borderRadius: 4,
    width: 500,
    maxWidth: '100%',
    height: 400,
    overflow: 'hidden',
    color: theme.palette.neutral[800],
    display: 'flex',
    flexDirection: 'column',
  },

  listInner: {
    overflowY: 'scroll',
    flexGrow: 1,
  },

  list: {
    padding: 0,

    '& .MuiListItem-root': {
      borderBottom: `1px solid ${theme.palette.neutral[300]}`,

      '&:last-of-type': {
        marginBottom: 50,
      },

      '&.Mui-selected': {
        background: theme.palette.neutral[200],

        '&:hover': {
          background: theme.palette.neutral[300],
        },
      },
    },

    '& .MuiListItemIcon-root': {
      justifyContent: 'flex-end',
    },
  },

  cancelBtn: {
    marginRight: 16,
  },
}));

export default function AssignTeamMembersList({client, handleClose}) {
  const classes = useStyles();
  const {dispatch, state} = useAppState();
  const {enqueueSnackbar} = useSnackbar();
  const [assignedMembers, setAssignedMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const listInner = useRef(null);

  useData({
    queries: ['getMyTeam'],
    setLoadingState: true,
  });

  useEffect(() => {
    setAssignedMembers(client && client.team ? client.team.map((teamMember) => teamMember.id) : []);
    setSelectedMembers(client && client.team ? client.team.map((teamMember) => teamMember.id) : []);
  }, [client]);

  const toggleClient = (teamMemberId) => {
    const currentIndex = selectedMembers.indexOf(teamMemberId);
    const newChecked = [...selectedMembers];

    if (currentIndex === -1) {
      newChecked.push(teamMemberId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedMembers(newChecked);
  };

  const handleAssignTeam = async (e) => {
    e.preventDefault();
    setLoading(true);

    const toRemove = assignedMembers.filter((id) => !selectedMembers.includes(id));
    const toAssign = selectedMembers.filter((id) => !assignedMembers.includes(id));

    let finalClientData;
    let error = false;

    if (toAssign.length > 0 && !error) {
      let memberIds = toAssign.join(',');
      const {data, errors, message, success} = await DataController.addMembersToClient({
        companyId: client.id,
        memberIds,
      });

      if (success) {
        finalClientData = data;
      } else {
        error = message;
      }
    }

    if (toRemove.length > 0 && !error) {
      let memberIds = toRemove.join(',');
      const {data, errors, message, success} = await DataController.removeMembersFromClient({
        companyId: client.id,
        memberIds,
      });

      if (success) {
        finalClientData = data;
      } else {
        error = message;
      }
    }

    if (error) {
      enqueueSnackbar(error, {
        variant: 'error',
      });
    } else if (toRemove.length === 0 && toAssign.length === 0) {
      enqueueSnackbar('Nothing to update!');
      setLoading(false);
      handleClose();
    } else {
      enqueueSnackbar('Team members updated successfully!');
      setLoading(false);
      handleClose();

      dispatch({
        type: actions.SET_CURRENT_CLIENT,
        payload: finalClientData,
      });

      // re-fetch my team to get single member changes
      dispatch({
        type: actions.CLEAR_DATA,
        payload: {
          action: actions.SET_MY_TEAM,
        },
      });
    }
  };

  return (
    <>
      <Box className={classes.listWrapper} mt={3}>
        <Box className={classes.listInner} ref={listInner}>
          {!state.myTeam.contacts || state.myTeam.contacts.length === 0 ? (
            state.myTeam.isLoaded ? (
              <Box p={3}>
                <Typography align="center">No team members found.</Typography>
              </Box>
            ) : (
              ''
            )
          ) : (
            <List className={classes.list} component="nav" aria-label="main mailbox folders">
              {state.myTeam.contacts.map((teamMember) => {
                const clientIsSelected = selectedMembers.indexOf(teamMember.id) > -1;
                return (
                  <ListItem
                    button
                    onClick={() => {
                      toggleClient(teamMember.id);
                    }}
                    selected={clientIsSelected}
                    key={teamMember.id}
                  >
                    <ListItemText primary={teamMember.fullname} />
                    {clientIsSelected && (
                      <ListItemIcon>
                        <CheckmarkIcon />
                      </ListItemIcon>
                    )}
                  </ListItem>
                );
              })}
            </List>
          )}
        </Box>
      </Box>
      <Divider />
      <Box display={'flex'}>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          disabled={false}
          className={classes.cancelBtn}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="default"
          type="submit"
          disabled={false}
          onClick={handleAssignTeam}
          loading={loading}
        >
          Assign
        </LoadingButton>
      </Box>
    </>
  );
}
