import React, {useState, useEffect} from 'react';
import {usePlaidLink} from 'react-plaid-link';
import DataController from '../../../lib/controllers/DataController.js';
import {actions} from '../../../store/store.js';
import useAppState from '../../../lib/hooks/useAppState.js';
import {useSnackbar} from 'notistack';
import {parseISO, isBefore} from 'date-fns';

// quick start https://plaid.com/docs/quickstart/
// plaid stripe https://plaid.com/docs/auth/partnerships/stripe/

let cachedPlaidToken = null;

function PlaidLinkTokenProvider({
  Component,
  ButtonComponent,
  ButtonComponentProps,
  onButtonComponentClick,
  orderId,
  triggerAchTokenOnly,
  successCallback,
}) {
  const {enqueueSnackbar} = useSnackbar();
  const [linkToken, setLinkToken] = useState(null);

  const generatePlaidToken = async () => {
    if (
      cachedPlaidToken &&
      cachedPlaidToken.expiration &&
      !isBefore(parseISO(cachedPlaidToken.expiration), new Date())
    ) {
      setLinkToken(cachedPlaidToken.link_token);
      return;
    }

    DataController.getPlaidLinkToken(orderId).then((res) => {
      console.log('[getPlaidLinkToken]', res);
      if (res && res.data && res.data.link_token) {
        cachedPlaidToken = res.data;
        setLinkToken(res.data.link_token);
      } else {
        enqueueSnackbar(res.message || 'Your bank account could not be added at this time. Please try again.', {
          variant: 'error',
        });
      }
    });
  };

  useEffect(() => {
    generatePlaidToken();
  }, []);

  return linkToken != null ? (
    <Component
      linkToken={linkToken}
      onButtonComponentClick={onButtonComponentClick}
      ButtonComponent={ButtonComponent}
      triggerAchTokenOnly={triggerAchTokenOnly}
      successCallback={successCallback}
      ButtonComponentProps={ButtonComponentProps}
    />
  ) : (
    ''
  );
}

function RenderPlaidButton({
  linkToken,
  ButtonComponent,
  ButtonComponentProps,
  onButtonComponentClick,
  successCallback,
  triggerAchTokenOnly,
}) {
  const {dispatch} = useAppState();
  const {enqueueSnackbar} = useSnackbar();

  const handlePlaidSuccess = (public_token, metadata) => {
    console.log('[handlePlaidSuccess]', public_token, metadata);

    if (triggerAchTokenOnly && successCallback) {
      successCallback({public_token, metadata});
      return;
    }

    DataController.getPlaidPaymentMethod({
      public_token: metadata.public_token,
      account_id: metadata.account_id,
    }).then((res) => {
      if (res && res.success) {
        dispatch({
          type: actions.CLEAR_DATA,
          payload: {
            action: actions.SET_MY_PAYMENT_METHODS,
          },
        });
        enqueueSnackbar('Your bank account has been added.');
        if (successCallback && typeof successCallback === 'function') {
          successCallback(res.data);
        }
      } else {
        enqueueSnackbar(res.message || 'Your bank account could not be added at this time. Please try again.', {
          variant: 'error',
        });
      }
      document.body.removeAttribute('style');
    });
  };

  const {open, ready} = usePlaidLink({
    token: linkToken,
    onSuccess: handlePlaidSuccess,
  });

  const handleClickButtonComponent = (e) => {
    if (typeof onButtonComponentClick === 'function') onButtonComponentClick(e);
    open();
  };

  return <ButtonComponent onClick={handleClickButtonComponent} disabled={!ready} {...ButtonComponentProps} />;
}

function PlaidButton({
  ButtonComponent,
  ButtonComponentProps,
  onButtonComponentClick,
  orderId,
  triggerAchTokenOnly,
  successCallback,
}) {
  return (
    <PlaidLinkTokenProvider
      Component={RenderPlaidButton}
      onButtonComponentClick={onButtonComponentClick}
      orderId={orderId}
      successCallback={successCallback}
      triggerAchTokenOnly={triggerAchTokenOnly}
      ButtonComponent={ButtonComponent}
      ButtonComponentProps={ButtonComponentProps}
    />
  );
}

export default PlaidButton;
