import React, {useState} from 'react';
import useData from 'lib/hooks/useData';
import {Box, Button, Checkbox, Typography, Divider, makeStyles} from '@material-ui/core';
import StripeProvider from '../../form/StripeProvider';
import PaymentCreateCardForm from '../../PaymentMethodsPage/PaymentCreateCardForm';
import PlaidButton from '../../form/PlaidButton';
import classNames from 'classnames';
import {ReactComponent as PlusIcon} from 'images/plusCircle.svg';
import {ReactComponent as CardAmexIcon} from 'images/cardAmex.svg';
import {ReactComponent as CardVisaIcon} from 'images/cardVisa.svg';
import {ReactComponent as CardJCBIcon} from 'images/cardJCB.svg';
import {ReactComponent as CardDiscoverIcon} from 'images/cardDiscover.svg';
import {ReactComponent as BankIcon} from 'images/bank.svg';
import {ReactComponent as InvoiceIcon} from 'images/attachment.svg';

const useStyles = makeStyles((theme) => ({
  wrap: {
    borderRadius: 5,
    border: `1px solid ${theme.palette.primary[400]}`,
  },
  displayNone: {
    display: 'none!important',
  },
  row: {
    background: theme.palette.primary[100],
    minHeight: 48,
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    borderBottom: `1px solid ${theme.palette.primary[200]}`,
  },
  header: {
    background: theme.palette.primary[400],
    color: theme.palette.primary[900],
  },
  addPaymentBtn: {
    '& .MuiButton-label': {
      fontSize: 13,
      textTransform: 'lowercase',
      fontWeight: 'normal',
    },
  },
  checkbox: {
    marginRight: 8,
  },
  type: {
    color: theme.palette.primary[900],
    marginLeft: 12,
  },
  name: {
    color: theme.palette.primary[900],
    marginLeft: 'auto',
  },
  formWrap: {
    padding: 16,
  },
  formTitle: {
    color: theme.palette.neutral[900],
    fontSize: 21,
  },
  bankIcon: {
    '& path': {
      stroke: theme.palette.common.white,
      fill: theme.palette.common.white,
    },
  },
  invoiceTitle: {
    marginLeft: 20,
    color: theme.palette.neutral[900],
  },
  paymentTerms: {
    marginLeft: 92,
  },
  paymentTermsRow: {
    borderBottom: 'none',
  },
  halfWidth: {
    width: '50%',
  },
  btn: {
    '& .MuiButton-label': {
      fontWeight: '400',
      fontSize: 14,
    },
  },
}));

const cardLogos = {
  'American Express': CardAmexIcon,
  Visa: CardVisaIcon,
  JCB: CardJCBIcon,
  Discover: CardDiscoverIcon,
};

export default function OrderExtraAddonsPayment({selected, setSelected, invoiceInfo, halfWidth = false}) {
  const classes = useStyles();

  const [addNewFormOpen, setAddNewFormOpen] = useState(null);

  const state = useData({
    queries: ['getMyPaymentMethods'],
    setLoadingState: true,
  });

  const displayAddNewForm = (type) => {
    setAddNewFormOpen(type);
  };

  const hideAddNewForm = () => {
    setAddNewFormOpen(null);
  };

  const handleChecked = (paymentId) => {
    setSelected(paymentId);
  };

  const getCardLogo = (card) => {
    if (cardLogos[card.cc_type]) {
      const SvgComp = cardLogos[card.cc_type];
      return <SvgComp />;
    }
  };

  const handlePaymentMethodAdded = (paymentMethod) => {
    setSelected(paymentMethod.id);
    hideAddNewForm();
  };

  return (
    <Box m={3} className={classes.wrap}>
      <Box className={classNames(classes.row, classes.header)}>payment method</Box>
      {invoiceInfo && (
        <Box>
          <Box className={classNames(classes.row, classes.paymentTermsRow)}>
            <Checkbox
              size="small"
              className={classes.checkbox}
              checked={invoiceInfo.id === selected}
              onChange={() => {
                handleChecked(invoiceInfo.id);
              }}
            />
            <InvoiceIcon />
            <span className={classes.invoiceTitle}>{`${invoiceInfo.title} `}</span>
            <Button href="/billing" target="_blank" size="small" className={classes.btn}>
              See terms.
            </Button>
          </Box>
        </Box>
      )}
      {state.paymentMethods.cards.map((card) => (
        <Box className={classes.row} key={card.id}>
          <Checkbox
            size="small"
            className={classes.checkbox}
            checked={card.id === selected}
            onChange={() => {
              handleChecked(card.id);
            }}
          />
          {getCardLogo(card)}
          <span className={classNames(classes.type, halfWidth ? classes.halfWidth : '')}>
            {card.cc_type === 'American Express' ? 'Amex' : card.cc_type}
          </span>
          <span className={classNames(classes.name, halfWidth ? classes.halfWidth : '')}>
            {card.cc_nickname} •••• {card.cc_number}
          </span>
        </Box>
      ))}
      {state.paymentMethods.banks.map((bank) => (
        <Box className={classes.row} key={bank.id}>
          <Checkbox
            size="small"
            className={classes.checkbox}
            checked={bank.id === selected}
            onChange={() => {
              handleChecked(bank.id);
            }}
          />
          <BankIcon className={classes.bankIcon} />
          <span className={classNames(classes.type, halfWidth ? classes.halfWidth : '')}>{bank.ba_name}</span>
          <span className={classNames(classes.name, halfWidth ? classes.halfWidth : '')}>{bank.ba_type}</span>
        </Box>
      ))}

      <Box className={classNames(classes.row, addNewFormOpen && classes.displayNone)}>
        <Button
          onClick={() => {
            displayAddNewForm('card');
          }}
          className={classes.addPaymentBtn}
          size="small"
          startIcon={<PlusIcon />}
        >
          add a new card
        </Button>{' '}
        or
        <PlaidButton
          successCallback={(paymentMethod) => handlePaymentMethodAdded(paymentMethod)}
          ButtonComponent={Button}
          ButtonComponentProps={{
            children: <span>bank account</span>,
            className: classes.addPaymentBtn,
            size: 'small',
          }}
        />
      </Box>
      {addNewFormOpen && (
        <Box className={classes.formWrap}>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.formTitle}>Add a New Card</Typography>
            <Button onClick={hideAddNewForm} className={classes.addPaymentBtn}>
              cancel
            </Button>
          </Box>
          <Divider />
          <StripeProvider>
            <PaymentCreateCardForm
              ignoreChangeIsMade
              successCallback={(paymentMethod) => handlePaymentMethodAdded(paymentMethod)}
            />
          </StripeProvider>
        </Box>
      )}
    </Box>
  );
}
