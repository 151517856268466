import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import {ReactComponent as PointingGuysInverseImage} from 'images/pointingGuysInverse.svg';
import useLinkHandler from '../../../lib/hooks/useLinkHandler';
import {wwwUrl} from 'lib/config.js';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  wrap: {
    maxWidth: '100%',
    '& svg': {
      maxWidth: '100%',
      height: 'auto',
    },
  },
  text: {
    color: theme.palette.neutral[800],
    fontSize: 20,
    lineHeight: '25px',
    marginBottom: 16,
  },
  cta: {
    marginBottom: 16,
  },
}));

function OrdersEmpty() {
  const {getLinkButtonProps} = useLinkHandler();
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.wrap}>
        <Box className={classes.text}>You haven’t placed any orders at Addy yet.</Box>
        <Box className={classes.cta}>
          <Button variant="contained" {...getLinkButtonProps({url: wwwUrl + '/shop', target: '_self'})}>
            Start Shopping
          </Button>
        </Box>
        <PointingGuysInverseImage />
      </Box>
    </Box>
  );
}

export default OrdersEmpty;
