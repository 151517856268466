import Box from '@material-ui/core/Box';
import addyDesignService from '../../../images/addyDesignService.png';
import Button from '@material-ui/core/Button';
import {ReactComponent as ExternalLinkIcon} from 'images/extLink.svg';
// import { ReactComponent as AddyDesignServiceIcon } from 'images/addyDesignService.svg';
import useLinkHandler from '../../../lib/hooks/useLinkHandler.js';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  imgWrap: {
    // borderRadius: 4,
    // border: `2px solid  ${theme.palette.common.base}`,
    overflow: 'hidden',
    marginBottom: 80,

    '& img': {
      display: 'block',
      width: '100%',
      height: 'auto',
    },
  },
}));

function OrderDesignService({url}) {
  const classes = useStyles();
  const {getLinkButtonProps} = useLinkHandler();

  return (
    <Box>
      {/* <Button variant="contained" fullWidth {...getLinkButtonProps({url})}>
        Start Ad Briefing <ExternalLinkIcon />
      </Button> */}
      <Box mt={3} className={classes.imgWrap}>
        {/*<AddyDesignServiceIcon/>*/}
        <img src={addyDesignService} alt="addy design service" />
      </Box>
    </Box>
  );
}

export default OrderDesignService;
