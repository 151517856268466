import React, {useState} from 'react';
import {Box, Divider, Select, InputLabel, MenuItem, makeStyles} from '@material-ui/core';
import EmailTextarea from './EmailTextarea';
import useAppState from 'lib/hooks/useAppState';
import DataController from 'lib/controllers/DataController';
import {useSnackbar} from 'notistack';
import LoadingButton from '../common/LoadingButton';

const useStyles = makeStyles((theme) => ({
  wrap: {
    maxWidth: 580,
  },
  bold: {
    fontWeight: 'bold',
    fontSize: 17,
    lineHeight: '24px',
    color: theme.palette.neutral[900],
    marginBottom: 4,
  },
  label: {
    lineHeight: '20px',
    paddingBottom: 4,
    color: theme.palette.neutral[700],
  },
  clientListDropdown: {
    height: 48,
  },
}));

export default function TeamInviteForm() {
  const [client, setClient] = useState('');
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);

  const {state} = useAppState();
  const {enqueueSnackbar} = useSnackbar();
  const isAgency = state.userData.is_agency;
  const classes = useStyles();

  const handleChange = (e) => {
    setClient(e.target.value);
  };

  const clearForm = () => {
    setEmails([]);
    setClient('');
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (!emails || emails.length < 1) return;

    setLoading(true);

    DataController.inviteTeamMembers(emails, client).then(({data, errors, message, success}) => {
      if (success) {
        enqueueSnackbar(message);
        clearForm();
      } else {
        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
      setLoading(false);
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box className={classes.wrap}>
        <InputLabel className={classes.bold}>
          Add team members to your Addy account by sending them an email invite.
        </InputLabel>
        <div>(Hint: you can add multiple emails at once by separating them with commas.)</div>
        <EmailTextarea items={emails} setItems={setEmails} />
        {isAgency && state.userData && state.userData.companies && state.userData.companies.length > 0 && (
          <>
            <InputLabel className={classes.label}>Assign to client</InputLabel>
            <Select
              id="demo-simple-select-outlined"
              value={client}
              className={classes.clientListDropdown}
              onChange={handleChange}
              fullWidth
              variant="outlined"
            >
              {state.userData.companies.map((client) => (
                <MenuItem key={client.id} value={client.id}>
                  {client.name}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      </Box>
      <Divider />
      <LoadingButton variant="contained" color="default" type="submit" loading={loading}>
        Add
      </LoadingButton>
    </form>
  );
}
