import React from 'react';
import {AreaChart, Area, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip} from 'recharts';
import {makeStyles} from '@material-ui/core';
import CustomTooltip from './CustomTooltip';
import {ReactComponent as LineAndCircle} from 'images/lineAndCircle.svg';

const useStyles = (props) =>
  makeStyles((theme) => ({
    chartWrapper: {
      width: '100%',
      height: 400,
    },
    chartTitleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      lineHeight: '25px',
      alignItems: 'center',
      paddingBottom: 20,
    },
    chartTitle: {
      fontSize: 20,
      color: theme.palette.neutral[900],
    },
    period: {
      fontSize: 11,
      lineHeight: '13px',
      textAlign: 'center',
      color: theme.palette.neutral[600],
      marginRight: 10,
    },
    redSvg: {
      '& path': {
        fill: props.areaColor,
      },
    },
  }));

export default function AreaChartComponent({data, title, dataKey, nameKey, areaColor}) {
  const classes = useStyles({areaColor})();
  return (
    <div className={classes.chartWrapper}>
      <div className={classes.chartTitleWrapper}>
        <span className={classes.chartTitle}>{title}</span>
        <div>
          <span className={classes.period}>daily total</span>
          <LineAndCircle className={classes.redSvg} />
        </div>
      </div>
      <ResponsiveContainer width="99%">
        <AreaChart data={data} margin={{top: 10, right: 0, left: 0, bottom: 0}}>
          <XAxis dataKey={nameKey} angle={-10} />
          <YAxis tickFormatter={(number) => number.toLocaleString()} />
          <CartesianGrid />
          <Tooltip content={<CustomTooltip />} />
          <Area type="monotone" dataKey={dataKey} stroke={areaColor} fillOpacity={0.3} fill={areaColor} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
