import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

function InvoiceLayout({children}) {
  const classes = useStyles();
  return <Box className={classes.root}>{children}</Box>;
}

export default InvoiceLayout;
