import {ReactComponent as UserIcon} from '../images/user.svg';
import ProfilePage from '../components/pages/dashboard/ProfilePage.js';
import {ReactComponent as CoffeeIcon} from '../images/coffee.svg';
import CompanyPage from '../components/pages/dashboard/CompanyPage.js';
import {ReactComponent as GlobeIcon} from '../images/globe.svg';
import PromoPage from '../components/pages/dashboard/PromoCodesPage.js';
import {ReactComponent as CreditCardIcon} from '../images/credit-card.svg';
import PaymentPage from '../components/pages/dashboard/PaymentMethodsPage.js';
import {ReactComponent as MailIcon} from '../images/mail.svg';
import MailingPage from '../components/pages/dashboard/AddressesPage.js';
import React from 'react';
import ProfileHeader from '../components/elements/ProfilePage/ProfileHeader.js';
import CompanyHeader from '../components/elements/CompanyPage/CompanyHeader.js';
import PromoHeader from '../components/elements/PromoCodesPage/PromoCodesHeader.js';
import PaymentHeader from '../components/elements/PaymentMethodsPage/PaymentMethodsHeader.js';
import MailingHeader from '../components/elements/AddressesPage/AddressesHeader.js';

const accountRoutes = [
  {
    route: '/account/profile',
    id: 0,
    label: 'Profile',
    icon: <UserIcon />,
    page: <ProfilePage />,
    header: <ProfileHeader />,
  },
  {
    route: '/account/company',
    id: 1,
    label: 'Company',
    icon: <CoffeeIcon />,
    page: <CompanyPage />,
    header: <CompanyHeader />,
  },
  {
    route: '/account/promocodes',
    id: 2,
    label: 'Promo Codes',
    icon: <GlobeIcon />,
    page: <PromoPage />,
    header: <PromoHeader />,
  },
  {
    route: '/account/paymentmethods',
    id: 3,
    label: 'Payment Methods',
    icon: <CreditCardIcon />,
    page: <PaymentPage />,
    header: <PaymentHeader />,
  },
  {
    route: '/account/addresses',
    id: 4,
    label: 'Mailing Addresses',
    icon: <MailIcon />,
    page: <MailingPage />,
    header: <MailingHeader />,
  },
];

export default accountRoutes;
