import {Box, Typography, Divider, makeStyles} from '@material-ui/core';
import OrderDetailsHeader from './OrderDetailsHeader';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  titleSpan: {
    color: theme.palette.neutral[600],
  },
  titleWrap: {
    position: 'relative',
    fontSize: 34,
    lineHeight: '34px',

    [theme.breakpoints.up('lg')]: {
      fontSize: 55,
      lineHeight: '55px',
    },
  },
  canceled: {
    opacity: 0.3,
    paddingRight: 75,
  },
  canceledText: {
    position: 'absolute',
    top: '0',
    right: '0',
    borderRadius: 14,
    border: `1px solid ${theme.palette.common.base}`,
    color: theme.palette.common.base,
    fontSize: 13,
    lineHeight: '18px',
    padding: '4px 8px',
    backgroundColor: theme.palette.common.white,
  },
}));

export default function OrderDetails({order}) {
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="h2" className={classNames(classes.titleWrap, order.is_canceled ? classes.canceled : '')}>
        <span className={classes.titleSpan}>Order #</span>
        <span>{order.id}</span>
        {order.is_canceled ? <div className={classes.canceledText}>Canceled</div> : ''}
      </Typography>
      <OrderDetailsHeader order={order} />
      <Divider />
    </Box>
  );
}
