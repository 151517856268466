import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import theme from 'theme';
import BackArrow from '../common/BackArrow';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(() => ({
  subtitle: {
    color: theme.default.palette.neutral[700],
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '25px',
    marginTop: 14,

    [theme.default.breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },
  contactUs: {
    fontSize: 20,
  },
}));

export default function ForgotPasswordForm({
  email,
  errors,
  handleResetSend,
  handleTabClick,
  isLoading,
  setEmail,
  handleBackClick,
}) {
  const classes = useStyles();

  return (
    <>
      <BackArrow onClick={handleBackClick} path={'/login'} label="Back to sign in" />
      <form autoComplete="off" noValidate onSubmit={handleResetSend}>
        <Typography variant="h3">Password Reset</Typography>

        <Typography className={classes.subtitle}>
          Forgot your password?
          <br />
          We will get you a new one.
        </Typography>

        <Box mt={4} mb={3}>
          <TextField
            id="reset-email"
            error={!!errors.email}
            helperText={errors.email}
            label="Email address"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="email@domain.com"
            required
            value={email}
          />
        </Box>
        <Box mt={3}>
          <Button variant="contained" type="submit" disabled={!email || isLoading}>
            Send
          </Button>
        </Box>
        <Divider />
        <Link to="/">
          <span className={classes.contactUs}>contact us</span>
        </Link>
      </form>
    </>
  );
}
