import {Box} from '@material-ui/core';
import CommonHeader from 'components/elements/common/CommonHeader.js';
import UserRoleChip from 'components/elements/TeamPage/UserRoleChip';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {useParams} from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles.js';
import BackArrow from 'components/elements/common/BackArrow';
import AvatarContainer from 'components/elements/common/AvatarContainer';
import {Divider} from '@material-ui/core';
import TeamCreateForm from '../../../elements/TeamPage/TeamEditForm';
import useData from '../../../../lib/hooks/useData.js';
import TeamMoreMenu from 'components/elements/TeamPage/TeamMoreMenu';
import ClientsSection from 'components/elements/TeamPage/ClientsSection';
import {useSnackbar} from 'notistack';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: 0,
  },
}));

function TeamEditPage() {
  const {enqueueSnackbar} = useSnackbar();
  const [teamMember, setTeamMember] = useState(null);
  const history = useHistory();
  const classes = useStyles();
  let {id} = useParams();
  const state = useData({
    queries: ['getMyCompany', 'getMyTeam', ['getTeamMember', [id]]],
    cbError: () => {
      // TODO: 404
    },
  });

  useEffect(() => {
    if (id && state.myTeam && state.myTeam.contacts && state.myTeam.contacts) {
      const contact = state.myTeam.contacts.find((contact) => contact.id.toString() === id);
      if (contact && Array.isArray(contact.clients)) {
        setTeamMember(contact);
      }
    }
  }, [state]);

  const teamMemberName = teamMember ? `${teamMember.firstname} ${teamMember.lastname}` : '';
  const teamMemberAvatar = teamMember ? teamMember.avatar : '';
  let teamMemberRole = teamMember ? 'account ' + teamMember.role : null;
  if (teamMemberRole === 'account null') {
    teamMemberRole = null;
  }

  useEffect(() => {
    if (teamMember && !teamMember.is_editable) {
      enqueueSnackbar('You have no permission to edit this user.', {
        variant: 'error',
      });
      history.push('/team');
    }
  }, [teamMember]);

  return (
    <Box className={classes.wrapper}>
      <CommonHeader
        heading={teamMemberName}
        right={
          teamMember && state.myCompany.data ? (
            <Box display="flex" alignItems="center">
              <UserRoleChip role={teamMemberRole} />
              <TeamMoreMenu contact={teamMember} company={state.myCompany.data} isEditPage={true} />
            </Box>
          ) : (
            false
          )
        }
      />

      <BackArrow path="/team" />

      <AvatarContainer
        src={teamMemberAvatar}
        uploadUrl={`/team/${id}/avatar`}
        badge={teamMember ? (teamMember.is_agency ? 'Agency' : 'Client') : ''}
      />

      <Divider />

      <TeamCreateForm teamMember={teamMember} />

      <Divider />

      {teamMember && teamMember.is_client_assignable && <ClientsSection teamMember={teamMember} />}
    </Box>
  );
}

export default TeamEditPage;
