import React, {useState, useEffect} from 'react';
import {Box, CircularProgress, makeStyles} from '@material-ui/core';
import CustomDialog from '../common/CustomDialog';
import DataController from 'lib/controllers/DataController';
import FileReuseDialogRow from './FileReuseDialogRow';

const useStyles = makeStyles((theme) => ({
  reuseDialogTitle: {
    marginBottom: 24,
  },

  reuseDialogContent: {
    marginLeft: -24,
    marginRight: -24,
  },

  loaderWrap: {
    width: '100%',
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function FileReuseDialog({file, isOpen, onClose, onOrderReuseClick}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);

      DataController.getFileSets(file.id).then(({data, success, error, message}) => {
        if (success) {
          setData(data);
          setLoading(false);
        } else {
          console.error(error, message);
          setLoading(false);
        }
      });
    }
  }, [isOpen]);

  return (
    <CustomDialog
      isOpen={isOpen}
      title="Choose a File Set to Re-use"
      hasXClose
      onClose={onClose}
      hideTitleDivider
      hideContentDivider
      dialogContentClass={classes.reuseDialogContent}
      dialogTitleClass={classes.reuseDialogTitle}
      maxWidth="lg"
    >
      <Box>
        {loading ? (
          <Box className={classes.loaderWrap}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Box>
            {data && data.length ? (
              <Box>
                {data.map((item, index) => (
                  <FileReuseDialogRow item={item} key={index} onOrderReuseClick={onOrderReuseClick} />
                ))}
              </Box>
            ) : (
              <Box>no data</Box>
            )}
          </Box>
        )}
      </Box>
    </CustomDialog>
  );
}
