import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: (props) => (props.size === 'half' ? 428 : 792),
    flexDirection: (props) => props.direction,

    '& .MuiFormControl-root': {
      maxWidth: (props) => (props.size === 'half' ? 209 : 428),
      padding: '15px 0',

      '& + .MuiFormControl-root': {
        marginLeft: 20,
      },
    },

    [theme.breakpoints.up('tb')]: {
      flexDirection: 'row',

      '& .MuiFormControl-root': {
        maxWidth: 384,
      },
    },
  },
}));

function FieldGroup({vertical, half, ...rest}) {
  const classes = useStyles({
    direction: vertical ? 'column' : 'row',
    size: half ? 'half' : 'full',
  });

  return <Box className={classes.root} display="flex" justifyContent="space-between" {...rest} />;
}

export default FieldGroup;
