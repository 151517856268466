import React, {useState} from 'react';
import useAppState from 'lib/hooks/useAppState';
import actions from 'store/actions';
import {useHistory} from 'react-router';
import MoreMenu from '../common/MoreMenu';
import AssignClientDialog from './AssignClientDialog';
import DataController from 'lib/controllers/DataController';
import {useSnackbar} from 'notistack';

export default function TeamMoreMenu({contact, company, isEditPage}) {
  const history = useHistory();
  const {dispatch} = useAppState();
  const {enqueueSnackbar} = useSnackbar();

  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [clientsDialogOpen, setClientsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEdit = (e) => {
    e.preventDefault();
    history.push(`/team/${contact.id}/edit`);
  };

  const handleOpenAssignClientsDialog = () => {
    setSelectedTeamMember(contact);
    setClientsDialogOpen(true);
  };

  const handleUpdateUserRole = (role) => {
    if (loading) return;

    setLoading(true);

    const companyId = company.id;
    const contactId = contact.id;

    DataController.postTeamMemberRole({role, companyId, contactId}).then(({data, errors, message, success}) => {
      if (success) {
        dispatch({
          type: actions.UPDATE_ROW,
          payload: {
            location: 'myTeam.contacts',
            data,
          },
        });
        enqueueSnackbar('Contact successfully updated!');
      } else {
        enqueueSnackbar(message, {
          variant: 'error',
        });
      }
      setLoading(false);
    });
  };

  const items = [];

  if (!isEditPage && contact.is_client_assignable) {
    items.push({
      label: 'Assign to clients',
      onClick: () => handleOpenAssignClientsDialog(),
    });
  }

  if (contact.is_editable && !isEditPage) {
    items.push({
      label: 'Edit',
      onClick: (e) => handleEdit(e),
    });
  }

  if (!contact.is_owner) {
    if (contact.is_admin) {
      items.push({
        label: 'Downgrade to account member',
        onClick: () => handleUpdateUserRole('member'),
      });
    } else if (contact.is_member) {
      items.push({
        label: 'Upgrade to account admin',
        onClick: () => handleUpdateUserRole('admin'),
      });
    } else {
      items.push(
        ...[
          {
            label: 'Upgrade to account admin',
            onClick: () => handleUpdateUserRole('admin'),
          },
          {
            label: 'Downgrade to account member',
            onClick: () => handleUpdateUserRole('member'),
          },
        ]
      );
    }
  }

  return (
    <>
      <MoreMenu id="team-menu" items={items} row={contact} loading={loading} />
      <AssignClientDialog
        teamMember={contact}
        handleClose={() => setClientsDialogOpen(false)}
        open={clientsDialogOpen}
      />
    </>
  );
}

TeamMoreMenu.defaultProps = {
  isEditPage: false,
};
