import React from 'react';
import {Button, Box, makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    border: `1px solid ${theme.palette.neutral[500]}`,
    background: theme.palette.common.offWhite,
    borderRightColor: theme.palette.neutral[500],
    width: 40,
    height: 40,

    '&:not(:last-child)': {
      borderRightColor: theme.palette.neutral[500],
    },

    '&:hover': {
      textDecoration: 'none',
    },

    '&:first-child': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      marginRight: -1,
    },
    '&:last-child': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginLeft: -1,
    },
  },

  box: {
    width: 40,
    height: 40,
    border: `1px solid ${theme.palette.neutral[500]}`,
    background: theme.palette.common.offWhite,
  },
}));

export default function NumberInputGroup({onChange, value, disabled = false, disableAdd = false}) {
  const classes = useStyles();

  const increment = () => {
    onChange(value + 1);
  };

  const decrement = () => {
    if (value > 0) {
      onChange(value - 1);
    }
  };

  return (
    <>
      <Box display="flex">
        <Button
          className={classes.button}
          size="small"
          disabled={value < 1 || disabled}
          onClick={() => {
            decrement();
          }}
        >
          -
        </Button>
        <Box className={classes.box} display="flex" alignItems="center" justifyContent="center">
          {value}
        </Box>
        <Button
          className={classes.button}
          size="small"
          disabled={disabled || disableAdd}
          onClick={() => {
            increment();
          }}
        >
          +
        </Button>
      </Box>
    </>
  );
}
