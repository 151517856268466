import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  payBox: {
    marginLeft: 50,
  },
  transferType: {
    fontSize: 17,
    color: theme.palette.neutral[900],
    lineHeight: '24px',
  },
  bankInfoItem: {
    marginTop: 15,
    '& :first-child': {
      fontSize: 11,
      fontWeight: 800,
      lineHeight: '13px',
      color: theme.palette.neutral[800],
    },
    '& :last-child': {
      fontSize: 11,
      fontWeight: 400,
      lineHeight: '13px',
      color: theme.palette.neutral[800],
    },
  },
  note: {
    fontSize: 11,
    lineHeight: '13px',
    marginTop: 15,
    color: theme.palette.neutral[800],
  },
}));

function WireTransferInfo({isGT}) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box>
        <div className={classes.transferType}>Wire Transfer</div>
        <div className={classes.bankInfoItem}>
          <div>Beneficiary Name:</div>
          <div>xAd, Inc. dba GroundTruth</div>
        </div>
        <div className={classes.bankInfoItem}>
          <div>Beneficiary Address:</div>
          <div>One World Trade Center, 60th floor. New York, NY 10007, USA</div>
        </div>
        <div className={classes.bankInfoItem}>
          <div>Bank Name:</div>
          <div>JP Morgan Chase</div>
        </div>
        <div className={classes.bankInfoItem}>
          <div>Bank Address:</div>
          <div>P.O. Box 735741, Chicago, IL 60673-5741</div>
        </div>
        <div className={classes.bankInfoItem}>
          <div>SWIFT ID:</div>
          <div>CHASUS33</div>
        </div>
        <div className={classes.bankInfoItem}>
          <div>ABA #:</div>
          <div>021000021</div>
        </div>
        <div className={classes.bankInfoItem}>
          <div>Account #:</div>
          <div>933211531</div>
        </div>
        <div className={classes.note}>Include INVOICE number(s) in note</div>
      </Box>
      <Box className={classes.payBox}>
        <div className={classes.transferType}>Pay by Check</div>
        <div className={classes.bankInfoItem}>
          <div>Please send checks to:</div>
          <div>
            {isGT ? 'GroundTruth' : 'GroundTruth'}
            <br />
            C/O JP Morgan Chase
            <br />
            P.O. Box 735741
            <br />
            Chicago, IL 60673-5741
            <br />
            <br />
            Be sure to include invoice number(s) in remittance.
          </div>
        </div>
      </Box>
    </Box>
  );
}

export default WireTransferInfo;
