import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  loader: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: 0,
    width: 0,
    height: '100%',
    background: 'rgba(255,255,255,0.3)',
  },
}));

function ProgressButton(props) {
  const classes = useStyles();
  const btnProps = {...props};
  btnProps.className = classNames(classes.root, btnProps.className ? btnProps.className : '');
  delete btnProps.progress;
  delete btnProps.children;
  const progress = props.progress || 0;
  let loaderColor = `255,255,255,`;
  if (btnProps.isLoaderBlack) {
    delete btnProps.isLoaderBlack;
    loaderColor = `0,0,0,`;
  }

  return (
    <Button {...btnProps}>
      <span
        className={classes.loader}
        style={{
          width: `${progress}%`,
          background: `rgba(${loaderColor}${(0.3 / 0.5) * (parseFloat(progress) / 100)})`,
        }}
      />
      {props.children}
    </Button>
  );
}

export default ProgressButton;
